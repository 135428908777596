import { useQuery } from '@apollo/client';
import { UserGroupIcon, SearchIcon } from '@heroicons/react/outline';
import { useState, FormEvent, MouseEvent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { PatientsTable } from './PatientsTable';
import { Input, Button } from '../../../common/form/components';
import { EmptyState, LoadingState } from '../../../common/state/components';
import { GET_DOCTOR_PATIENTS } from '../../graphql/queries/patients';
import { Query, QueryGetDoctorPatientsArgs, User } from '../../graphql/types';

const PATIENT_LIMIT = 15;
const SEARCH_BY_NAME = 'name';
const SEARCH_BY_IDENTIFICATION_NUMBER = 'identification_number';
const SEARCH_BY_OPTIONS = [SEARCH_BY_NAME, SEARCH_BY_IDENTIFICATION_NUMBER];

export function SearchablePatientsTable() {
  const { t } = useTranslation();

  const [patients, setPatients] = useState<User[]>([]);
  const [query, setQuery] = useState<string>('');
  const [searchBy, setSearchBy] = useState<string>(SEARCH_BY_NAME);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [currentOffset, setCurrentOffset] = useState<number>(0);
  const [noPatientsFound, setNoPatientsFound] = useState<boolean>(false);
  const { loading, refetch, fetchMore } = useQuery<Query, QueryGetDoctorPatientsArgs>(GET_DOCTOR_PATIENTS, {
    fetchPolicy: 'no-cache',
    variables: {
      input: {
        query: '',
        search_by: SEARCH_BY_NAME,
        offset: 0,
        limit: PATIENT_LIMIT,
      },
    },
    onCompleted: (dataResponse) => {
      handlePatientsResponse(dataResponse.getDoctorPatients);
    },
  });

  useEffect(() => {
    if (query && patients.length === 0) {
      setNoPatientsFound(true);
    }
  }, [patients]);

  async function searchPatients(query: string): Promise<void> {
    if (!submitting) {
      setSubmitting(true);
      setCurrentOffset(0);

      const { data: refetchResponse } = await refetch({
        input: {
          query,
          search_by: searchBy,
          offset: 0,
          limit: PATIENT_LIMIT,
        },
      });
      handlePatientsResponse(refetchResponse.getDoctorPatients, false);
      setSubmitting(false);
    }
  }

  function handlePatientsResponse(patientsFromResponse: User[], mergePatients: boolean = true) {
    setPatients((prevPatients) => (mergePatients ? [...prevPatients, ...patientsFromResponse] : patientsFromResponse));
  }

  async function loadMoreCallback(): Promise<boolean> {
    const fetchMoreResponse = await fetchMore({
      variables: {
        input: {
          query,
          search_by: searchBy,
          offset: currentOffset + PATIENT_LIMIT,
          limit: PATIENT_LIMIT,
        },
      },
    });
    const responsePatients = fetchMoreResponse.data.getDoctorPatients;
    setCurrentOffset((prevCurrentOffset) => prevCurrentOffset + PATIENT_LIMIT);
    handlePatientsResponse(fetchMoreResponse.data.getDoctorPatients);
    return responsePatients.length < PATIENT_LIMIT;
  }

  async function onSearchFormSubmit(event: FormEvent<HTMLFormElement>): Promise<void> {
    event.preventDefault();

    if (query) {
      await searchPatients(query);
    }
  }

  async function onClearButtonClick(event: MouseEvent<HTMLButtonElement>): Promise<void> {
    event.preventDefault();

    if (query) {
      setQuery('');
      setNoPatientsFound(false);
      await searchPatients('');
    }
  }

  return (
    <>
      <form onSubmit={onSearchFormSubmit} className="pb-4">
        <div className="flex items-center mb-2">
          <label className="text-sm font-medium leading-5 text-gray-700">{t('doctor:patients:search_by')}</label>
          {SEARCH_BY_OPTIONS.map((searchItem) => (
            <div key={searchItem} className="ml-2">
              <input
                id={searchItem}
                className="form-radio h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                type="radio"
                checked={searchItem === searchBy}
                value={searchItem}
                onChange={(event) => {
                  setSearchBy(event.target.value);
                }}
              />
              <label className="ml-2 text-sm leading-5 text-gray-900 cursor-pointer" htmlFor={searchItem}>
                {t(`doctor:patients:search_by_${searchItem}`)}
              </label>
            </div>
          ))}
        </div>

        <div className="flex flex-wrap items-center space-x-2">
          <Input
            className="flex-grow"
            icon={<SearchIcon className="h-5 w-5 text-gray-400" />}
            placeholder={t('common:search')}
            value={query}
            onChange={(event) => setQuery(event.target.value)}
            id="query"
            name="query"
            type="text"
            autoFocus
          />
          <Button label={t('common:clear')} type="button" variant="outline" onClick={onClearButtonClick} />
          <Button label={t('common:search')} type="submit" loading={submitting} disabled={submitting} />
        </div>
      </form>
      {loading && <LoadingState message={t('doctor:patients:collecting_patients')} />}
      {!loading && noPatientsFound && (
        <EmptyState
          icon={<UserGroupIcon className="h-12 w-12 mx-auto text-orange-400" />}
          title={t('doctor:patients:no_patients')}
          message={t('doctor:patients:you_have_no_patients_yet')}
        />
      )}
      {!loading && !submitting && !noPatientsFound && (
        <PatientsTable patients={patients} loadMoreCallback={loadMoreCallback} />
      )}
    </>
  );
}
