import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom';

import { InboxPage } from '../../consultation/pages';
import DoctorShell from '../../shell/DoctorShell';

export function ConsultationsRouter() {
  const match = useRouteMatch();

  return (
    <DoctorShell>
      <Switch>
        <Route exact path={`${match.path}/:id?`} component={InboxPage} />

        <Route path="*">
          <Redirect to={match.url} />
        </Route>
      </Switch>
    </DoctorShell>
  );
}
