import { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { UserContext } from '../../../common/authentication/UserContext';
import { User } from '../../../common/authentication/types';
import { Button } from '../../../common/form/components';
import { PageHeading, SearchablePatientsTable, InvitePatientModal } from '../components';

export function PatientsPage() {
  const { t } = useTranslation();
  const { user } = useContext(UserContext);

  const [invitePatientModalOpen, setInvitePatientModalOpen] = useState<boolean>(false);

  return (
    <div className="w-full mx-auto p-4 sm:p-6 md:p-8">
      <div className="bg-white overflow-hidden shadow sm:rounded-lg">
        <div className="px-4 py-5 sm:p-6">
          <PageHeading
            title={t('doctor:patients:patients')}
            actions={
              <div className="flex space-y-2 sm:space-x-2 sm:space-y-0">
                <Button onClick={() => setInvitePatientModalOpen(true)} label={t('doctor:patients:invite_patient')} />
              </div>
            }
          />
          <SearchablePatientsTable />
          <InvitePatientModal user={user as User} open={invitePatientModalOpen} setOpen={setInvitePatientModalOpen} />
        </div>
      </div>
    </div>
  );
}
