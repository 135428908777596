import { ExclamationCircleIcon } from '@heroicons/react/outline';
import { SelectHTMLAttributes, ReactNode } from 'react';

interface Props extends SelectHTMLAttributes<HTMLSelectElement> {
  children?: ReactNode;
  label?: string;
  icon?: ReactNode;
  error?: string;
}

export function Select(props: Props) {
  const { children, label, icon, error, id, required, ...rest } = props;

  const defaultClasses = 'border-gray-300 placeholder-gray-400 focus:ring-teal-500 focus:border-teal-500';
  const errorClasses = 'border-red-300 placeholder-red-300 focus:ring-red-500 focus:border-red-500 text-red-900';
  const iconClasses = 'pl-10';

  return (
    <div>
      {label && (
        <label htmlFor={id} className="block text-sm font-medium text-gray-700 text-left">
          {label}
          {required && '*'}
        </label>
      )}
      <div className="mt-1 flex relative rounded-md shadow-sm">
        {icon && <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">{icon}</div>}
        {error && (
          <div className="absolute inset-y-0 right-0 pr-8 flex items-center pointer-events-none">
            <ExclamationCircleIcon className="h-5 w-5 text-red-500" />
          </div>
        )}
        <select
          className={`appearance-none block w-full sm:text-sm focus:outline-none rounded-md ${
            error ? errorClasses : defaultClasses
          } ${icon && iconClasses}`}
          id={id}
          required={required}
          {...rest}
        >
          {children && children}
        </select>
      </div>
      {error && (
        <p className="mt-2 text-sm text-red-600" id={`${id}-error`}>
          {error}
        </p>
      )}
    </div>
  );
}
