import { useMutation } from '@apollo/client';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ActionHeaderBackLink } from './ActionHeaderBackLink';
import { ActionHeaderConsultationInformation } from './ActionHeaderConsultationInformation';
import { ActionHeaderConsultationScroller } from './ActionHeaderConsultationScroller';
import { ActionHeaderICD10CodeSelector } from './ActionHeaderICD10CodeSelector';
import CloseConsultationModal from './CloseConsultationModal';
import { ValidationError } from '../../../common/api/types';
import { User } from '../../../common/authentication/types';
import { toggleReopenStatus } from '../../../common/consultation/api';
import { ToggleWithLabel } from '../../../common/consultation/components';
import { Button } from '../../../common/form/components';
import { ICD10Code } from '../../../common/icd10/types';
import { SuccessNotification } from '../../../common/notification/components';
import { UPDATE_CONSULTATION_IS_FLAGGED } from '../../graphql/mutations/consultation';
import { Consultation } from '../../graphql/types';

interface Props {
  user: User;
  consultation: Consultation;
  onSelectConsultation: (consultation: Consultation | null) => void;
  icd10CodeSuggestions: ICD10Code[];
  setListOpen: (open: boolean) => void;
  consultationUpdateCallback: (updatedConsultation: Consultation) => void;
}

export function ActionHeader(props: Props) {
  const { user, consultation, icd10CodeSuggestions, onSelectConsultation, setListOpen, consultationUpdateCallback } =
    props;
  const [error, setError] = useState<ValidationError>({} as ValidationError);
  const [save_success, setSaveSuccess] = useState<boolean>(false);
  const [close_success, setCloseSuccess] = useState<boolean>(false);
  const { t } = useTranslation();
  const [showCloseConsultationModal, setShowCloseConsultationModal] = useState<boolean>(false);
  const [updateConsultationIsFlagged] = useMutation(UPDATE_CONSULTATION_IS_FLAGGED);

  function onShowConsultationListClick(event: React.MouseEvent<HTMLAnchorElement>) {
    event.preventDefault();
    setListOpen(true);
    onSelectConsultation(null);
  }

  function onUpdateConsultation(consultation: Consultation) {
    setSaveSuccess(false);
    consultationUpdateCallback(consultation);
    setSaveSuccess(true);
  }

  function onCloseConsultation(consultation: Consultation) {
    setCloseSuccess(false);
    consultationUpdateCallback(consultation);
    setCloseSuccess(true);
  }

  async function updateConsultationIsFlaggedCallback(flag: boolean) {
    setSaveSuccess(false);
    const { data: responseData } = await updateConsultationIsFlagged({
      variables: {
        consultationId: consultation.id,
        isFlagged: flag,
      },
    });

    consultationUpdateCallback(responseData.updateDoctorConsultationIsFlagged);
    setSaveSuccess(true);
  }

  async function updateReopenStatus() {
    setSaveSuccess(false);
    const updatedConsultation = await toggleReopenStatus(user, consultation);
    consultationUpdateCallback(updatedConsultation);
    setSaveSuccess(true);
  }

  return (
    <>
      <SuccessNotification
        title={t('common:consultation_saved')}
        message={t('common:this_consultation_is_saved')}
        isOpen={save_success}
      />
      <SuccessNotification
        title={t('common:consultation_closed')}
        message={t('common:this_consultation_is_closed')}
        isOpen={close_success}
      />
      <header className="bg-white z-10">
        <div className="pt-4 md:pt-1 pb-4 md:pb-2 px-4 sm:px-6">
          <div className="flex flex-col md:flex-row justify-between space-y-2 md:space-y-0">
            <div className="flex flex-1 flex-col space-y-2">
              <ActionHeaderBackLink onClick={onShowConsultationListClick} />
              <ActionHeaderConsultationInformation
                user={user}
                consultation={consultation}
                toggleConsultationFlag={updateConsultationIsFlaggedCallback}
              />
            </div>

            <div className="flex flex-col justify-between space-y-2 md:space-y-0">
              <ActionHeaderConsultationScroller
                nextConsultationId={consultation.next_consultation_id ?? undefined}
                previousConsultationId={consultation.previous_consultation_id ?? undefined}
              />
              {consultation.user_has_write_access && (
                <div className="flex items-start">
                  <ActionHeaderICD10CodeSelector
                    user={user}
                    consultation={consultation}
                    onUpdateConsultation={onUpdateConsultation}
                    icd10CodeSuggestions={icd10CodeSuggestions}
                    error={error}
                    setError={setError}
                  />
                  {consultation.closed_at && (
                    <div className="ml-2 self-center">
                      <ToggleWithLabel
                        checked={consultation.status === 'closed'}
                        label={t('common:closed')}
                        onChangeCallback={updateReopenStatus}
                      />
                    </div>
                  )}
                  {consultation.status !== 'closed' &&
                    consultation.status !== 'reminded' &&
                    !consultation.closed_at && (
                      <>
                        <Button
                          className="mt-0.5 ml-2"
                          label={t('common:close')}
                          variant="tertiary"
                          onClick={() => {
                            setShowCloseConsultationModal(true);
                          }}
                        />
                        <CloseConsultationModal
                          closeCallback={onCloseConsultation}
                          consultation={consultation}
                          errorCallback={setError}
                          visible={showCloseConsultationModal}
                          setVisibility={setShowCloseConsultationModal}
                        />
                      </>
                    )}
                </div>
              )}
            </div>
          </div>
        </div>
      </header>
    </>
  );
}
