import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';

import useQueryParams from '../../../hooks/useQueryParams';
import NotAuthenticatedShell from '../../shell/NotAuthenticatedShell';
import { Header, ResetPasswordForm } from '../components';

export function ResetPasswordPage() {
  const { t } = useTranslation();
  const queryParams = useQueryParams();

  if (!queryParams.get('token') || !queryParams.get('email')) {
    return <Redirect to="/login" />;
  }

  return (
    <NotAuthenticatedShell>
      <Header>{t('authentication:reset_your_password')}</Header>
      <div className="mt-10">
        <ResetPasswordForm
          email={(queryParams.get('email') as string).replace(' ', '+')}
          token={queryParams.get('token') as string}
        />
      </div>
    </NotAuthenticatedShell>
  );
}
