import { useTranslation } from 'react-i18next';

import type { TextMessageProps } from './TextMessage.types';
import { TextMessage as TextMessageType } from '../../../doctor/graphql/types';
import { classNames } from '../../utils/style';
import ConversationWrapper from '../ConversationWrapper';
import { stripEmptyPTags } from '../helpers';

export function TextMessage(props: TextMessageProps) {
  const { deleteCallbackHandler, message, isUser, readonly } = props;
  const { t } = useTranslation();

  return (
    <ConversationWrapper
      deleteCallbackHandler={deleteCallbackHandler}
      isUser={isUser}
      item={message}
      readonly={readonly}
    >
      <div
        className={classNames(
          'px-4 py-2 rounded-lg text-sm leading-5 text-gray-500',
          isUser ? 'bg-indigo-100' : 'bg-gray-100',
          isUser && !readonly && !message.deleted_at && 'pr-8'
        )}
      >
        {!message.deleted_at ? (
          <div className="inline-flex items-start justify-start">
            <div
              className="message whitespace-pre-wrap"
              dangerouslySetInnerHTML={{ __html: stripEmptyPTags((message.body as TextMessageType).content) }}
            />
          </div>
        ) : (
          <p className="whitespace-pre-wrap italic text-gray-400">{t('common:message_deleted')}</p>
        )}
      </div>
    </ConversationWrapper>
  );
}
