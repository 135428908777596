import { useTranslation } from 'react-i18next';

export function Logo() {
  const { t } = useTranslation();

  return (
    <a href="https://skindr.com" target="_blank" rel="noreferrer">
      <img className="h-12 w-auto" src="/img/logo.png" alt={t('common:skindr')} />
    </a>
  );
}
