import { useTranslation } from 'react-i18next';

import { ProblemInformationProps } from './ProblemInformation.types';
import DescriptionList from '../../../DescriptionList';
import DescriptionListItem from '../../../DescriptionList/DescriptionListItem';
import DotTag from '../../../tags/components/DotTag';
import SkindrAITag from '../../../tags/components/SkindrAITag';

export function ProblemInformation(props: ProblemInformationProps) {
  const { problem, consultation, patient, patientInputSummary } = props;
  const { t } = useTranslation();

  function renderSymptomTags(symptoms: string) {
    return symptoms?.split(', ').map((symptom, index) => <DotTag key={index} label={t(`common:${symptom}`)} />);
  }

  return (
    <DescriptionList>
      {patientInputSummary && (
        <DescriptionListItem
          term={
            <div className="flex">
              <span className="mr-1 mt-0.5">{t('doctor:patient_input_summary')}</span>
              <SkindrAITag />
            </div>
          }
          termValue={<div className="summary" dangerouslySetInnerHTML={{ __html: patientInputSummary.content }} />}
        />
      )}
      {consultation.skin_concern && (
        <DescriptionListItem
          term={t('doctor:skin_concern')}
          termValue={t(`doctor:consultation:skin_concern_values:${consultation.skin_concern}`)}
        />
      )}
      <DescriptionListItem term={t('doctor:first_occurred')} termValue={t(`common:${problem.first_occurred}`)} />
      <DescriptionListItem term={t('doctor:description')} termValue={problem.description} />
      {consultation.latest_consultation && (
        <DescriptionListItem
          term={t('doctor:latest_consultation')}
          termValue={t(`common:${consultation.latest_consultation}`)}
        />
      )}
      {consultation.latest_developments && (
        <DescriptionListItem term={t('doctor:latest_developments')} termValue={consultation.latest_developments} />
      )}
      {problem.visual_symptoms && (
        <DescriptionListItem
          term={t('doctor:visual_symptoms')}
          termValue={renderSymptomTags(problem.visual_symptoms)}
        />
      )}
      {problem.other_visual_symptoms && (
        <DescriptionListItem term={t('common:other_visual_symptoms')} termValue={problem.other_visual_symptoms} />
      )}
      {problem.sensational_symptoms && (
        <DescriptionListItem
          term={t('doctor:sensational_symptoms')}
          termValue={renderSymptomTags(problem.sensational_symptoms)}
        />
      )}
      {problem.other_sensational_symptoms && (
        <DescriptionListItem
          term={t('common:other_sensational_symptoms')}
          termValue={problem.other_sensational_symptoms}
        />
      )}
      {problem.discolouration && (
        <DescriptionListItem term={t('doctor:discolouration')} termValue={renderSymptomTags(problem.discolouration)} />
      )}
      {problem.patient_treatments && (
        <DescriptionListItem term={t('doctor:patient_treatments')} termValue={problem.patient_treatments} />
      )}
      {problem.doctor_treatments && (
        <DescriptionListItem term={t('doctor:doctor_treatments')} termValue={problem.doctor_treatments} />
      )}
      {patient.patient_settings?.allergies && (
        <DescriptionListItem term={t('doctor:allergies')} termValue={patient.patient_settings.allergies} />
      )}
      {consultation.medication && (
        <DescriptionListItem term={t('doctor:medication')} termValue={consultation.medication} />
      )}
      {problem.remarks && <DescriptionListItem term={t('doctor:remarks')} termValue={problem.remarks} />}
    </DescriptionList>
  );
}
