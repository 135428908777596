import { useEffect, useRef, useState } from 'react';

import { InfiniteScrollProps } from './InfiniteScroll.types';
import { Spinner } from '../state/components';

export const InfiniteScroll = <T extends object>(props: InfiniteScrollProps<T>) => {
  const { children, data, loadMoreCallback, wrapperClassName } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [loadMoreDisabled, setLoadMoreDisabled] = useState(false);
  const observerTarget = useRef(null);

  useEffect(() => {
    let ignore = false;

    const observer = new IntersectionObserver(
      async (entries) => {
        if (entries[0].isIntersecting) {
          await fetchMoreData();
        }
      },
      { threshold: 0.5 }
    );

    if (observer && observerTarget.current && !ignore) {
      observer.observe(observerTarget.current);
    }

    return () => {
      ignore = true;

      if (observer && observerTarget.current) {
        observer.unobserve(observerTarget.current);
      }
    };
  }, [data]);

  const fetchMoreData = async () => {
    setIsLoading(true);

    const hasMoreData = await loadMoreCallback();
    if (hasMoreData !== loadMoreDisabled) {
      setLoadMoreDisabled(true);
    }
    setIsLoading(false);
  };

  return (
    <div className={wrapperClassName}>
      {children}
      {isLoading && <Spinner className="h-8 w-8 mx-auto my-4" />}
      {!isLoading && data.length > 0 && !loadMoreDisabled && <div className="h-4 w-full" ref={observerTarget} />}
    </div>
  );
};
