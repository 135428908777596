export function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ');
}

function enableDocumentScrollLock(element: HTMLElement): void {
  element.style.overflow = 'hidden';
  element.style.height = '100vh';
}

function disableDocumentScrollLock(element: HTMLElement): void {
  element.style.overflow = '';
  element.style.height = '';
}

export function toggleDocumentScrollLock(flag: boolean) {
  if (flag) {
    enableDocumentScrollLock(document.documentElement);
    enableDocumentScrollLock(document.body);
    return;
  }

  disableDocumentScrollLock(document.documentElement);
  disableDocumentScrollLock(document.body);
}
