interface Props {
  children: React.ReactNode;
}

export function Main(props: Props) {
  const { children } = props;

  return (
    <main className="flex-1 relative overflow-y-auto focus:outline-none" tabIndex={0}>
      {children}
    </main>
  );
}
