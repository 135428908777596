import { PatientProfileConsultations } from './PatientProfileConsultations';
import { PatientProfileCreatedProfiles } from './PatientProfileCreatedProfiles';
import { PatientProfileExtraInformation } from './PatientProfileExtraInformation';
import { PatientProfileHeading } from './PatientProfileHeading';
import { PatientProfilePersonalData } from './PatientProfilePersonalData';
import { Patient } from '../../../common/consultation/types';

interface Props {
  patient: Patient;
}

export function PatientProfile(props: Props) {
  const { patient } = props;

  return (
    <main className="py-8">
      <PatientProfileHeading patient={patient} />
      <div className="mt-8 mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:grid-flow-col-dense lg:grid-cols-3">
        <div className="space-y-6 lg:col-start-1 lg:col-span-2">
          <PatientProfilePersonalData patient={patient} />
          <PatientProfileExtraInformation patient={patient} />
          {!!patient.created_users?.length && <PatientProfileCreatedProfiles patients={patient.created_users} />}
        </div>
        <div className="lg:col-start-3 lg:col-span-1">
          <PatientProfileConsultations patient={patient} />
        </div>
      </div>
    </main>
  );
}
