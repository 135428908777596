import ConsultationHistoryCard from './ConsultationHistoryCard';
import { Consultation } from '../../graphql/types';

interface Props {
  consultations: Consultation[];
}

export function ConsultationHistoryList(props: Props) {
  const { consultations } = props;

  function renderConsultations() {
    return consultations.map((consultation: Consultation, index: number) => {
      return (
        <li key={consultation.id} className={index ? 'border-t border-gray-200' : ''}>
          <ConsultationHistoryCard consultation={consultation} />
        </li>
      );
    });
  }

  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-md">
      <ul>{renderConsultations()}</ul>
    </div>
  );
}
