import { SwitchHorizontalIcon } from '@heroicons/react/outline';
import { useEffect, useState } from 'react';

import { TransferConsultationToggleButtonProps } from './TransferConsultationToggleButton.types';
import TransferConsultationModal from '../Modal';

export const TransferConsultationToggleButton = (props: TransferConsultationToggleButtonProps) => {
  const { consultationDoctorId, consultationUpdateCallback } = props;
  const [visible, setVisibility] = useState<boolean>(false);

  useEffect(() => {
    setVisibility(false);
  }, [consultationDoctorId]);

  return (
    <>
      <button
        title="Transfer Consultation Toggle"
        onClick={() => {
          setVisibility(true);
        }}
      >
        <SwitchHorizontalIcon className="h-5 w-5 text-gray-400" />
      </button>
      <TransferConsultationModal
        consultationDoctorId={consultationDoctorId}
        consultationUpdateCallback={consultationUpdateCallback}
        setVisibility={setVisibility}
        visible={visible}
      />
    </>
  );
};
