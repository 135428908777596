import { Dialog, Transition } from '@headlessui/react';
import { UsersIcon, XIcon, AnnotationIcon } from '@heroicons/react/outline';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useRouteMatch, matchPath } from 'react-router-dom';

import { Logo } from './Logo';
import { classNames } from '../../../common/utils/style';

interface Props {
  mobileSidebarOpen: boolean;
  setMobileSidebarOpen: (isOpen: boolean) => void;
}

export function Sidebar(props: Props) {
  const { mobileSidebarOpen, setMobileSidebarOpen } = props;
  const { t } = useTranslation();
  const match = useRouteMatch();

  const sidebarNavigation = [
    {
      name: t('common:dermatologists'),
      to: '/admin/doctors',
      icon: UsersIcon,
      current: Boolean(matchPath('/admin/doctors', { path: match.path })),
    },
    {
      name: t('common:templates'),
      to: '/admin/templates',
      icon: AnnotationIcon,
      current: Boolean(matchPath('/admin/templates', { path: match.path })),
    },
  ];

  return (
    <>
      <div className="hidden w-28 bg-white flex flex-col overflow-y-auto md:block border-r border-gray-200">
        <div className="w-full h-full py-2 flex flex-col justify-between">
          <div className="flex flex-col items-center">
            <Logo />
            <div className="mt-6 w-full px-2 space-y-1">
              {sidebarNavigation.map((item) => (
                <Link
                  key={item.name}
                  to={item.to}
                  className={classNames(
                    item.current ? 'bg-gray-100 text-gray-900' : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                    'group w-full p-3 rounded-md flex flex-col items-center text-xs font-medium'
                  )}
                  aria-current={item.current ? 'page' : undefined}
                >
                  <item.icon
                    className={classNames(
                      item.current ? 'text-gray-500' : 'text-gray-500 group-hover:text-gray-500',
                      'h-6 w-6'
                    )}
                    aria-hidden="true"
                  />
                  <span className="mt-2">{item.name}</span>
                </Link>
              ))}
            </div>
          </div>
        </div>
      </div>

      <Transition.Root show={mobileSidebarOpen} as={Fragment}>
        <Dialog as="div" static className="md:hidden" open={mobileSidebarOpen} onClose={setMobileSidebarOpen}>
          <div className="fixed inset-0 z-40 flex">
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <div className="relative max-w-xs w-full bg-white pt-5 pb-4 flex-1 flex flex-col">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-1 right-0 -mr-14 p-1">
                    <button
                      type="button"
                      className="h-12 w-12 rounded-full flex items-center justify-center focus:outline-none focus:ring-2 focus:ring-white"
                      onClick={() => setMobileSidebarOpen(false)}
                    >
                      <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                    </button>
                  </div>
                </Transition.Child>
                <Logo />
                <div className="mt-5 flex-1 h-0 px-2 overflow-y-auto">
                  <nav className="h-full flex flex-col justify-between">
                    <div className="space-y-1">
                      {sidebarNavigation.map((item) => (
                        <Link
                          key={item.name}
                          to={item.to}
                          className={classNames(
                            item.current
                              ? 'bg-gray-100 text-gray-900'
                              : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                            'group py-2 px-3 rounded-md flex items-center text-sm font-medium'
                          )}
                          aria-current={item.current ? 'page' : undefined}
                        >
                          <item.icon
                            className={classNames(
                              item.current ? 'text-gray-500' : 'text-gray-500 group-hover:text-gray-500',
                              'mr-3 h-6 w-6'
                            )}
                            aria-hidden="true"
                          />
                          <span>{item.name}</span>
                        </Link>
                      ))}
                    </div>
                  </nav>
                </div>
              </div>
            </Transition.Child>
            <div className="flex-shrink-0 w-14" aria-hidden="true" />
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
