import { MouseEvent } from 'react';

interface Props {
  label: string;
  onRemoveClick: (label: string) => void;
}

export function RemovableTag(props: Props) {
  const { label, onRemoveClick } = props;

  function onRemoveButtonClick(event: MouseEvent) {
    event.preventDefault();
    onRemoveClick(label);
  }

  return (
    <span className="inline-flex rounded-md items-center py-0.5 pl-2.5 pr-1 text-sm font-medium bg-teal-100 text-teal-700 mr-2">
      {label}
      <button
        type="button"
        className="flex-shrink-0 ml-0.5 h-4 w-4 rounded-full inline-flex items-center justify-center text-teal-400 hover:bg-teal-200 hover:text-teal-500 focus:outline-none focus:bg-teal-500 focus:text-white"
        onClick={onRemoveButtonClick}
      >
        <svg className="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
          <path strokeLinecap="round" strokeWidth="1.5" d="M1 1l6 6m0-6L1 7" />
        </svg>
      </button>
    </span>
  );
}
