import { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { UserContext } from '../../../common/authentication/UserContext';
import { User } from '../../../common/authentication/types';
import { LoadingState } from '../../../common/state/components';
import { getCannedResponses } from '../../../common/template/api';
import { CannedResponse, DoctorCannedResponse } from '../../../common/template/types';
import { DefaultTemplateList, TemplateList } from '../components';

export function TemplateIndexPage() {
  const { user } = useContext(UserContext);
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(true);
  const [canned_responses, setCannedResponses] = useState<CannedResponse[]>([]);
  const [doctor_canned_responses, setDoctorCannedResponses] = useState<DoctorCannedResponse[]>([]);

  useEffect(() => {
    async function collectCannedResponses() {
      const cannedResponses = await getCannedResponses(user as User);

      setCannedResponses(cannedResponses.canned_responses);
      setDoctorCannedResponses(cannedResponses.doctor_canned_responses);
      setLoading(false);
    }

    collectCannedResponses();
  }, [user]);

  function onSuccessfulDelete(deletedCannedResponse: DoctorCannedResponse): void {
    setDoctorCannedResponses(
      doctor_canned_responses.filter((canned_response: DoctorCannedResponse) => {
        return canned_response.id !== deletedCannedResponse.id;
      })
    );
  }

  if (loading) {
    return (
      <div className="w-full mx-auto p-4 sm:p-6 md:p-8">
        <LoadingState message={t('common:collecting_templates')} />
      </div>
    );
  }

  return (
    <div className="w-full mx-auto p-4 sm:p-6 md:p-8">
      <div className="bg-white overflow-hidden shadow sm:rounded-lg">
        <div className="px-4 py-5 sm:p-6">
          <TemplateList
            user={user as User}
            cannedResponses={doctor_canned_responses}
            onSuccessfulDelete={onSuccessfulDelete}
          />
          <DefaultTemplateList cannedResponses={canned_responses} />
        </div>
      </div>
    </div>
  );
}
