import { PaperClipIcon } from '@heroicons/react/outline';

import type { FileMessageProps } from './FileMessage.types';
import { FileMessage as FileMessageType } from '../../../doctor/graphql/types';
import { classNames } from '../../utils/style';
import ConversationWrapper from '../ConversationWrapper';

export function FileMessage(props: FileMessageProps) {
  const { deleteCallbackHandler, message, isUser, readonly } = props;

  if (message.deleted_at) {
    return null;
  }

  return (
    <ConversationWrapper
      deleteCallbackHandler={deleteCallbackHandler}
      isUser={isUser}
      item={message}
      readonly={readonly}
    >
      <div
        className={classNames(
          'inline-flex items-center px-4 py-2 rounded-lg text-sm leading-5 text-gray-500',
          isUser ? 'bg-indigo-100' : 'bg-gray-100',
          isUser && !readonly && !message.deleted_at && 'pr-8'
        )}
      >
        <a
          className="w-24 md:w-32 lg:w-full lg:max-w-2xl truncate inline-flex items-center"
          download
          rel="noopener noreferrer"
          href={(message.body as FileMessageType).url}
          target="_blank"
        >
          <PaperClipIcon className="flex-shrink-0 h-4 w-4 mt-0.5 mr-1" />
          <span className="underline">{(message.body as FileMessageType).label}</span>
        </a>
      </div>
    </ConversationWrapper>
  );
}
