import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import en from './en.json';
import fr from './fr.json';
import nl from './nl.json';
import type { SupportedLanguagesCode } from '../authentication/types';

export const SUPPORTED_LANGUAGES: SupportedLanguagesCode[] = ['nl', 'fr', 'en'];

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection: {
      order: ['querystring', 'localStorage'],
    },
    supportedLngs: SUPPORTED_LANGUAGES,
    fallbackLng: SUPPORTED_LANGUAGES[0],
    resources: {
      nl,
      fr,
      en,
    },
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
