import type { CheckboxTypesProps } from './Checkbox.types';

export function Checkbox(props: CheckboxTypesProps) {
  const { changeHandler, checked, error, id, label, name, value } = props;

  return (
    <>
      <div className="flex items-center">
        <input
          id={id}
          value={value}
          type="checkbox"
          name={name}
          className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
          checked={checked}
          onChange={changeHandler}
        />
        <label htmlFor={id} className="ml-2 block text-sm leading-5 text-gray-900 cursor-pointer">
          {label}
        </label>
      </div>
      {error && <p className="mt-1 text-sm text-red-600">{error}</p>}
    </>
  );
}
