import { MenuIcon } from '@heroicons/react/outline';

import { ProfileDropdown } from './ProfileDropdown';

interface Props {
  profileDropdownOpen: boolean;
  onOpenSidebarButtonClick: () => void;
  onProfileDropdownButtonClick: (event: React.MouseEvent<HTMLElement>) => void;
}

export function Topbar(props: Props) {
  const { profileDropdownOpen, onOpenSidebarButtonClick, onProfileDropdownButtonClick } = props;

  return (
    <div className="relative z-20 flex-shrink-0 flex h-16 bg-white shadow">
      <button
        onClick={onOpenSidebarButtonClick}
        className="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:bg-gray-100 focus:text-gray-600 md:hidden"
        aria-label="Open sidebar"
      >
        <MenuIcon className="h-6 w-6" />
      </button>
      <div className="flex-1 px-4 flex justify-end">
        <div className="ml-4 flex items-center md:ml-6">
          <ProfileDropdown
            profileDropdownOpen={profileDropdownOpen}
            onProfileDropdownButtonClick={onProfileDropdownButtonClick}
          />
        </div>
      </div>
    </div>
  );
}
