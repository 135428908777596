import { useTranslation } from 'react-i18next';

import NotAuthenticatedShell from '../../shell/NotAuthenticatedShell';
import { ForgotPasswordForm, Header } from '../components';

export function ForgotPasswordPage() {
  const { t } = useTranslation();

  return (
    <NotAuthenticatedShell>
      <Header>{t('authentication:did_you_forgot_your_password')}</Header>
      <p className="mt-2 text-center text-sm leading-5 text-gray-600 max-w">
        {t('authentication:we_will_send_you_a_reset_link')}
      </p>
      <div className="mt-10">
        <ForgotPasswordForm />
      </div>
    </NotAuthenticatedShell>
  );
}
