import { useContext } from 'react';

import { UserContext } from '../../../common/authentication/UserContext';
import { User } from '../../../common/authentication/types';
import { DoctorSettingsForm } from '../components';

export function DoctorSettingsPage() {
  const { user } = useContext(UserContext);

  return (
    <div className="w-full mx-auto p-4 sm:p-6 md:p-8">
      <DoctorSettingsForm user={user as User} />
    </div>
  );
}
