import { NotAuthenticatedShellProps } from './NotAuthenticatedShell.types';
import { Cover } from '../../authentication/components';
import { DesktopLanguageSelector } from '../components/DesktopLanguageSelector';
import { Logo } from '../components/Logo';

export const NotAuthenticatedShell = (props: NotAuthenticatedShellProps) => {
  const { children } = props;

  return (
    <div className="min-h-screen bg-white flex flex-col lg:flex-row">
      <div className="self-end pt-4 pr-4 lg:order-1 lg:self-start">
        <DesktopLanguageSelector />
      </div>

      <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
        <div className="mx-auto w-full max-w-sm md:w-96">
          <div className="flex justify-center">
            <Logo />
          </div>

          {children}
        </div>
      </div>

      <div className="hidden lg:block relative w-0 flex-1">
        <Cover />
      </div>
    </div>
  );
};
