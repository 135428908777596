import { useTranslation } from 'react-i18next';

import type { ProblemFollowUpInformationProps } from './ProblemFollowUpInformation.types';
import DescriptionList from '../../../DescriptionList';
import DescriptionListItem from '../../../DescriptionList/DescriptionListItem';

export function ProblemFollowUpInformation(props: ProblemFollowUpInformationProps) {
  const { consultation, patient } = props;
  const { t } = useTranslation();

  return (
    <DescriptionList>
      <DescriptionListItem term={t('doctor:latest_developments')} termValue={consultation.latest_developments} />
      {patient.patient_settings?.allergies && (
        <DescriptionListItem term={t('doctor:allergies')} termValue={patient.patient_settings.allergies} />
      )}
      {consultation.medication && (
        <DescriptionListItem term={t('doctor:medication')} termValue={consultation.medication} />
      )}
    </DescriptionList>
  );
}
