import { MailIcon, PhoneIcon, UserIcon, CakeIcon, IdentificationIcon } from '@heroicons/react/outline';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { copyToClipboard } from '../../../common/utils/clipboard';
import { calculateAge } from '../../../common/utils/date';
import type { User } from '../../graphql/types';

interface Props {
  patient: User;
}

export function CreatorHeader(props: Props) {
  const { patient } = props;
  const { t } = useTranslation();

  function onCopyIdentificationNumberClick(event: React.MouseEvent<HTMLElement>): void {
    event.preventDefault();
    if (patient.patient_settings && patient.patient_settings.identification_number) {
      const trimmedIdentificationNumber = patient.patient_settings.identification_number.replace(/\D/g, '');
      copyToClipboard(trimmedIdentificationNumber);
    }
  }

  return (
    <div className="bg-teal-50 opacity-60 border-b border-gray-200 z-0">
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="py-2 md:flex md:items-center md:justify-between">
          <div className="flex-1 min-w-0">
            <div className="flex items-center">
              <img
                className="hidden h-12 w-12 rounded-full sm:block object-cover"
                src={patient.avatar}
                alt={patient.first_name}
              />
              <div>
                <div className="flex items-center">
                  <img className="h-12 w-12 rounded-full sm:hidden" src={patient.avatar} alt={patient.first_name} />
                  <h1 className="ml-3 text-lg font-bold leading-5 text-gray-900 sm:leading-5 sm:truncate">
                    {t('common:created_by')} {patient.first_name} {patient.last_name}
                  </h1>
                </div>
                <dl className="mt-6 flex flex-col sm:ml-3 sm:mt-1 sm:flex-row sm:flex-wrap">
                  {patient.email && (
                    <>
                      <dt className="sr-only">{t('common:email_address')}</dt>
                      <dd className="flex items-center text-sm leading-5 text-gray-500 font-medium sm:mr-2">
                        <MailIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" />
                        {patient.email}
                      </dd>
                    </>
                  )}
                  {patient.patient_settings?.phone_number && (
                    <>
                      <dt className="sr-only">{t('common:phone_number')}</dt>
                      <dd className="mt-3 flex items-center text-sm leading-5 text-gray-500 font-medium sm:mr-2 sm:mt-0">
                        <PhoneIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" />
                        {patient.patient_settings.phone_number}
                      </dd>
                    </>
                  )}
                  {patient.patient_settings?.gender && (
                    <>
                      <dt className="sr-only">{t('common:gender')}</dt>
                      <dd className="mt-3 flex items-center text-sm leading-5 text-gray-500 font-medium sm:mr-2 sm:mt-0">
                        <UserIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" />
                        {t(`common:${patient.patient_settings.gender}`)}
                      </dd>
                    </>
                  )}
                  {patient.patient_settings?.date_of_birth && (
                    <>
                      <dt className="sr-only">{t('common:age')}</dt>
                      <dd className="mt-3 flex items-center text-sm leading-5 text-gray-500 font-medium sm:mr-2 sm:mt-0">
                        <CakeIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" />
                        {calculateAge(patient.patient_settings.date_of_birth)}
                      </dd>
                    </>
                  )}
                  {patient.patient_settings?.identification_number && (
                    <>
                      <dt className="sr-only">{t('common:identification_number')}</dt>
                      <Link to="/" onClick={onCopyIdentificationNumberClick}>
                        <dd className="mt-3 flex items-center text-sm leading-5 text-gray-500 hover:text-gray-400 font-medium sm:mr-2 sm:mt-0 transition ease-in-out duration-150">
                          <IdentificationIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" />
                          {patient.patient_settings.identification_number}
                        </dd>
                      </Link>
                    </>
                  )}
                </dl>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
