import { MenuIcon } from '@heroicons/react/outline';
import React from 'react';

import { MobileHeaderProps } from './MobileHeader.types';
import { Logo } from '../Logo';

export const MobileHeader = (props: MobileHeaderProps) => {
  const { callback } = props;

  return (
    <div className="flex md:hidden items-center px-4 py-2">
      <Logo />
      <button type="button" className="ml-auto p-2 text-black" onClick={callback}>
        <MenuIcon className="h-6 w-6" aria-hidden="true" />
      </button>
    </div>
  );
};
