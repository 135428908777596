import { useTranslation } from 'react-i18next';

import { PatientProfileConsultationCard } from './PatientProfileConsultationCard';
import { Patient } from '../../../common/consultation/types';

interface Props {
  patient: Patient;
}

export function PatientProfileConsultations(props: Props) {
  const { patient } = props;
  const { t } = useTranslation();

  return (
    <section>
      <div className="bg-white shadow sm:rounded-lg">
        <div className="px-4 py-5 sm:px-6">
          <h2 className="text-lg leading-6 font-medium text-gray-900">{t('doctor:patients:consultations')}</h2>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">{t('doctor:patients:consultation_history')}</p>
        </div>
        <div className="border-t border-gray-200 px-4 py-4">
          <div className="bg-white shadow overflow-hidden">
            <ul className="divide-y divide-gray-200">
              {patient.consultations &&
                patient.consultations.map((consultation) => (
                  <PatientProfileConsultationCard key={consultation.id} patient={patient} consultation={consultation} />
                ))}
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}
