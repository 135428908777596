import { PencilAltIcon, ShoppingBagIcon } from '@heroicons/react/solid';
import { useTranslation } from 'react-i18next';

import { PersonalizedTagKeys, ToolbarProps } from './Toolbar.types';
import { classNames } from '../../../../utils/style';
import SkincarePlanPrompt from '../../SkincarePlanPrompt';

const personalizedTagKeys: PersonalizedTagKeys[] = [
  'patient_first_name',
  'patient_last_name',
  'doctor_first_name',
  'doctor_last_name',
  'creator_first_name',
  'creator_last_name',
];

export const Toolbar = (props: ToolbarProps) => {
  const { consultationNoteEnabled, setConsultationNoteEnabled } = props;
  const { i18n } = useTranslation();
  const { t } = useTranslation();

  const renderPersonalizedTag = (tags: PersonalizedTagKeys[]) => (
    <span className={classNames('ql-formats', consultationNoteEnabled && 'relative disabled')}>
      {consultationNoteEnabled && <div className="absolute top-0 right-0 w-full h-full" />}
      <select className="ql-variables">
        <option value="0">{t('quill_editor:variables')}</option>
        {tags.map((tag: PersonalizedTagKeys) => {
          const label = t(`quill_editor:${tag}`);
          const valueObj = {
            key: tag,
            label,
          };

          return (
            <option key={valueObj.key} value={JSON.stringify(valueObj)}>
              {label}
            </option>
          );
        })}
      </select>
    </span>
  );

  return (
    <>
      <SkincarePlanPrompt />

      <div id="toolbar" className={classNames('relative', i18n.language)}>
        {consultationNoteEnabled && (
          <span
            className="absolute z-50 top-full left-0 rounded bg-primary-dark text-white text-xs font-bold px-2 py-0.5"
            style={{ marginTop: '12px', marginLeft: '15px' }}
          >
            {t('doctor:consultation:consultation_notes:tag')}
          </span>
        )}
        <span className="ql-formats">
          <button disabled={consultationNoteEnabled} className="ql-bold" />
          <button disabled={consultationNoteEnabled} className="ql-italic" />
          <button disabled={consultationNoteEnabled} className="ql-underline" />
        </span>
        <span className="ql-formats">
          <button disabled={consultationNoteEnabled} className="ql-list" value="ordered" />
          <button disabled={consultationNoteEnabled} className="ql-list" value="bullet" />
        </span>
        <span className="ql-formats">
          <button disabled={consultationNoteEnabled} className="ql-link" />
        </span>
        <span className="ql-formats">
          <button disabled={consultationNoteEnabled} className="ql-skincarePlan">
            <ShoppingBagIcon />
          </button>
          {consultationNoteEnabled !== undefined && setConsultationNoteEnabled !== undefined && (
            <button
              className={classNames(consultationNoteEnabled && 'ql-active')}
              onClick={(event) => {
                event.preventDefault();
                setConsultationNoteEnabled(!consultationNoteEnabled);
              }}
            >
              <PencilAltIcon />
            </button>
          )}
        </span>
        {renderPersonalizedTag(personalizedTagKeys)}
      </div>
    </>
  );
};
