import { ExclamationIcon } from '@heroicons/react/outline';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { getAuthenticatedUser } from '../../../common/authentication/api';
import { User } from '../../../common/authentication/types';

interface Props {
  user: User;
  onSuccessfulVerificationCheck: (user: User) => void;
}

export function VerificationBanner(props: Props) {
  const { user, onSuccessfulVerificationCheck } = props;
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    async function updateVerificationStatus() {
      const verifiedUser = await getAuthenticatedUser(user);
      setLoading(false);

      if (verifiedUser.is_verified_doctor !== user.is_verified_doctor) {
        onSuccessfulVerificationCheck(verifiedUser);
      }
    }

    updateVerificationStatus();
  }, [user, onSuccessfulVerificationCheck]);

  async function onCheckVerificationLinkClick(event: React.MouseEvent<HTMLElement>): Promise<void> {
    event.preventDefault();

    if (!loading) {
      setLoading(true);

      const verifiedUser = await getAuthenticatedUser(user);
      setLoading(false);

      if (verifiedUser.is_verified_doctor !== user.is_verified_doctor) {
        onSuccessfulVerificationCheck(verifiedUser);
      }
    }
  }

  if (loading || user.is_verified_doctor) {
    return null;
  }

  return (
    <div className="rounded-md bg-yellow-50 p-4 -mt-2 mb-6">
      <div className="flex">
        <div className="flex-shrink-0">
          <ExclamationIcon className="h-5 w-5 text-yellow-400" />
        </div>
        <div className="ml-3">
          <h3 className="text-sm leading-5 font-medium text-yellow-800">{t('common:account_verification')}</h3>
          <div className="mt-2 text-sm leading-5 text-yellow-700">
            <p>
              {t('common:we_are_verifying_your_account')}
              <Link
                to="/"
                onClick={onCheckVerificationLinkClick}
                className="mt-1 block font-medium text-teal-600 hover:text-teal-500 focus:outline-none focus:underline transition ease-in-out duration-150"
              >
                {t('common:check_verification')}
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
