import { CalendarIcon, ClipboardListIcon, UserIcon } from '@heroicons/react/outline';
import { PencilAltIcon } from '@heroicons/react/solid';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { ConsultationHistoryCardProps } from './ConsultationHistoryCard.types';
import { StatusTag } from '../../../../common/consultation/components';
import { formatReadable } from '../../../../common/utils/date';

export const ConsultationHistoryCard = (props: ConsultationHistoryCardProps) => {
  const { consultation } = props;
  const { t } = useTranslation();

  return (
    <Link
      className="block w-full px-4 py-4 hover:bg-gray-50 text-sm text-gray-500"
      to={`/doctor/consultations/${consultation.id}`}
    >
      <div className="flex justify-between">
        <div className="flex-grow">
          <h3 className="text-sm leading-5 font-medium text-teal-600 truncate capitalize text-left">
            {consultation.type}
          </h3>
          <ul className="mt-2 space-y-2">
            <li className="flex items-center">
              <UserIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
              {`Dr. ${consultation.doctor.last_name} ${consultation.doctor.first_name}`}
            </li>
            <li className="flex items-center">
              <ClipboardListIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
              <span className="truncate">
                {consultation.icd10_code && consultation.diagnosis
                  ? `${consultation.diagnosis.code} ${consultation.diagnosis.description}`
                  : t('doctor:patients:no_diagnosis_yet')}
              </span>
            </li>
            {consultation.medication && (
              <li className="flex items-center">
                <PencilAltIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                {consultation.medication}
              </li>
            )}
          </ul>
        </div>
        <div className="ml-2 flex flex-col items-end flex-shrink-0">
          <StatusTag status={consultation.status} />
          <div className="flex mt-2">
            <CalendarIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" />
            <time dateTime={formatReadable(consultation.created_at)}>{formatReadable(consultation.created_at)}</time>
          </div>
        </div>
      </div>
    </Link>
  );
};
