import { MailIcon, KeyIcon } from '@heroicons/react/outline';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { ValidationError } from '../../../common/api/types';
import { resetPassword } from '../../../common/authentication/api';
import { Button, Input } from '../../../common/form/components';

interface Props {
  email: string;
  token: string;
}
export function ResetPasswordForm(props: Props) {
  const { token, email: emailProps } = props;
  const { t } = useTranslation();
  const [email, setEmail] = useState<string>(emailProps);
  const [password, setPassword] = useState<string>('');
  const [error, setError] = useState<ValidationError>({} as ValidationError);
  const [loading, setLoading] = useState<boolean>(false);
  const [password_reset, setPasswordReset] = useState<boolean>(false);

  async function onResetPasswordFormSubmit(event: React.FormEvent<HTMLElement>): Promise<void> {
    event.preventDefault();

    if (!loading) {
      setLoading(true);
      setError({} as ValidationError);

      try {
        await resetPassword({
          token,
          email,
          password,
        });

        setEmail('');
        setPassword('');
        setLoading(false);
        setPasswordReset(true);
      } catch (error) {
        setError(error.error);
        setLoading(false);
      }
    }
  }

  if (password_reset) {
    return (
      <div className="bg-gray-50 sm:rounded-lg">
        <div className="px-4 py-5 sm:p-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">{t('authentication:your_password_was_reset')}</h3>
          <div className="mt-2 max-w-xl text-sm text-gray-500">
            <p>{t('authentication:your_password_has_been_reset')}</p>
          </div>
          <div className="mt-5">
            <Link
              to="/login"
              className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-teal-600 hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500"
            >
              {t('authentication:login_again')}
            </Link>
          </div>
        </div>
      </div>
    );
  }

  return (
    <form className="space-y-2" onSubmit={onResetPasswordFormSubmit}>
      <div className="space-y-4">
        <Input
          icon={<MailIcon className="h-5 w-5 text-gray-400" />}
          label={t('common:email_address')}
          placeholder={t('common:email_address')}
          value={email}
          onChange={(event) => setEmail(event.target.value)}
          error={error.email}
          id="email"
          name="email"
          type="email"
          autoComplete="email"
          required
        />

        <Input
          icon={<KeyIcon className="h-5 w-5 text-gray-400" />}
          label={t('common:new_password')}
          placeholder={t('common:new_password')}
          value={password}
          onChange={(event) => setPassword(event.target.value)}
          error={error.password}
          id="password"
          name="password"
          type="password"
          autoComplete="current-password"
          required
        />
      </div>

      <div className="pt-8">
        <Button label={t('authentication:reset_password')} block type="submit" loading={loading} disabled={loading} />
      </div>
    </form>
  );
}
