import { useTranslation } from 'react-i18next';

import type { CosmeticRequest, User } from '../../graphql/types';

interface Props {
  cosmeticRequest: CosmeticRequest;
  patient: User;
}

export function CosmeticRequestInformation(props: Props) {
  const { cosmeticRequest, patient } = props;
  const { t } = useTranslation();

  return (
    <dl className="grid grid-cols-1 gap-x-4 gap-y-4 sm:grid-cols-2">
      <div className="sm:col-span-2">
        <dt className="text-sm leading-5 font-medium text-gray-500">{t('doctor:expectations')}</dt>
        <dd className="mt-1 text-sm leading-5 text-gray-900">{cosmeticRequest.expectations}</dd>
      </div>
      {cosmeticRequest.expected_treatments && (
        <div className="sm:col-span-2">
          <dt className="text-sm leading-5 font-medium text-gray-500">{t('doctor:expected_treatments')}</dt>
          <dd className="mt-1 text-sm leading-5 text-gray-900">{cosmeticRequest.expected_treatments}</dd>
        </div>
      )}
      {cosmeticRequest.previous_treatments && (
        <div className="sm:col-span-2">
          <dt className="text-sm leading-5 font-medium text-gray-500">{t('doctor:previous_treatments')}</dt>
          <dd className="mt-1 text-sm leading-5 text-gray-900">{cosmeticRequest.previous_treatments}</dd>
        </div>
      )}
      {cosmeticRequest.previous_experiences && (
        <div className="sm:col-span-2">
          <dt className="text-sm leading-5 font-medium text-gray-500">{t('doctor:previous_experiences')}</dt>
          <dd className="mt-1 text-sm leading-5 text-gray-900">{cosmeticRequest.previous_experiences}</dd>
        </div>
      )}
      {patient.patient_settings?.allergies && (
        <div className="sm:col-span-2">
          <dt className="text-sm leading-5 font-medium text-gray-500">{t('doctor:allergies')}</dt>
          <dd className="mt-1 text-sm leading-5 text-gray-900">{patient.patient_settings.allergies}</dd>
        </div>
      )}
    </dl>
  );
}
