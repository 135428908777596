import { ExclamationCircleIcon } from '@heroicons/react/outline';
import { InputHTMLAttributes, ReactNode } from 'react';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  addOnLeft?: string;
  addOnRight?: string;
  icon?: ReactNode;
  error?: string;
}

export function Input(props: Props) {
  const { label, addOnLeft, addOnRight, icon, error, id, required, className, type, ...rest } = props;

  const defaultClasses = 'border-gray-300 placeholder-gray-400 focus:ring-teal-500 focus:border-teal-500';
  const errorClasses = 'border-red-300 placeholder-red-300 focus:ring-red-500 focus:border-red-500 text-red-900';
  const iconClasses = 'pl-10';
  const addOnClasses = 'rounded-none rounded-r-md';
  const noAddOnClasses = 'rounded-md';

  return (
    <div className={className}>
      {label && (
        <label htmlFor={id} className="block text-sm font-medium text-gray-700 text-left">
          {label}
          {required && '*'}
        </label>
      )}
      <div className={`flex relative rounded-md shadow-sm ${label && 'mt-1'}`}>
        {addOnLeft && (
          <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
            {addOnLeft}
          </span>
        )}
        {icon && <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">{icon}</div>}
        {addOnRight && (
          <div
            className={`absolute inset-y-0 right-0 flex items-center pointer-events-none ${
              error || type === 'number' ? 'pr-10' : 'pr-3'
            }`}
          >
            <span className="text-gray-500 sm:text-sm">{addOnRight}</span>
          </div>
        )}
        {error && (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <ExclamationCircleIcon className="h-5 w-5 text-red-500" />
          </div>
        )}
        <input
          className={`appearance-none block w-full sm:text-sm focus:outline-none ${
            error ? errorClasses : defaultClasses
          } ${icon && iconClasses} ${addOnLeft ? addOnClasses : noAddOnClasses}`}
          id={id}
          required={required}
          type={type}
          {...rest}
        />
      </div>
      {error && (
        <p className="mt-2 text-sm text-red-600" id={`${id}-error`}>
          {error}
        </p>
      )}
    </div>
  );
}
