import { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { UserContext } from '../../../common/authentication/UserContext';
import { User } from '../../../common/authentication/types';
import { LoadingState } from '../../../common/state/components';
import { getDefaultCannedResponse } from '../../../common/template/api';
import { CannedResponse } from '../../../common/template/types';

interface Params {
  id: string;
}

export function DefaultTemplateViewPage() {
  const { user } = useContext(UserContext);
  const { id } = useParams<Params>();
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(true);
  const [canned_response, setCannedResponse] = useState<CannedResponse | null>(null);

  useEffect(() => {
    async function collectCannedResponse() {
      const cannedResponse = await getDefaultCannedResponse(user as User, parseInt(id, 10));

      setCannedResponse(cannedResponse);
      setLoading(false);
    }

    collectCannedResponse();
  }, [user, id]);

  if (loading) {
    return (
      <div className="w-full mx-auto p-4 sm:p-6 md:p-8">
        <LoadingState message={t('common:collecting_template')} />
      </div>
    );
  }

  return (
    <div className="w-full mx-auto p-4 sm:p-6 md:p-8">
      <div className="bg-white shadow px-4 py-5 sm:rounded-t-lg sm:p-6">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <h3 className="text-lg font-medium leading-6 text-gray-900">{canned_response?.title}</h3>
            <p className="mt-1 text-sm leading-5 text-gray-500">{t('common:see_a_preview_of_your_template')}</p>
          </div>
          <div className="mt-5 md:mt-0 md:col-span-2">
            <div className="flex justify-end my-4 message">
              <div className="bg-indigo-100 sm:rounded-lg">
                <div className="px-4 py-2">
                  <div className="max-w-2xl text-sm leading-5 text-gray-500">
                    <div
                      className="whitespace-pre-wrap"
                      dangerouslySetInnerHTML={{ __html: canned_response?.template || '' }}
                    />
                  </div>
                </div>
              </div>
              <img
                className="ml-4 inline-block h-12 w-12 rounded-full object-cover"
                src={user?.avatar}
                alt={user?.first_name}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
