import { isReactAppEnvDevelopment, isReactAppEnvProduction, isReactAppEnvStaging } from './env-helper';
import { User } from '../authentication/types';

const getFeatureFlagUsers = (): string[] => {
  return process.env.REACT_APP_FEATURE_FLAG_USERS?.split(',') || [];
};

export const enableSkincarePlanButton = (doctor: User): boolean => {
  const featureFlagUsers = getFeatureFlagUsers();
  return (
    isReactAppEnvDevelopment() ||
    (isReactAppEnvStaging() && featureFlagUsers.includes(doctor.id.toString())) ||
    (isReactAppEnvProduction() && featureFlagUsers.includes(doctor.id.toString()))
  );
};
