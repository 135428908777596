import { AnnotationIcon, ChatAltIcon } from '@heroicons/react/outline';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { ValidationError } from '../../../common/api/types';
import { User } from '../../../common/authentication/types';
import { Button, Select, Input } from '../../../common/form/components';
import QuillEditor from '../../../common/form/components/QuillEditor';
import { storeCannedResponse } from '../../../common/template/api';

interface Props {
  user: User;
}

export function CreateTemplateForm(props: Props) {
  const { user } = props;
  const { t } = useTranslation();
  const history = useHistory();
  const [title, setTitle] = useState<string>('');
  const [template, setTemplate] = useState<string>('');
  const [language, setLanguage] = useState<string>('nl');
  const [error, setError] = useState<ValidationError>({} as ValidationError);
  const [submitting, setSubmitting] = useState<boolean>(false);

  async function onCreateTemplateFormSubmit(event: React.FormEvent<HTMLElement>): Promise<void> {
    event.preventDefault();

    if (!submitting) {
      setSubmitting(true);
      setError({} as ValidationError);

      try {
        await storeCannedResponse(user, {
          title,
          template,
          language,
        });

        setSubmitting(false);
        history.push('/doctor/templates');
      } catch (errors) {
        setError(errors.error);
        setSubmitting(false);
      }
    }
  }

  return (
    <form action="#" method="POST" onSubmit={onCreateTemplateFormSubmit}>
      <div className="bg-white shadow px-4 py-5 sm:rounded-t-lg sm:p-6">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <h3 className="text-lg font-medium leading-6 text-gray-900">{t('common:create_template')}</h3>
            <p className="mt-1 text-sm leading-5 text-gray-500">{t('common:create_a_new_template')}</p>
          </div>
          <div className="mt-5 md:mt-0 md:col-span-2">
            <div className="grid gap-6">
              <Input
                icon={<AnnotationIcon className="h-5 w-5 text-gray-400" />}
                label={t('common:name')}
                placeholder={t('common:name')}
                value={title}
                onChange={(event) => setTitle(event.target.value)}
                error={error.title}
                id="title"
                name="title"
                type="text"
                required
              />
              <QuillEditor
                label={t('common:template')}
                content={template}
                setContent={setTemplate}
                error={error.content}
                required
              />
              <Select
                icon={<ChatAltIcon className="h-5 w-5 text-gray-400" />}
                label={t('common:language')}
                value={language}
                onChange={(event) => setLanguage(event.target.value)}
                error={error.language}
                id="language"
                name="language"
                required
              >
                <option value="nl">{t('common:dutch')}</option>
                <option value="fr">{t('common:french')}</option>
                <option value="en">{t('common:english')}</option>
              </Select>
            </div>
          </div>
        </div>
      </div>

      <div className="shadow px-4 py-3 bg-gray-50 text-right sm:px-6 sm:rounded-b-lg">
        <Button label={t('common:save')} type="submit" loading={submitting} disabled={submitting} />
      </div>
    </form>
  );
}
