import { Transition } from '@headlessui/react';

import { History } from './History';
import { User } from '../../../common/authentication/types';
import { Consultation } from '../../graphql/types';

interface Props {
  isOpen: boolean;
  onCloseButtonClick: () => void;
  consultation: Consultation;
  user: User;
}

export function HistorySlideOver(props: Props) {
  const { isOpen, onCloseButtonClick, consultation, user } = props;

  return (
    <div className={`fixed inset-0 overflow-hidden z-40 ${isOpen ? 'pointer-events-auto' : 'pointer-events-none'}`}>
      <Transition
        show={isOpen}
        enter="ease-out duration-500 sm:duration-700"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-500 sm:duration-700"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        className="transition-opacity"
      >
        <div className="absolute inset-0 overflow-hidden bg-black opacity-75" onClick={onCloseButtonClick} />
      </Transition>
      <div>
        <section className="absolute inset-y-0 right-0 pl-10 max-w-full flex sm:pl-16">
          <Transition
            show={isOpen}
            enter="transform transition ease-in-out duration-500 sm:duration-700"
            enterFrom="translate-x-full"
            enterTo="translate-x-0"
            leave="transform transition ease-in-out duration-500 sm:duration-700"
            leaveFrom="translate-x-0"
            leaveTo="translate-x-full"
            className="w-72 sm:w-screen md:max-w-2xl"
          >
            <History consultation={consultation} onCloseButtonClick={onCloseButtonClick} user={user} />
          </Transition>
        </section>
      </div>
    </div>
  );
}
