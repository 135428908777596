import { IdentificationIcon, UserIcon, CalendarIcon } from '@heroicons/react/outline';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { calculateAge, formatReadable } from '../../../common/utils/date';
import { User } from '../../graphql/types';

interface Props {
  patient: User;
}

export function RelatedPatientRow(props: Props) {
  const { patient } = props;
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <tr
      className="opacity-60 cursor-pointer"
      onClick={() => {
        history.push(`/doctor/patients/${patient.id}`);
      }}
    >
      <td className="pl-20 py-2 whitespace-nowrap text-sm font-medium text-gray-900">
        <div className="flex items-center">
          <div className="flex-shrink-0 h-6 w-6">
            <img className="h-6 w-6 rounded-full" src={patient.avatar} alt={patient.first_name} />
          </div>
          <div className="ml-4">
            <div className="text-sm font-medium text-gray-900">
              {patient.first_name} {patient.last_name}
            </div>
          </div>
        </div>
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        <div className="flex items-center text-sm text-gray-500">
          <IdentificationIcon className="flex-shrink-0 mr-1 h-4 w-4 text-gray-400" />
          {patient.patient_settings.identification_number ? patient.patient_settings.identification_number : '-'}
        </div>
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        {patient.patient_settings.date_of_birth && (
          <div className="flex items-center text-sm text-gray-500">
            <CalendarIcon className="flex-shrink-0 mr-1 h-4 w-4 text-gray-400" />
            {formatReadable(patient.patient_settings.date_of_birth)} (
            {calculateAge(patient.patient_settings.date_of_birth)})
          </div>
        )}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        {patient.patient_settings.gender && (
          <div className="flex items-center text-sm text-gray-500">
            <UserIcon className="flex-shrink-0 mr-1 h-4 w-4 text-gray-400" />
            {t(`common:${patient.patient_settings.gender}`)}
          </div>
        )}
      </td>
    </tr>
  );
}
