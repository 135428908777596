import { useMutation } from '@apollo/client';
import { Menu, Transition } from '@headlessui/react';
import { CheckIcon, UsersIcon } from '@heroicons/react/outline';
import { Fragment, useContext } from 'react';
import { useParams } from 'react-router';

import { AssignConsultationProps } from './AssignConsultation.types';
import { UserContext } from '../../../../common/authentication/UserContext';
import { User as OldUserType } from '../../../../common/authentication/types';
import { classNames } from '../../../../common/utils/style';
import { TOGGLE_ASSIGN_CONSULTATION } from '../../../graphql/mutations/consultation';
import { User } from '../../../graphql/types';
import { ConsultationDetailParams } from '../ConsultationDetail/ConsultationDetail.types';

export const AssignConsultation = (props: AssignConsultationProps) => {
  const { consultationUpdateCallback, doctorId, userSettings } = props;
  const { user } = useContext(UserContext);
  const [toggleAssignConsultation] = useMutation(TOGGLE_ASSIGN_CONSULTATION);
  const { id: consultationId } = useParams<ConsultationDetailParams>();

  const onClickHandler = async (userId: string): Promise<void> => {
    const response = await toggleAssignConsultation({
      variables: {
        consultationId,
        userId,
      },
    });

    if (response.data.assignConsultation) {
      consultationUpdateCallback(response.data.assignConsultation);
    } else if (response.errors) {
      console.error(response.errors);
    }
  };

  return (
    <Menu as="div" className="relative self-center">
      <Menu.Button className="mt-1">
        <UsersIcon className="h-5 w-5 text-gray-400" />
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute z-30 top-0 left-full ml-3 md:right-full md:left-auto md:mr-3 md:ml-0 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
          <h3 className="px-4 py-2 block text-sm font-medium leading-6 text-gray-900">Assign to:</h3>
          <div className="flex flex-col">
            {userSettings?.map((userSetting: User) => {
              const currentUser = (user as OldUserType).id === parseInt(userSetting.id, 10);
              const otherDoctor = userSetting.id !== doctorId && !userSetting.is_medical_care;
              const disabled = userSetting.pivot?.assigned || otherDoctor;

              return (
                <Menu.Item key={userSetting.id}>
                  <button
                    className={classNames(
                      currentUser ? 'order-1' : 'order-2',
                      otherDoctor && 'opacity-50',
                      disabled ? 'cursor-default' : 'cursor-pointer',
                      'px-4 py-2 text-sm'
                    )}
                    onClick={async () => {
                      await onClickHandler(userSetting.id);
                    }}
                    disabled={disabled}
                  >
                    <div className="flex items-center">
                      <img
                        src={userSetting.avatar}
                        alt=""
                        className="h-5 w-5 flex-shrink-0 rounded-full object-cover"
                      />
                      <span
                        className={classNames(
                          userSetting.pivot?.assigned ? 'font-semibold' : 'font-normal',
                          'ml-3 block truncate'
                        )}
                      >
                        {currentUser ? 'Me' : `${userSetting.first_name} ${userSetting.last_name}`}
                      </span>
                      <span
                        className={classNames(!userSetting.pivot?.assigned ? 'text-white' : 'text-teal-600', 'ml-auto')}
                      >
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    </div>
                  </button>
                </Menu.Item>
              );
            })}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};
