import type { DotTagProps } from './DotTag.types';
import { DotIcon } from '../../../icon/components';

export function DotTag(props: DotTagProps) {
  const { label } = props;

  return (
    <span className="inline-flex items-center mr-2 my-2 px-2 py-0.5 rounded text-xs font-medium leading-4 bg-teal-100 text-teal-800">
      <DotIcon className="mr-1.5 h-2 w-2 text-teal-400" />
      {label}
    </span>
  );
}
