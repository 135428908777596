import {
  CannedResponsesResponse,
  CannedResponseInput,
  DoctorCannedResponse,
  CannedResponse,
  SearchInput,
} from './types';
import { ValidationError } from '../api/ValidationError';
import * as api from '../api/api';
import { User } from '../authentication/types';

export async function getCannedResponses(user: User): Promise<CannedResponsesResponse> {
  try {
    const cannedResponsesResponse = await api.getCannedResponses(user);

    return cannedResponsesResponse.data.data;
  } catch (error) {
    throw new ValidationError('Validation failed', error.response.data.errors);
  }
}

export async function getDoctorCannedResponse(user: User, id: number): Promise<DoctorCannedResponse> {
  try {
    const cannedResponseResponse = await api.getDoctorCannedResponse(user, id);

    return cannedResponseResponse.data.data.canned_response;
  } catch (error) {
    throw new ValidationError('Validation failed', error.response.data.errors);
  }
}

export async function getDefaultCannedResponse(user: User, id: number): Promise<CannedResponse> {
  try {
    const cannedResponseResponse = await api.getDefaultCannedResponse(user, id);

    return cannedResponseResponse.data.data.canned_response;
  } catch (error) {
    throw new ValidationError('Validation failed', error.response.data.errors);
  }
}

export async function deleteCannedResponse(
  user: User,
  cannedResponse: DoctorCannedResponse
): Promise<DoctorCannedResponse> {
  try {
    const cannedResponseResponse = await api.deleteCannedResponse(user, cannedResponse);

    return cannedResponseResponse.data.data.doctor_canned_response;
  } catch (error) {
    throw new ValidationError('Validation failed', error.response.data.errors);
  }
}

export async function deleteDefaultCannedResponse(user: User, cannedResponse: CannedResponse): Promise<CannedResponse> {
  try {
    const cannedResponseResponse = await api.deleteDefaultCannedResponse(user, cannedResponse);

    return cannedResponseResponse.data.data.canned_response;
  } catch (error) {
    throw new ValidationError('Validation failed', error.response.data.errors);
  }
}

export async function searchCannedResponses(user: User, searchInput: SearchInput): Promise<CannedResponsesResponse> {
  try {
    const cannedResponsesResponse = await api.searchCannedResponses(user, searchInput);

    return cannedResponsesResponse.data.data;
  } catch (error) {
    throw new ValidationError('Validation failed', error.response.data.errors);
  }
}

export async function storeCannedResponse(
  user: User,
  cannedResponseInput: CannedResponseInput
): Promise<DoctorCannedResponse> {
  try {
    const cannedResponseResponse = await api.storeCannedResponse(user, cannedResponseInput);

    return cannedResponseResponse.data.data.doctor_canned_response;
  } catch (error) {
    throw new ValidationError('Validation failed', error.response.data.errors);
  }
}

export async function storeDefaultCannedResponse(
  user: User,
  cannedResponseInput: CannedResponseInput
): Promise<CannedResponse> {
  try {
    const cannedResponseResponse = await api.storeDefaultCannedResponse(user, cannedResponseInput);

    return cannedResponseResponse.data.data.canned_response;
  } catch (error) {
    throw new ValidationError('Validation failed', error.response.data.errors);
  }
}

export async function updateCannedResponse(
  user: User,
  cannedResponse: DoctorCannedResponse,
  cannedResponseInput: CannedResponseInput
): Promise<DoctorCannedResponse> {
  try {
    const cannedResponseResponse = await api.updateCannedResponse(user, cannedResponse, cannedResponseInput);

    return cannedResponseResponse.data.data.doctor_canned_response;
  } catch (error) {
    throw new ValidationError('Validation failed', error.response.data.errors);
  }
}

export async function updateDefaultCannedResponse(
  user: User,
  cannedResponse: CannedResponse,
  cannedResponseInput: CannedResponseInput
): Promise<CannedResponse> {
  try {
    const cannedResponseResponse = await api.updateDefaultCannedResponse(user, cannedResponse, cannedResponseInput);

    return cannedResponseResponse.data.data.canned_response;
  } catch (error) {
    throw new ValidationError('Validation failed', error.response.data.errors);
  }
}
