import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { UserContext } from '../../../common/authentication/UserContext';
import { User } from '../../../common/authentication/types';
import NotAuthenticatedShell from '../../shell/NotAuthenticatedShell';
import { Header } from '../components';
import SignUpForm from '../components/SignUpForm';

export function SignUpPage() {
  const { t } = useTranslation();
  const { setAuthenticatedUser } = useContext(UserContext);

  function onSuccessfulSignUp(user: User): void {
    setAuthenticatedUser(user);
  }

  return (
    <NotAuthenticatedShell>
      <Header>{t('authentication:create_account')}</Header>
      <p className="mt-2 text-center text-sm leading-5 text-gray-600 max-w">
        <Link
          to="/doctor/login"
          className="font-medium text-teal-600 hover:text-teal-500 focus:outline-none focus:underline transition ease-in-out duration-150"
        >
          {t('authentication:account_already')}
        </Link>
      </p>

      <div className="mt-10">
        <SignUpForm onSuccessfulSignUp={onSuccessfulSignUp} />
      </div>
    </NotAuthenticatedShell>
  );
}
