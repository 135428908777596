import { Listbox } from '@headlessui/react';
import { useTranslation } from 'react-i18next';

import type { TemplateSearchItemProps } from './TemplateSearchItem.types';

export function TemplateSearchItem(props: TemplateSearchItemProps) {
  const { item } = props;
  const { t } = useTranslation();

  return (
    <Listbox.Option value={item}>
      <div className="cursor-pointer hover:text-white hover:bg-teal-500 text-gray-900 cursor-default select-none relative py-2 pl-8 pr-4 border-b">
        <span className="font-semibold block">
          {item.title} ({t(`common:${item.language}`)})
        </span>
        <p className="line-clamp-3">{item.sanitized_template}</p>
      </div>
    </Listbox.Option>
  );
}
