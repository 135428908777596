import { useTranslation } from 'react-i18next';

export function Logo() {
  const { t } = useTranslation();

  return (
    <div className="flex-shrink-0 flex items-center ml-4 sm:ml-0">
      <a href="https://skindr.com">
        <img className="h-12 w-auto" src="/img/logo.png" alt={t('common:skindr')} />
      </a>
    </div>
  );
}
