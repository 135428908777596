import { useTranslation } from 'react-i18next';

export function PatientsTableHead() {
  const { t } = useTranslation();

  return (
    <thead className="bg-gray-50">
      <tr>
        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
          {t('doctor:patients:patient')}
        </th>
        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
          {t('doctor:patients:data')}
        </th>
        <th scope="col" className="relative px-6 py-3">
          <span className="sr-only">{t('doctor:patients:data')}</span>
        </th>
        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
          {t('doctor:patients:extra_information')}
        </th>
      </tr>
    </thead>
  );
}
