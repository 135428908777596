import { InformationCircleIcon } from '@heroicons/react/outline';
import { useTranslation } from 'react-i18next';

import type { OpenUntilTagProps } from './OpenUntilTag.types';
import { getTimeRemaining, TimeRemaining } from '../../../../common/utils/date';

export function OpenUntilTag(props: OpenUntilTagProps) {
  const { openUntil } = props;
  const { t } = useTranslation();
  const timeRemaining: TimeRemaining = getTimeRemaining(openUntil);

  function createDeadlineLabel() {
    if (timeRemaining.total <= 0) {
      return t('doctor:consultations:open_until:closed');
    }

    const timeLabels: string[] = [];
    if (timeRemaining.days) {
      timeLabels.push(t('doctor:consultations:open_until:day', { count: timeRemaining.days }));
    }
    if (timeRemaining.hours) {
      timeLabels.push(t('doctor:consultations:open_until:hour', { count: timeRemaining.hours }));
    }
    if (!timeRemaining.days && !timeRemaining.hours && timeRemaining.minutes) {
      timeLabels.push(t('doctor:consultations:open_until:minute', { count: timeRemaining.minutes }));
    }

    const timeLeft = timeLabels.join(' ');
    return t('doctor:consultations:open_until:patient_has_time_left', { time_left: timeLeft });
  }

  return (
    <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium leading-4 bg-indigo-100 text-indigo-800">
      <InformationCircleIcon className="mr-1 h-4 w-4 text-indigo-800" />
      {createDeadlineLabel()}
    </span>
  );
}
