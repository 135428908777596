import type { DescriptionListItemProps } from './DescriptionListItem.types';

export function DescriptionListItem(props: DescriptionListItemProps) {
  const { term, termValue } = props;

  return (
    <div className="sm:col-span-2">
      <dt className="text-sm leading-5 font-medium text-gray-500">{term}</dt>
      <dd className="mt-1 text-sm leading-5 text-gray-900">{termValue}</dd>
    </div>
  );
}
