import { Transition } from '@headlessui/react';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { UserContext } from '../../../common/authentication/UserContext';

interface Props {
  profileDropdownOpen: boolean;
  onProfileDropdownButtonClick: (event: React.MouseEvent<HTMLElement>) => void;
}

export function ProfileDropdown(props: Props) {
  const { profileDropdownOpen, onProfileDropdownButtonClick } = props;
  const { t } = useTranslation();
  const { user, unsetAuthenticatedUser } = useContext(UserContext);

  function onSignOutClick(event: React.MouseEvent<HTMLElement>): void {
    event.preventDefault();
    unsetAuthenticatedUser();
  }

  return (
    <div onClick={onProfileDropdownButtonClick} className="ml-3 relative">
      <div>
        <button
          className="flex text-sm border-2 border-transparent rounded-full focus:outline-none focus:border-gray-300 transition duration-150 ease-in-out"
          id="user-menu"
          aria-label="User menu"
          aria-haspopup="true"
        >
          <img className="h-8 w-8 rounded-full object-cover" src={user?.avatar} alt="" />
        </button>
      </div>
      <Transition
        show={profileDropdownOpen}
        enter="transition ease-out duration-200"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
        className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg z-50"
      >
        <div
          className="py-1 rounded-md bg-white ring-1 ring-black ring-opacity-5"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="user-menu"
        >
          <div className="px-4 py-3">
            <p className="text-sm leading-5">{t('common:signed_in_as')}</p>
            <p className="text-sm leading-5 font-medium text-gray-900 truncate">{user?.email}</p>
          </div>
          <div className="border-t border-gray-100" />
          <Link
            to="/settings"
            className="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out"
          >
            {t('common:settings')}
          </Link>
          <Link
            to="/doctor/login"
            onClick={onSignOutClick}
            className="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out"
          >
            {t('common:signout')}
          </Link>
        </div>
      </Transition>
    </div>
  );
}
