import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { CannedResponse } from '../../../common/template/types';
import { localeToLanguage } from '../../../common/utils/locale';

interface Props {
  cannedResponses: CannedResponse[];
}

export function DefaultTemplateList(props: Props) {
  const { t } = useTranslation();
  const { cannedResponses } = props;

  function renderCannedResponses() {
    return cannedResponses.map((cannedResponse: CannedResponse) => {
      return (
        <tr key={cannedResponse.id}>
          <td className="px-6 py-4 whitespace-nowrap text-sm leading-5 font-medium text-gray-900">
            {cannedResponse.title}
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-sm leading-5 text-gray-500 max-w-xl truncate">
            {localeToLanguage(cannedResponse.language, t)}
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-sm leading-5 text-gray-500 max-w-xl truncate">
            {cannedResponse.sanitized_template}
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-right text-sm leading-5 font-medium">
            <Link to={`/doctor/templates/${cannedResponse.id}`} className="text-teal-600 hover:text-teal-900">
              {t('common:view')}
            </Link>
          </td>
        </tr>
      );
    });
  }

  return (
    <>
      <div className="pb-5 border-b border-gray-200">
        <h3 className="text-lg leading-6 font-medium text-gray-900">{t('common:default_templates')}</h3>
      </div>

      <div className="flex flex-col">
        <div className="mt-2 mb-6 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead>
                  <tr>
                    <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                      {t('common:name')}
                    </th>
                    <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                      {t('common:language')}
                    </th>
                    <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                      {t('common:template')}
                    </th>
                    <th className="px-6 py-3 bg-gray-50" />
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">{renderCannedResponses()}</tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
