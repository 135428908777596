import { useQuery } from '@apollo/client';
import { createContext, useContext, useEffect, useState } from 'react';

import { PermissionProviderProps, PermissionsContextType } from './PermissionContext.types';
import { GET_USER_ROLES_PERMISSIONS } from '../../../doctor/graphql/models/User';
import { AppPermissionName, Permission } from '../../../doctor/graphql/types';
import { UserContext } from '../../authentication/UserContext';

export const PermissionsContext = createContext<PermissionsContextType>({} as PermissionsContextType);

export const PermissionsProvider = ({ children }: PermissionProviderProps) => {
  const { user } = useContext(UserContext);
  const [permissions, setPermissions] = useState<AppPermissionName[]>([]);
  const { data, refetch: refetchQuery } = useQuery(GET_USER_ROLES_PERMISSIONS, {
    skip: !user,
  });

  useEffect(() => {
    if (data && data?.me) {
      const allPermissions = data.me.roles.reduce(
        (acc: AppPermissionName[], role: any) => {
          const rolePermissions = role.permissions.map((permission: Permission) => permission.name);
          return [...acc, ...rolePermissions];
        },
        data.me.permissions.map((permission: Permission) => permission.name)
      );
      setPermissions(allPermissions);
    }
  }, [data]);

  useEffect(() => {
    if (!user && permissions.length > 0) {
      setPermissions([]);
    }
  }, [user]);

  const refetch = async () => {
    try {
      const { data } = await refetchQuery();
      if (data && data.me) {
        setPermissions(data.me.permissions.map((permission: Permission) => permission.name));
      }
    } catch (error) {
      console.error('Error refetching permissions:', error);
    }
  };

  return <PermissionsContext.Provider value={{ permissions, refetch }}>{children}</PermissionsContext.Provider>;
};
