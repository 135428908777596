import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ReactQuill, { Quill } from 'react-quill';

import { QuillEditorProps } from './QuillEditor.types';
import Toolbar from './Toolbar';
import PersonalizedTag from './blots/PersonalizedTag';
import { formats, modules } from './config';
import { classNames } from '../../../utils/style';

// CSS
import 'react-quill/dist/quill.snow.css';
import './CustomQuillCSS.css';

Quill.register(PersonalizedTag);

export const QuillEditor = (props: QuillEditorProps) => {
  const { content, consultationNoteEnabled, setContent, setConsultationNoteEnabled, label, error, required } = props;
  const quillRef = useRef<any>(undefined);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (quillRef === undefined && setConsultationNoteEnabled === undefined) {
      return;
    }

    quillRef.current.editor.root.dataset.placeholder = consultationNoteEnabled
      ? t('doctor:consultation:consultation_notes:placeholder')
      : '';
  }, [consultationNoteEnabled]);

  return (
    <>
      {label && (
        <label className="block text-sm font-medium text-gray-700 text-left">
          {label}
          {required && '*'}
        </label>
      )}

      <div className="flex flex-col flex-grow relative">
        <Toolbar
          consultationNoteEnabled={consultationNoteEnabled}
          setConsultationNoteEnabled={setConsultationNoteEnabled}
        />
        <ReactQuill
          ref={quillRef}
          className={classNames(consultationNoteEnabled && `quill--consultation-note ${i18n.language}`)}
          theme="snow"
          value={content}
          onChange={setContent}
          modules={modules}
          formats={formats}
          placeholder={consultationNoteEnabled ? 'Add a note...' : 'Write something...'}
        />
      </div>
      {error && <p className="mt-2 text-sm text-red-600">{error}</p>}
    </>
  );
};
