import { gql } from '@apollo/client';

export const GET_DOCTOR_PATIENTS = gql`
  query getDoctorPatients($input: GetDoctorPatients!) {
    getDoctorPatients(input: $input) {
      id
      email
      avatar
      first_name
      last_name
      patient_settings {
        birth_control
        date_of_birth
        gender
        id
        identification_number
        is_pregnant
        postal_code
        phone_number
      }
      created_users {
        avatar
        first_name
        id
        patient_settings {
          date_of_birth
          gender
          identification_number
        }
        last_name
      }
    }
  }
`;
