import { useQuery } from '@apollo/client';
import { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';

import ConsultationDetail from './ConsultationDetail';
import { ConsultationDetailParams } from './ConsultationDetail/ConsultationDetail.types';
import { FilterableConsultationList } from './FilterableConsultationList';
import { User } from '../../../common/authentication/types';
import { toggleConsultationReadStatus } from '../../../common/consultation/api';
import { ConsultationReadStatus, Filter } from '../../../common/consultation/types';
import { getICD10CodeSuggestions } from '../../../common/icd10/api';
import { ICD10Code } from '../../../common/icd10/types';
import { LoadingState } from '../../../common/state/components';
import { GET_DOCTOR_CONSULTATIONS } from '../../graphql/queries/consultations';
import { Consultation, GetDoctorConsultationsResponse } from '../../graphql/types';

interface Props {
  user: User;
  forceReload?: boolean;
  defaultFilter: Filter;
}

const CONSULTATION_LIMIT = 10;

export type ConsultationPermission = 'read' | 'write' | 'owner';

export function ConsultationInbox(props: Props) {
  const { user, forceReload, defaultFilter } = props;
  const { t } = useTranslation();
  const history = useHistory();
  const { id } = useParams<ConsultationDetailParams>();

  const {
    loading: consultations_loading,
    refetch,
    fetchMore,
  } = useQuery(GET_DOCTOR_CONSULTATIONS, {
    variables: {
      input: {
        statuses: defaultFilter.statuses.join(','),
        sortBy: defaultFilter.sortBy,
        query: defaultFilter.query,
        inboxPreset: defaultFilter.inboxPreset,
        offset: 0,
        limit: CONSULTATION_LIMIT,
        excludeIds: [],
      },
    },
    onCompleted: (dataResponse) => {
      handleConsultationResponse(dataResponse.getDoctorConsultations, false);
    },
  });

  const [filter, setFilter] = useState<Filter>(defaultFilter);
  const activeFilterRef = useRef(defaultFilter);
  const [selectedConsultationId, setSelectedConsultationId] = useState<string | null>(null);
  const [filterLoading, setFilterLoading] = useState<boolean>(false);
  const [icd10_codes_loading, setICD10CodesLoading] = useState<boolean>(true);
  const [consultations, setConsultations] = useState<Consultation[]>([]);
  const [totalConsultationsCount, setTotalConsultationsCount] = useState<number>(0);
  const [icd10_code_suggestions, setICD10CodeSuggestions] = useState<ICD10Code[]>([]);
  const [consultationListOpen, setConsultationListOpen] = useState(true);
  const [currentOffset, setCurrentOffset] = useState<number>(0);
  const loading = consultations_loading && icd10_codes_loading;

  useEffect(() => {
    async function collectICD10CodeSuggestions(): Promise<void> {
      const icd10Codes = await getICD10CodeSuggestions(user as User);

      setICD10CodeSuggestions(icd10Codes);
      setICD10CodesLoading(false);
    }

    collectICD10CodeSuggestions();
  }, []);

  const isFirstRun = useRef(true);
  useEffect((): void => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }

    if (forceReload) {
      setSelectedConsultationId(null);
      setConsultationListOpen(true);
    }

    activeFilterRef.current = filter;

    setCurrentOffset(0);
    setFilterLoading(true);
    collectConsultations();
  }, [filter, forceReload]);

  async function collectConsultations(): Promise<void> {
    const { data: refetchResponse } = await refetch({
      input: {
        statuses: filter.statuses.join(','),
        sortBy: filter.sortBy,
        query: filter.query,
        inboxPreset: filter.inboxPreset,
        offset: 0, // Calling the set function does not change the current state in the already executing code
        limit: CONSULTATION_LIMIT,
      },
    });
    if (activeFilterRef.current.inboxPreset === filter.inboxPreset) {
      handleConsultationResponse(refetchResponse.getDoctorConsultations, false);
    }
  }

  function handleConsultationResponse(
    getDoctorConsultationsResponse: GetDoctorConsultationsResponse,
    mergeConsultations: boolean = true
  ): void {
    setFilterLoading(false);
    setTotalConsultationsCount(getDoctorConsultationsResponse.totalCount);
    setConsultations((prevConsultations) =>
      mergeConsultations
        ? [...prevConsultations, ...getDoctorConsultationsResponse.consultations]
        : getDoctorConsultationsResponse.consultations
    );
  }

  function updateConsultationActivityStateById(
    consultationId: string,
    activityFlag: boolean
  ): Consultation[] | undefined {
    if (typeof consultations === 'undefined') {
      return;
    }

    return consultations?.reduce((previousValue: any, currentValue: Consultation): Consultation[] => {
      if (currentValue.id === consultationId) {
        return [...previousValue, { ...currentValue, has_new_activity: activityFlag }];
      }

      return [...previousValue, currentValue];
    }, []);
  }

  function onSelectConsultation(selectedConsultation: Consultation | null): void {
    if (selectedConsultation !== null) {
      const updatedConsultations = updateConsultationActivityStateById(selectedConsultation.id, false);
      if (updatedConsultations) {
        setConsultations(updatedConsultations);
      }
      setSelectedConsultationId(selectedConsultation.id);
      setConsultationListOpen(false);
      history.replace(`/doctor/consultations/${selectedConsultation.id}`);
    } else {
      setSelectedConsultationId(null);
      history.replace('/doctor/consultations');
    }
  }

  // TODO: remove consultation from state list when status not allowed in preset inbox
  function consultationListUpdateCallback(updatedConsultation: Consultation): void {
    if (consultations.length > 0) {
      setConsultations(
        consultations.map((consultation) => {
          // Graphql consultation id is of type String
          if (consultation.id === updatedConsultation.id) {
            return {
              ...consultation,
              status: updatedConsultation.status,
              icd10_code: updatedConsultation.icd10_code,
              user_settings: updatedConsultation.user_settings,
            };
          } else {
            return consultation;
          }
        })
      );
    }
  }

  async function onFilterChange(filter: Filter): Promise<void> {
    setFilter(filter);
    setTotalConsultationsCount(0);
  }

  async function updateConsultationHandler(consultation: Consultation, status: ConsultationReadStatus): Promise<void> {
    await toggleConsultationReadStatus(user, consultation, status);
    const updatedConsultations = updateConsultationActivityStateById(consultation.id, !consultation.has_new_activity);

    if (updatedConsultations) {
      setConsultations(updatedConsultations);
    }
  }

  async function loadMoreCallback() {
    const stateConsultationsId = consultations.map((consultation) => consultation.id);
    const fetchMoreResponse = await fetchMore({
      variables: {
        input: {
          statuses: filter.statuses.join(','),
          sortBy: filter.sortBy,
          query: filter.query,
          offset: currentOffset + CONSULTATION_LIMIT,
          limit: CONSULTATION_LIMIT,
          inboxPreset: filter.inboxPreset,
          excludeIds: stateConsultationsId,
        },
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        if (!fetchMoreResult || !fetchMoreResult.data) {
          return previousResult;
        }

        return {
          getDoctorConsultations: {
            ...previousResult.getDoctorConsultations,
            consultations: [
              ...previousResult.getDoctorConsultations.consultations,
              ...fetchMoreResult.data.getDoctorConsultations.consultations,
            ],
          },
        };
      },
    });
    setCurrentOffset((prevCurrentOffset) => prevCurrentOffset + CONSULTATION_LIMIT);
    handleConsultationResponse(fetchMoreResponse.data.getDoctorConsultations);

    return fetchMoreResponse.data.getDoctorConsultations.consultations.length < CONSULTATION_LIMIT;
  }

  return (
    <>
      {loading ? (
        <div className="w-full mx-auto p-4 sm:p-6 md:p-8">
          <LoadingState message={t('common:collecting_consultations')} />
        </div>
      ) : (
        <div className="w-full md:h-full">
          <FilterableConsultationList
            totalConsultationsCount={totalConsultationsCount}
            consultations={consultations}
            onSelectConsultation={onSelectConsultation}
            filter={filter}
            onFilterChange={onFilterChange}
            filterLoading={filterLoading}
            isVisible={consultationListOpen}
            setIsVisible={setConsultationListOpen}
            updateConsultationHandler={updateConsultationHandler}
            loadMoreCallback={loadMoreCallback}
          />
          {(selectedConsultationId || id) && (
            <ConsultationDetail
              user={user}
              consultationId={selectedConsultationId || id}
              consultationListUpdateCallback={consultationListUpdateCallback}
              onSelectConsultation={onSelectConsultation}
              icd10CodeSuggestions={icd10_code_suggestions}
              isVisible={!consultationListOpen}
              setConsultationListOpen={setConsultationListOpen}
            />
          )}
        </div>
      )}
    </>
  );
}
