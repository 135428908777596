import { CalendarIcon, ClipboardListIcon, ChatIcon } from '@heroicons/react/outline';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { StatusTag } from '../../../common/consultation/components';
import { Patient, Consultation } from '../../../common/consultation/types';
import { CosmeticRequest } from '../../../common/cosmetics/types';
import { Problem } from '../../../common/problem/types';
import { formatReadable } from '../../../common/utils/date';

interface Props {
  patient: Patient;
  consultation: Consultation;
}

export function PatientProfileConsultationCard(props: Props) {
  const { patient, consultation } = props;
  const { t } = useTranslation();

  return (
    <li key={consultation.id}>
      <Link to={`/doctor/consultations/${consultation.id}`} className="block hover:bg-gray-50">
        <div className="px-4 py-4">
          <div className="flex items-center justify-between">
            <p className="text-sm font-medium text-teal-600 truncate">
              {patient.first_name} {patient.last_name}
            </p>
            <StatusTag status={consultation.status} />
          </div>
          <div className="mt-2">
            <div className="mt-2 text-sm text-gray-500 space-y-2">
              <div className="flex items-center">
                <CalendarIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                <time dateTime={formatReadable(consultation.created_at)}>
                  {formatReadable(consultation.created_at)}
                </time>
              </div>
              <div className="flex items-center">
                <ClipboardListIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                <span className="truncate">
                  {consultation.icd10_code && consultation.diagnosis
                    ? `${consultation.diagnosis.code} ${consultation.diagnosis.description}`
                    : t('doctor:patients:no_diagnosis_yet')}
                </span>
              </div>
            </div>
          </div>
          <div className="mt-2 flex items-center text-sm leading-5 text-gray-500">
            <div className="flex">
              <ChatIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
              {consultation.consultable_type !== 'App\\Models\\CosmeticRequest' ? (
                <p className="line-clamp-3">{(consultation.consultable as Problem).description}</p>
              ) : (
                <p className="line-clamp-3">{(consultation.consultable as CosmeticRequest).expectations}</p>
              )}
            </div>
          </div>
        </div>
      </Link>
    </li>
  );
}
