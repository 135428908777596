import { useTranslation } from 'react-i18next';

import { PatientProfileCreatedProfileCard } from './PatientProfileCreatedProfileCard';
import { Patient } from '../../../common/consultation/types';

interface Props {
  patients: Patient[];
}

export function PatientProfileCreatedProfiles(props: Props) {
  const { patients } = props;
  const { t } = useTranslation();

  return (
    <section>
      <div className="bg-white shadow sm:rounded-lg">
        <div className="px-4 py-5 sm:px-6">
          <h2 className="text-lg leading-6 font-medium text-gray-900">{t('doctor:patients:related_patients')}</h2>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">
            {t('doctor:patients:patient_profiles_created_by_this_user')}
          </p>
        </div>
        <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
            {patients.map((patient: Patient) => (
              <PatientProfileCreatedProfileCard key={patient.id} patient={patient} />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}
