import {
  UserIcon,
  MailIcon,
  IdentificationIcon,
  PhoneIcon,
  ClipboardListIcon,
  OfficeBuildingIcon,
  LocationMarkerIcon,
  KeyIcon,
  ChatAltIcon,
  ClockIcon,
  AdjustmentsIcon,
  CalendarIcon,
  AcademicCapIcon,
} from '@heroicons/react/outline';
import heic2any from 'heic2any';
import { useState, useEffect, ChangeEvent, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { ValidationError } from '../../../common/api/types';
import { SupportedLanguages, User } from '../../../common/authentication/types';
import PermissionsContext from '../../../common/context/PermissionContext';
import { Button, Select, Textarea, Input } from '../../../common/form/components';
import Checkbox from '../../../common/form/components/Checkbox';
import CheckboxGroup from '../../../common/form/components/CheckboxGroup';
import { CheckboxGroupConfig } from '../../../common/form/components/CheckboxGroup/CheckboxGroup.types';
import { SuccessNotification } from '../../../common/notification/components';
import { updateSettings, updateDoctorSettings, getDoctorSettings, getDataExport } from '../../../common/settings/api';
import type { CountrySetting } from '../../../common/settings/types';
import { LoadingState } from '../../../common/state/components';
import { RemovableTag } from '../../../common/tags/components';
import { copyToClipboard } from '../../../common/utils/clipboard';
import { formatDay, formatSecondsToHours } from '../../../common/utils/date';

interface Props {
  user: User;
  onSuccessfulSettingsUpdate: (updatedUser: User) => void;
}

// TODO: duplicate code DoctorSettingsForm (admin) vs SettingsForm (doctor)
export function SettingsForm(props: Props) {
  const { user, onSuccessfulSettingsUpdate } = props;
  const permissionContext = useContext(PermissionsContext);
  const { t } = useTranslation();
  const [error, setError] = useState<ValidationError>({} as ValidationError);
  const [loading, setLoading] = useState<boolean>(true);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);

  // User information
  const [first_name, setFirstName] = useState<string>(user.first_name);
  const [last_name, setLastName] = useState<string>(user.last_name);
  const [email, setEmail] = useState<string>(user.email);
  const [language, setLanguage] = useState<string>(user.language);
  const [current_password, setCurrentPassword] = useState<string>('');
  const [new_password, setNewPassword] = useState<string>('');
  const [avatar, setAvatar] = useState<Blob | undefined>(undefined);
  const [avatar_preview, setAvatarPreview] = useState<string>(user.avatar);

  // Doctor settings
  const [license_number, setLicenseNumber] = useState<string>('');
  const [phone_number, setPhoneNumber] = useState<string>('');
  const [practice, setPractice] = useState<string>('');
  const [practice_city_nl, setPracticeCityNl] = useState<string>('');
  const [practice_city_fr, setPracticeCityFr] = useState<string>('');
  const [practice_city_en, setPracticeCityEn] = useState<string>('');
  const [about_nl, setAboutNL] = useState<string>('');
  const [about_fr, setAboutFR] = useState<string>('');
  const [about_en, setAboutEN] = useState<string>('');
  const [education, setEducation] = useState<string>('');
  const [startProfessionalCareer, setStartProfessionalCareer] = useState<string>('');

  const [average_response_time, setAverageResponseTime] = useState<number>(0);
  const [response_time, setResponseTime] = useState<string>('2_days');
  const [accepting_new_patients, setAcceptingNewPatients] = useState<boolean>(true);
  const [is_away, setIsAway] = useState<boolean>(false);
  const [mediris_integration, setMedirisIntegration] = useState<boolean>(false);
  const [consultationTransfer, setConsultationTransfer] = useState<boolean>(false);
  const [weekly_volume_limit, setWeeklyVolumeLimit] = useState<string>('');

  const [allowed_postal_codes, setAllowedPostalCodes] = useState<string[]>([]);
  const [current_allowed_postal_code, setCurrentAllowedPostalCode] = useState<string>('');
  const [allowedCountries, setAllowedCountries] = useState<CountrySetting[]>([]);
  const [supportedLanguages, setSupportedLanguages] = useState<SupportedLanguages[]>([]);

  // Checkbox groups
  const supportedLanguagesCheckboxGroup: CheckboxGroupConfig<SupportedLanguages> = {
    legend: t('doctor:settings:supported_languages'),
    name: 'supportedLanguages',
    changeHandler: onCheckboxGroupChangeHandler,
    items: ['dutch', 'french', 'english'],
  };

  useEffect(() => {
    async function collectDoctorInformation() {
      const {
        license_number,
        phone_number,
        supported_languages,
        about_nl,
        about_en,
        about_fr,
        response_time,
        average_response_time,
        practice,
        practice_city_nl,
        practice_city_fr,
        practice_city_en,
        accepting_new_patients,
        is_away,
        mediris_integration,
        weekly_volume_limit,
        allowed_postal_codes,
        countries,
        start_professional_career,
        education,
        consultation_transfer,
      } = await getDoctorSettings(user);

      setLicenseNumber(license_number);
      setPhoneNumber(phone_number);
      setSupportedLanguages(supported_languages ?? ['dutch']);
      setPractice(practice ?? '');
      setPracticeCityNl(practice_city_nl ?? '');
      setPracticeCityFr(practice_city_fr ?? '');
      setPracticeCityEn(practice_city_en ?? '');
      setAboutNL(about_nl ?? '');
      setAboutFR(about_fr ?? '');
      setAboutEN(about_en ?? '');
      setResponseTime(response_time);
      setAverageResponseTime(average_response_time);
      setAcceptingNewPatients(accepting_new_patients);
      setIsAway(is_away);
      setMedirisIntegration(mediris_integration);
      setWeeklyVolumeLimit(weekly_volume_limit ?? '');
      setAllowedPostalCodes(allowed_postal_codes ?? []);
      setAllowedCountries(countries ?? ['belgium']);
      setStartProfessionalCareer(start_professional_career ? formatDay(start_professional_career) : '');
      setEducation(education ?? '');
      setConsultationTransfer(consultation_transfer);
      setLoading(false);
    }
    if (user.is_doctor) {
      collectDoctorInformation();
    } else {
      setLoading(false);
    }
  }, [user]);

  async function onSettingsFormSubmit(event: React.FormEvent<HTMLElement>): Promise<void> {
    event.preventDefault();

    if (!submitting) {
      setSubmitting(true);
      setSuccess(false);
      setError({} as ValidationError);

      try {
        if (user.is_doctor) {
          await updateDoctorSettings(user, user.id, {
            license_number,
            phone_number,
            supported_languages: supportedLanguages,
            practice,
            practice_city_nl,
            practice_city_fr,
            practice_city_en,
            about_nl,
            about_fr,
            about_en,
            response_time,
            accepting_new_patients,
            is_away,
            mediris_integration,
            weekly_volume_limit,
            allowed_postal_codes,
            countries: allowedCountries,
            start_professional_career: startProfessionalCareer,
            education,
            consultation_transfer: consultationTransfer,
          });
        }

        const updatedUser = await updateSettings(user, user.id, {
          first_name,
          last_name,
          email,
          current_password,
          new_password,
          avatar,
          language,
        });

        await permissionContext.refetch();

        setCurrentPassword('');
        setNewPassword('');
        setSubmitting(false);
        setSuccess(true);

        onSuccessfulSettingsUpdate(updatedUser);
      } catch (errors) {
        setCurrentPassword('');
        setNewPassword('');
        setError(errors.error);
        setSubmitting(false);
      }
    }
  }

  async function onChangeAvatarClick(event: React.MouseEvent<HTMLElement>): Promise<void> {
    event.preventDefault();
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*, image/heic';
    document.body.appendChild(input);

    const reader = new FileReader();
    reader.onload = () => {
      setAvatarPreview(reader.result as string);
    };

    input.onchange = async (onChangeEvent: Event) => {
      let selectedAvatar = ((onChangeEvent.target as HTMLInputElement).files as FileList)[0] as Blob;
      if (selectedAvatar.type === 'image/heic') {
        selectedAvatar = (await heic2any({
          blob: selectedAvatar,
          toType: 'image/jpeg',
        })) as Blob;
      }
      reader.readAsDataURL(selectedAvatar);
      setAvatar(selectedAvatar);
      document.body.removeChild(input);
    };

    input.click();
  }

  async function onDownloadExportClick(event: React.MouseEvent<HTMLElement>): Promise<void> {
    event.preventDefault();

    try {
      const exportBlob = await getDataExport(user);
      const url = window.URL.createObjectURL(exportBlob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'Skindr-export.xlsx');
      document.body.appendChild(link);
      link.click();
    } catch (errors) {
      setError(errors.error);
    }
  }

  function onCopyProfileLinkButtonClick(event: React.MouseEvent<HTMLElement>): void {
    event.preventDefault();
    const profileLink = `${window.location.protocol}//${window.location.host}/doctors/${user.id}`;
    copyToClipboard(profileLink);
  }

  function onAllowedPostalCodesChange(postal_code: string) {
    setCurrentAllowedPostalCode(postal_code);
    if (postal_code.length === 4) {
      if (allowed_postal_codes.indexOf(postal_code) === -1) {
        setAllowedPostalCodes([...allowed_postal_codes, postal_code]);
      }
      setCurrentAllowedPostalCode('');
    }
  }

  function onRemoveAllowedPostalCode(postal_code: string) {
    const index = allowed_postal_codes.indexOf(postal_code);
    setAllowedPostalCodes([...allowed_postal_codes.slice(0, index), ...allowed_postal_codes.slice(index + 1)]);
  }

  function onCheckboxGroupChangeHandler(event: ChangeEvent<HTMLInputElement>) {
    const { value, checked } = event.target;

    if (checked) {
      setSupportedLanguages([...supportedLanguages, value as SupportedLanguages]);
    } else {
      setSupportedLanguages(supportedLanguages.filter((item) => item !== value));
    }
  }

  if (loading) {
    return <LoadingState message={t('common:collecting_settings')} />;
  }

  return (
    <form autoComplete="off" onSubmit={onSettingsFormSubmit}>
      <SuccessNotification
        title={t('common:settings_saved')}
        message={t('common:your_profile_settings_are_saved')}
        isOpen={success}
      />
      <div className="bg-white shadow px-4 py-5 sm:rounded-t-lg sm:p-6">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <h3 className="text-lg font-medium leading-6 text-gray-900">{t('common:personal_information')}</h3>
            <p className="mt-1 text-sm leading-5 text-gray-500">{t('common:use_a_valid_email_address')}</p>
          </div>
          <div className="mt-5 md:mt-0 md:col-span-2 space-y-4">
            <Input
              icon={<UserIcon className="h-5 w-5 text-gray-400" />}
              label={t('common:first_name')}
              placeholder={t('common:first_name')}
              value={first_name}
              onChange={(event) => setFirstName(event.target.value)}
              error={error.first_name}
              id="first_name"
              name="first_name"
              type="text"
              required
            />
            <Input
              icon={<UserIcon className="h-5 w-5 text-gray-400" />}
              label={t('common:last_name')}
              placeholder={t('common:last_name')}
              value={last_name}
              onChange={(event) => setLastName(event.target.value)}
              error={error.last_name}
              id="last_name"
              name="last_name"
              type="text"
              required
            />
            <Input
              icon={<MailIcon className="h-5 w-5 text-gray-400" />}
              label={t('common:email_address')}
              placeholder={t('common:email_address')}
              value={email}
              onChange={(event) => setEmail(event.target.value)}
              error={error.email}
              id="email"
              name="email"
              type="email"
              required
            />
            {user.is_doctor && (
              <>
                <Input
                  icon={<IdentificationIcon className="h-5 w-5 text-gray-400" />}
                  label={t('common:license_number')}
                  placeholder={t('common:license_number')}
                  value={license_number}
                  onChange={(event) => setLicenseNumber(event.target.value)}
                  error={error.license_number}
                  id="license_number"
                  name="license_number"
                  type="text"
                  required
                />
                <Input
                  icon={<PhoneIcon className="h-5 w-5 text-gray-400" />}
                  label={t('common:phone_number')}
                  placeholder={t('common:phone_number')}
                  value={phone_number}
                  onChange={(event) => setPhoneNumber(event.target.value)}
                  error={error.phone_number}
                  id="phone_number"
                  name="phone_number"
                  type="text"
                  required
                />
              </>
            )}

            <Select
              icon={<ChatAltIcon className="h-5 w-5 text-gray-400" />}
              label={t('common:language')}
              value={language}
              onChange={(event) => setLanguage(event.target.value)}
              error={error.language}
              id="language"
              name="language"
              required
            >
              <option value="nl">{t('common:dutch')}</option>
              <option value="fr">{t('common:french')}</option>
              <option value="en">{t('common:english')}</option>
            </Select>

            {user.is_doctor && (
              <>
                <CheckboxGroup
                  key={`fieldset-${supportedLanguagesCheckboxGroup.name}`}
                  columns={1}
                  label={`${supportedLanguagesCheckboxGroup.legend}*`}
                  error={error.supported_languages}
                >
                  {supportedLanguagesCheckboxGroup.items.map((item: SupportedLanguages) => (
                    <div key={`${supportedLanguagesCheckboxGroup.name}-${item}`} className="mt-2 inline-block w-full">
                      <Checkbox
                        changeHandler={supportedLanguagesCheckboxGroup.changeHandler}
                        checked={supportedLanguages.includes(item)}
                        id={`${supportedLanguagesCheckboxGroup.name}-${item}`}
                        label={t(`common:${item}`)}
                        name={supportedLanguagesCheckboxGroup.name}
                        value={item}
                      />
                    </div>
                  ))}
                </CheckboxGroup>

                <Input
                  icon={<OfficeBuildingIcon className="h-5 w-5 text-gray-400" />}
                  label={t('common:practice')}
                  placeholder={t('common:practice')}
                  value={practice}
                  onChange={(event) => setPractice(event.target.value)}
                  error={error.practice}
                  id="practice"
                  name="practice"
                  type="text"
                />
                <Input
                  icon={<AcademicCapIcon className="h-5 w-5 text-gray-400" />}
                  label={t('common:education')}
                  placeholder={t('common:education')}
                  value={education}
                  onChange={(event) => setEducation(event.target.value)}
                  error={error.education}
                  id="education"
                  name="education"
                  type="text"
                  required
                />
                <Input
                  icon={<CalendarIcon className="h-5 w-5 text-gray-400" />}
                  label={t('doctor:settings:start_professional_career')}
                  placeholder={t('common:date_of_birth')}
                  value={startProfessionalCareer}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    setStartProfessionalCareer(event.target.value);
                  }}
                  error={error.start_professional_career}
                  id="start_professional_career"
                  name="start_professional_career"
                  type="date"
                  required
                />
                <div className="grid md:grid-cols-3 gap-3">
                  <p className="md:col-span-3 text-sm font-medium text-gray-700">{t('common:practice_city')}</p>
                  <Input
                    icon={<LocationMarkerIcon className="h-5 w-5 text-gray-400" />}
                    placeholder={`${t('common:practice_city')} (${t('common:dutch')})`}
                    value={practice_city_nl}
                    onChange={(event) => setPracticeCityNl(event.target.value)}
                    error={error.practice_city}
                    id="practice_city_nl"
                    name="practice_city_nl"
                    type="text"
                  />
                  <Input
                    icon={<LocationMarkerIcon className="h-5 w-5 text-gray-400" />}
                    placeholder={`${t('common:practice_city')} (${t('common:french')})`}
                    value={practice_city_fr}
                    onChange={(event) => setPracticeCityFr(event.target.value)}
                    error={error.practice_city}
                    id="practice_city_fr"
                    name="practice_city_fr"
                    type="text"
                  />
                  <Input
                    icon={<LocationMarkerIcon className="h-5 w-5 text-gray-400" />}
                    placeholder={`${t('common:practice_city')} (${t('common:english')})`}
                    value={practice_city_en}
                    onChange={(event) => setPracticeCityEn(event.target.value)}
                    error={error.practice_city_en}
                    id="practice_city_en"
                    name="practice_city_en"
                    type="text"
                  />
                </div>
                <Textarea
                  icon={<ClipboardListIcon className="h-5 w-5 text-gray-400" />}
                  label={t('common:about_nl')}
                  placeholder={t('common:about_nl')}
                  value={about_nl}
                  onChange={(event) => setAboutNL(event.target.value)}
                  error={error.about_nl}
                  rows={3}
                  id="about_nl"
                  name="about_nl"
                  required
                />
                <Textarea
                  icon={<ClipboardListIcon className="h-5 w-5 text-gray-400" />}
                  label={t('common:about_fr')}
                  placeholder={t('common:about_fr')}
                  value={about_fr}
                  onChange={(event) => setAboutFR(event.target.value)}
                  error={error.about_fr}
                  rows={3}
                  id="about_fr"
                  name="about_fr"
                  required
                />
                <Textarea
                  icon={<ClipboardListIcon className="h-5 w-5 text-gray-400" />}
                  label={t('common:about_en')}
                  placeholder={t('common:about_en')}
                  value={about_en}
                  onChange={(event) => setAboutEN(event.target.value)}
                  error={error.about_en}
                  rows={3}
                  id="about_en"
                  name="about_en"
                  required
                />
              </>
            )}
            <div>
              <label className="block text-sm leading-5 font-medium text-gray-700">{t('common:avatar')}</label>
              <div className="mt-1 flex items-center">
                <span className="flex items-center h-20 w-20 rounded-full overflow-hidden bg-gray-100 object-cover">
                  <img
                    src={avatar_preview}
                    alt={user.first_name}
                    className="flex items-center h-20 w-20 rounded-full overflow-hidden bg-gray-100 object-cover"
                  />
                </span>
                <span className="ml-5 rounded-md shadow-sm">
                  <Button label={t('common:change')} type="button" variant="outline" onClick={onChangeAvatarClick} />
                </span>
              </div>
              {error.avatar && <p className="mt-1 text-sm text-red-600">{error.avatar}</p>}
            </div>
          </div>
        </div>

        {user.is_doctor && (
          <div className="mt-8 pt-8 border-t border-gray-200 md:grid md:grid-cols-3 md:gap-6">
            <div className="md:col-span-1">
              <h3 className="text-lg font-medium leading-6 text-gray-900">{t('common:settings')}</h3>
              <p className="mt-1 text-sm leading-5 text-gray-500">{t('common:global_settings')}</p>
            </div>
            <div className="mt-5 md:mt-0 md:col-span-2 space-y-4">
              <Select
                icon={<ClockIcon className="h-5 w-5 text-gray-400" />}
                label={t('common:response_time')}
                value={response_time}
                onChange={(event) => setResponseTime(event.target.value)}
                error={error.response_time}
                id="response_time"
                name="response_time"
                required
              >
                <option value="1_day">{t('common:1_day')}</option>
                <option value="2_days">{t('common:2_days')}</option>
                <option value="3_days">{t('common:3_days')}</option>
                <option value="4_days">{t('common:4_days')}</option>
                <option value="5_days">{t('common:5_days')}</option>
                <option value="6_days">{t('common:6_days')}</option>
                <option value="7_days">{t('common:7_days')}</option>
                <option value="average">
                  {t('common:my_average_response_time', { hours: formatSecondsToHours(average_response_time) })}
                </option>
              </Select>
              <Input
                icon={<AdjustmentsIcon className="h-5 w-5 text-gray-400" />}
                label={t('doctor:settings:maximum_number_of_consultations_per_week')}
                placeholder={t('doctor:settings:maximum_number_of_consultations_per_week')}
                value={weekly_volume_limit}
                onChange={(event) => setWeeklyVolumeLimit(event.target.value)}
                error={error.weekly_volume_limit}
                id="weekly_volume_limit"
                name="weekly_volume_limit"
                type="number"
              />
              <Input
                icon={<LocationMarkerIcon className="h-5 w-5 text-gray-400" />}
                label={t('doctor:settings:allowed_postal_codes')}
                placeholder={t('doctor:settings:allowed_postal_codes_placeholder')}
                value={current_allowed_postal_code}
                onChange={(event) => onAllowedPostalCodesChange(event.target.value)}
                error={error.allowed_postal_codes}
                id="allowed_postal_codes"
                name="allowed_postal_codes"
                type="text"
              />
              {!!allowed_postal_codes.length && (
                <div className="space-y-2">
                  {allowed_postal_codes.map((postal_code: string) => {
                    return (
                      <RemovableTag key={postal_code} label={postal_code} onRemoveClick={onRemoveAllowedPostalCode} />
                    );
                  })}
                </div>
              )}
              <div className="pt-2">
                <div className="flex items-center">
                  <input
                    id="accepting_new_patients"
                    type="checkbox"
                    className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                    checked={accepting_new_patients}
                    onChange={(event) => setAcceptingNewPatients(event.target.checked)}
                  />
                  <label htmlFor="accepting_new_patients" className="ml-2 block text-sm leading-5 text-gray-900">
                    {t('common:i_am_accepting_new_patients')}
                  </label>
                </div>
                {error.accepting_new_patients && (
                  <p className="mt-1 text-sm text-red-600">{error.accepting_new_patients}</p>
                )}
              </div>
              <div>
                <div className="flex items-center">
                  <input
                    id="is_away"
                    type="checkbox"
                    className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                    checked={is_away}
                    onChange={(event) => setIsAway(event.target.checked)}
                  />
                  <label htmlFor="is_away" className="ml-2 block text-sm leading-5 text-gray-900">
                    {t('common:i_am_unavailable')}
                  </label>
                </div>
                {error.is_away && <p className="mt-1 text-sm text-red-600">{error.is_away}</p>}
              </div>
              <div>
                <div className="flex items-center">
                  <input
                    id="mediris_integration"
                    type="checkbox"
                    className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                    checked={mediris_integration}
                    onChange={(event) => setMedirisIntegration(event.target.checked)}
                  />
                  <label htmlFor="mediris_integration" className="ml-2 block text-sm leading-5 text-gray-900">
                    {t('doctor:settings:enable_mediris_integration')}
                  </label>
                </div>
                {error.mediris_integration && <p className="mt-1 text-sm text-red-600">{error.mediris_integration}</p>}
              </div>
            </div>
          </div>
        )}

        <div className="mt-8 pt-8 border-t border-gray-200 md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <h3 className="text-lg font-medium leading-6 text-gray-900">{t('common:password')}</h3>
            <p className="mt-1 text-sm leading-5 text-gray-500">{t('common:this_is_optional')}</p>
          </div>
          <div className="mt-5 md:mt-0 md:col-span-2 space-y-4">
            <Input
              icon={<KeyIcon className="h-5 w-5 text-gray-400" />}
              label={t('common:current_password')}
              placeholder={t('common:current_password')}
              value={current_password}
              onChange={(event) => setCurrentPassword(event.target.value)}
              error={error.current_password}
              id="current_password"
              name="current_password"
              autoComplete="new-password"
              type="password"
            />
            <Input
              icon={<KeyIcon className="h-5 w-5 text-gray-400" />}
              label={t('common:new_password')}
              placeholder={t('common:new_password')}
              value={new_password}
              onChange={(event) => setNewPassword(event.target.value)}
              error={error.new_password}
              id="new_password"
              name="new_password"
              autoComplete="new-password"
              type="password"
            />
          </div>
        </div>
        {user.is_doctor && (
          <>
            <div className="mt-8 pt-8 border-t border-gray-200 md:grid md:grid-cols-3 md:gap-6">
              <div className="md:col-span-1">
                <h3 className="text-lg font-medium leading-6 text-gray-900">{t('common:privacy_and_data')}</h3>
                <p className="mt-1 text-sm leading-5 text-gray-500">{t('common:manage_your_privacy_and_data')}</p>
              </div>
              <div className="mt-5 md:mt-0 md:col-span-2">
                <div className="grid gap-6">
                  <div>
                    <label className="block text-sm font-medium leading-5 text-gray-700">
                      {t('common:view_your_doctor_profile')}
                    </label>
                    <div className="flex flex-col sm:flex-row items-center">
                      <Link
                        to={`/doctors/${user.id}`}
                        className="font-medium text-teal-600 hover:text-teal-500 focus:outline-none focus:underline transition ease-in-out duration-150 mt-2 sm:mt-0"
                      >
                        {`${window.location.protocol}//${window.location.host}/doctors/${user.id}`}
                      </Link>
                      <Button
                        className="ml-2"
                        label={t('common:copy')}
                        type="button"
                        variant="outline"
                        onClick={onCopyProfileLinkButtonClick}
                      />
                    </div>
                  </div>

                  <div>
                    <label className="block text-sm font-medium leading-5 text-gray-700">
                      {t('common:close_account')}
                    </label>
                    <p className="mt-1 text-sm leading-5 text-gray-500">
                      {t('common:contact_us_if_you_wish_to_close_your_account')}
                      <a
                        href="mailto:care@skindr.com"
                        className="font-medium text-teal-600 hover:text-teal-500 focus:outline-none focus:underline transition ease-in-out duration-150"
                      >
                        &nbsp;{t('common:close_account')}
                      </a>
                    </p>
                  </div>

                  <div>
                    <label className="block text-sm font-medium leading-5 text-gray-700">
                      {t('common:data_analysis')}
                    </label>
                    <p className="mt-1 text-sm leading-5 text-gray-500">
                      {t('common:contact_us_if_you_wish_to_be_excluded_from_analysis')}
                      <a
                        href="mailto:care@skindr.com"
                        className="font-medium text-teal-600 hover:text-teal-500 focus:outline-none focus:underline transition ease-in-out duration-150"
                      >
                        &nbsp;{t('common:exclude_me_from_analysis')}
                      </a>
                    </p>
                  </div>

                  <p className="mt-1 text-sm leading-5 text-gray-500">{t('common:export_your_personal_data')}</p>
                  <div>
                    <label className="block text-sm font-medium leading-5 text-gray-700">
                      {t('common:data_export')}
                    </label>
                    <div className="mt-1 flex items-center">
                      <span className="rounded-md shadow-sm">
                        <Button
                          label={t('common:export')}
                          type="button"
                          variant="outline"
                          onClick={onDownloadExportClick}
                        />
                      </span>
                    </div>
                    {error.export && <p className="mt-1 text-sm text-red-600">{error.export}</p>}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>

      <div className="shadow px-4 py-3 bg-gray-50 text-right sm:px-6 sm:rounded-b-lg">
        <Button label={t('common:save')} type="submit" loading={submitting} disabled={submitting} />
      </div>
    </form>
  );
}
