import { useTranslation } from 'react-i18next';

import { Select } from '../../../common/form/components';

export function DesktopLanguageSelector() {
  const { t, i18n } = useTranslation();

  function onLanguageChange(event: React.ChangeEvent<HTMLSelectElement>) {
    const language = event.target.value;
    localStorage.setItem('i18nextLng', language);
    i18n.changeLanguage(language);
  }

  return (
    <div className="items-center inline-flex ml-6">
      <Select value={i18n.language} onChange={onLanguageChange} id="locale" name="locale">
        <option value="nl">{t('common:nl')}</option>
        <option value="fr">{t('common:fr')}</option>
        <option value="en">{t('common:en')}</option>
      </Select>
    </div>
  );
}
