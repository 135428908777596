import { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { UserContext } from '../../../common/authentication/UserContext';
import { User } from '../../../common/authentication/types';
import { LoadingState } from '../../../common/state/components';
import { getDoctorCannedResponse } from '../../../common/template/api';
import { DoctorCannedResponse } from '../../../common/template/types';
import { EditTemplateForm } from '../components';

interface Params {
  id: string;
}

export function TemplateEditPage() {
  const { user } = useContext(UserContext);
  const { id } = useParams<Params>();
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(true);
  const [canned_response, setCannedResponse] = useState<DoctorCannedResponse | null>(null);

  useEffect(() => {
    async function collectCannedResponse() {
      const cannedResponse = await getDoctorCannedResponse(user as User, parseInt(id, 10));

      setCannedResponse(cannedResponse);
      setLoading(false);
    }

    collectCannedResponse();
  }, [user, id]);

  if (loading) {
    return (
      <div className="w-full mx-auto p-4 sm:p-6 md:p-8">
        <LoadingState message={t('common:collecting_template')} />
      </div>
    );
  }

  return (
    <div className="template-form-page w-full mx-auto p-4 sm:p-6 md:p-8">
      <EditTemplateForm user={user as User} cannedResponse={canned_response as DoctorCannedResponse} />
    </div>
  );
}
