import type { CountrySetting } from '../settings/types';

export function localeToLanguage(locale: string, t: Function) {
  switch (locale) {
    case 'nl':
      return t('common:dutch');
    case 'fr':
      return t('common:french');
    case 'en':
      return t('common:english');
    default:
      return locale;
  }
}

export function convertCountryCodeToCountrySetting(countryCode: string | undefined): CountrySetting | undefined {
  switch (countryCode) {
    case 'be':
      return 'belgium';
    case 'nl':
      return 'netherlands';
    default:
      return undefined;
  }
}
