import { useContext } from 'react';

import { UserContext } from '../../../common/authentication/UserContext';
import { User } from '../../../common/authentication/types';
import DoctorShell from '../../shell/DoctorShell';
import { SettingsForm, VerificationBanner } from '../components';

export function SettingsPage() {
  const { user, setAuthenticatedUser } = useContext(UserContext);

  function onSuccessfulSettingsUpdate(updatedUser: User): void {
    setAuthenticatedUser({
      ...user,
      ...updatedUser,
    });
  }

  return (
    <DoctorShell>
      <div className="w-full mx-auto p-4 sm:p-6 md:p-8">
        {user && (
          <>
            {!user.is_medical_care && (
              <VerificationBanner user={user} onSuccessfulVerificationCheck={onSuccessfulSettingsUpdate} />
            )}
            <SettingsForm user={user} onSuccessfulSettingsUpdate={onSuccessfulSettingsUpdate} />
          </>
        )}
      </div>
    </DoctorShell>
  );
}
