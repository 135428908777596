import { Dialog } from '@headlessui/react';
import { UserAddIcon, UserIcon, MailIcon, ClockIcon } from '@heroicons/react/outline';
import { useState, FormEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { ValidationError } from '../../../common/api/types';
import { User } from '../../../common/authentication/types';
import { storePatientInvitation } from '../../../common/consultation/api';
import { Button, Input } from '../../../common/form/components';

interface Props {
  user: User;
  setOpen: (open: boolean) => void;
}

export function InvitePatientForm(props: Props) {
  const { user, setOpen } = props;
  const { t } = useTranslation();

  const [first_name, setFirstName] = useState<string>('');
  const [last_name, setLastName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [weeks_until_reminder, setWeeksUntilReminder] = useState<string>('2');
  const [error, setError] = useState<ValidationError>({} as ValidationError);
  const [loading, setLoading] = useState<boolean>(false);

  async function onInvitePatientFormSubmit(event: FormEvent<HTMLElement>): Promise<void> {
    event.preventDefault();

    if (!loading) {
      setLoading(true);
      setError({} as ValidationError);

      try {
        await storePatientInvitation(user, {
          first_name,
          last_name,
          email,
          weeks_until_reminder: parseInt(weeks_until_reminder, 10),
        });

        setLoading(false);
        setOpen(false);
      } catch (error) {
        setError(error.error);
        setLoading(false);
      }
    }
  }

  return (
    <form onSubmit={onInvitePatientFormSubmit}>
      <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
        <div className="sm:flex sm:items-start">
          <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-teal-100 sm:mx-0 sm:h-10 sm:w-10">
            <UserAddIcon className="h-6 w-6 text-teal-600" aria-hidden="true" />
          </div>
          <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
            <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
              {t('doctor:patients:invite_patient')}
            </Dialog.Title>
            <p className="text-sm text-gray-500">{t('doctor:patients:invite_patient_explanation')}</p>
            <div className="mt-4 space-y-4">
              <Input
                icon={<UserIcon className="h-5 w-5 text-gray-400" />}
                label={t('common:first_name')}
                placeholder={t('common:first_name')}
                value={first_name}
                onChange={(event) => setFirstName(event.target.value)}
                error={error.first_name}
                id="first_name"
                name="first_name"
                type="text"
                required
              />
              <Input
                icon={<UserIcon className="h-5 w-5 text-gray-400" />}
                label={t('common:last_name')}
                placeholder={t('common:last_name')}
                value={last_name}
                onChange={(event) => setLastName(event.target.value)}
                error={error.last_name}
                id="last_name"
                name="last_name"
                type="text"
                required
              />
              <Input
                icon={<MailIcon className="h-5 w-5 text-gray-400" />}
                label={t('common:email_address')}
                placeholder={t('common:email_address')}
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                error={error.email}
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                required
              />
              <Input
                icon={<ClockIcon className="h-5 w-5 text-gray-400" />}
                label={t('doctor:patients:send_invitation_reminder_in')}
                addOnRight={t('common:weeks')}
                value={weeks_until_reminder}
                onChange={(event) => setWeeksUntilReminder(event.target.value)}
                error={error.weeks_until_reminder}
                id="weeks_until_reminder"
                name="weeks_until_reminder"
                type="number"
                required
              />
            </div>
          </div>
        </div>
      </div>
      <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
        <Button
          type="submit"
          label={t('doctor:patients:invite_patient')}
          className="w-full sm:w-auto sm:ml-3"
          loading={loading}
          disabled={loading}
        />
        <Button
          type="button"
          label={t('common:close')}
          onClick={() => setOpen(false)}
          variant="outline"
          className="mt-3 w-full sm:w-auto sm:mt-0"
        />
      </div>
    </form>
  );
}
