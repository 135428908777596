import {
  PhoneIcon,
  MailIcon,
  IdentificationIcon,
  UserIcon,
  CalendarIcon,
  CakeIcon,
  LocationMarkerIcon,
} from '@heroicons/react/outline';
import { MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Patient } from '../../../common/consultation/types';
import { copyToClipboard } from '../../../common/utils/clipboard';
import { formatReadable, calculateAge } from '../../../common/utils/date';

interface Props {
  patient: Patient;
}

export function PatientProfilePersonalData(props: Props) {
  const { patient } = props;
  const { t } = useTranslation();

  function onCopyIdentificationNumberClick(event: MouseEvent<HTMLElement>): void {
    event.preventDefault();
    const trimmedIdentificationNumber = patient.patient_settings.identification_number.replace(/\D/g, '');
    copyToClipboard(trimmedIdentificationNumber);
  }

  return (
    <section>
      <div className="bg-white shadow sm:rounded-lg">
        <div className="px-4 py-5 sm:px-6">
          <h2 className="text-lg leading-6 font-medium text-gray-900">{t('doctor:patients:personal_data')}</h2>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">{t('doctor:patients:personal_patient_details')}</p>
        </div>
        <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
          <dl className="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-2">
            <div className="sm:col-span-1">
              <dt className="flex items-center text-sm font-medium text-gray-500">
                <MailIcon className="flex-shrink-0 mr-1 h-4 w-4 text-gray-400" />
                {t('common:email_address')}
              </dt>
              <dd className="mt-1 text-sm text-gray-900">
                {patient.email ? patient.email : t('doctor:patients:no_email_address')}
              </dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="flex items-center text-sm font-medium text-gray-500">
                <PhoneIcon className="flex-shrink-0 mr-1 h-4 w-4 text-gray-400" />
                {t('common:phone_number')}
              </dt>
              <dd className="mt-1 text-sm text-gray-900">
                {patient.patient_settings.phone_number
                  ? patient.patient_settings.phone_number
                  : t('doctor:patients:no_phone_number')}
              </dd>
            </div>
            <div className="sm:col-span-1">
              <Link to="/" onClick={onCopyIdentificationNumberClick}>
                <dt className="flex items-center text-sm font-medium text-gray-500">
                  <IdentificationIcon className="flex-shrink-0 mr-1 h-4 w-4 text-gray-400" />
                  {t('common:identification_number')}
                </dt>
                {patient.patient_settings.identification_number && (
                  <dd className="mt-1 text-sm text-gray-900">{patient.patient_settings.identification_number}</dd>
                )}
              </Link>
            </div>
            <div className="sm:col-span-1">
              <dt className="flex items-center text-sm font-medium text-gray-500">
                <UserIcon className="flex-shrink-0 mr-1 h-4 w-4 text-gray-400" />
                {t('common:gender')}
              </dt>
              {patient.patient_settings.gender && (
                <dd className="mt-1 text-sm text-gray-900">{t(`common:${patient.patient_settings.gender}`)}</dd>
              )}
            </div>
            <div className="sm:col-span-1">
              <dt className="flex items-center text-sm font-medium text-gray-500">
                <CalendarIcon className="flex-shrink-0 mr-1 h-4 w-4 text-gray-400" />
                {t('common:date_of_birth')}
              </dt>
              {patient.patient_settings.date_of_birth && (
                <dd className="mt-1 text-sm text-gray-900">{formatReadable(patient.patient_settings.date_of_birth)}</dd>
              )}
            </div>
            <div className="sm:col-span-1">
              <dt className="flex items-center text-sm font-medium text-gray-500">
                <CakeIcon className="flex-shrink-0 mr-1 h-4 w-4 text-gray-400" />
                {t('common:age')}
              </dt>
              {patient.patient_settings.date_of_birth && (
                <dd className="mt-1 text-sm text-gray-900">{calculateAge(patient.patient_settings.date_of_birth)}</dd>
              )}
            </div>
            <div className="sm:col-span-1">
              <dt className="flex items-center text-sm font-medium text-gray-500">
                <LocationMarkerIcon className="flex-shrink-0 mr-1 h-4 w-4 text-gray-400" />
                {t('common:postal_code')}
              </dt>
              {patient.patient_settings.postal_code && (
                <dd className="mt-1 text-sm text-gray-900">{patient.patient_settings.postal_code}</dd>
              )}
            </div>
          </dl>
        </div>
      </div>
    </section>
  );
}
