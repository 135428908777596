import type { RadioButtonGroupProps } from './RadioButtonGroup.types';

export function RadioButtonGroup(props: RadioButtonGroupProps) {
  const { children, label } = props;

  return (
    <>
      <label className="block text-sm font-medium leading-5 text-gray-700">{label}</label>
      <fieldset className="mt-1">
        <legend className="sr-only">{label}</legend>
        <div className="bg-white rounded-md -space-y-px">{children}</div>
      </fieldset>
    </>
  );
}
