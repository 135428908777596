import { ModalWrapperProps } from './ModalWrapper.types';
import { Modal } from '../modal/components';

export function ModalWrapper(props: ModalWrapperProps) {
  const { children, title, setVisibility, visible } = props;

  return (
    <Modal open={visible} setOpen={setVisibility}>
      <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
        <h2 className="text-xl font-medium text-gray-900">{title}</h2>
        <div className="mt-3">{children}</div>
      </div>
    </Modal>
  );
}
