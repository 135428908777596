import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom';

import DoctorShell from '../../shell/DoctorShell';
import {
  DefaultTemplateViewPage,
  TemplateCreatePage,
  TemplateEditPage,
  TemplateIndexPage,
  TemplateViewPage,
} from '../../template/pages';

export function TemplatesRouter() {
  const match = useRouteMatch();

  return (
    <DoctorShell>
      <Switch>
        <Route exact path={match.path} component={TemplateIndexPage} />
        <Route exact path={`${match.path}/create`} component={TemplateCreatePage} />
        <Route exact path={`${match.path}/doctor/:id/edit`} component={TemplateEditPage} />
        <Route exact path={`${match.path}/doctor/:id`} component={TemplateViewPage} />
        <Route exact path={`${match.path}/:id`} component={DefaultTemplateViewPage} />

        <Route path="*">
          <Redirect to={match.path} />
        </Route>
      </Switch>
    </DoctorShell>
  );
}
