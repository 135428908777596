import { parseISO, isToday, differenceInYears, format, compareAsc } from 'date-fns';

export function formatReadable(date: string): string {
  const dateFormat = isToday(parseISO(date)) ? 'kk:mm' : 'dd-MM-yyyy';

  return format(parseISO(date), dateFormat);
}

export function formatSecondsToHours(seconds: number): number {
  if (seconds === 0) {
    return 1;
  }

  return Math.ceil(seconds / (60 * 60));
}

export function formatSecondsToDays(seconds: number): number {
  if (seconds === 0) {
    return 1;
  }

  return Math.ceil(seconds / (60 * 60 * 24));
}

export function formatDay(date: string): string {
  return format(parseISO(date), 'yyyy-MM-dd');
}

export function calculateAge(date: string): number {
  return differenceInYears(new Date(), parseISO(date));
}

export function sortAsc(dateLeft: string, dateRight: string): number {
  return compareAsc(parseISO(dateLeft), parseISO(dateRight));
}

export interface TimeRemaining {
  total: number;
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}

export function getTimeRemaining(endTime: string): TimeRemaining {
  const total = Date.parse(endTime) - Date.parse(new Date().toString());
  const seconds = Math.floor((total / 1000) % 60);
  const minutes = Math.floor((total / 1000 / 60) % 60);
  const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
  const days = Math.floor(total / (1000 * 60 * 60 * 24));

  return {
    total,
    days,
    hours,
    minutes,
    seconds,
  };
}
