import { XIcon } from '@heroicons/react/outline';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ConsultationHistoryList } from './ConsultationHistoryList';
import { User } from '../../../common/authentication/types';
import { getPatientHistory } from '../../../common/consultation/api';
import { Button } from '../../../common/form/components';
import { EmptyState, LoadingState } from '../../../common/state/components';
import type { Consultation } from '../../graphql/types';

interface Props {
  user: User;
  consultation: Consultation;
  onCloseButtonClick: () => void;
}

export function History(props: Props) {
  const { user, consultation, onCloseButtonClick } = props;
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(true);
  const [consultations, setConsultations] = useState<Consultation[]>([]);

  useEffect(() => {
    async function collectHistory() {
      const consultations = await getPatientHistory(user, consultation);

      setConsultations(consultations);
      setLoading(false);
    }

    collectHistory();
  }, [user, consultation]);

  return (
    <div className="h-full flex flex-col bg-white shadow-xl overflow-y-scroll">
      <div className="flex-1">
        <header className="px-4 py-6 bg-teal-700 sm:px-6">
          <div className="flex items-start justify-between space-x-3">
            <div className="space-y-1">
              <h2 className="text-lg leading-7 font-medium text-white">{t('common:history')}</h2>
              <p className="text-sm text-teal-300 leading-5">{t('doctor:history_of_previous_consultations')}</p>
            </div>
            <div className="h-7 flex items-center">
              <button
                onClick={onCloseButtonClick}
                aria-label="Close panel"
                className="text-teal-200 hover:text-white transition ease-in-out duration-150"
              >
                <XIcon className="h-6 w-6" />
              </button>
            </div>
          </div>
        </header>

        <div className="flex-1 flex flex-col justify-between">
          <div className="px-4 divide-y divide-gray-200 sm:px-6">
            <div className="space-y-6 pt-6 pb-5">
              <div className="space-y-1">
                {loading ? (
                  <LoadingState message={t('common:collecting_consultations')} />
                ) : consultations.length ? (
                  <ConsultationHistoryList consultations={consultations} />
                ) : (
                  <EmptyState title={t('common:no_results')} message={t('common:no_consultation_history')} />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex-shrink-0 px-4 border-t border-gray-200 py-5 sm:px-6">
        <div className="space-x-3 flex justify-end">
          <span className="inline-flex rounded-md shadow-sm">
            <Button label={t('common:close')} type="button" variant="outline" onClick={onCloseButtonClick} />
          </span>
        </div>
      </div>
    </div>
  );
}
