import {
  MailIcon,
  PhoneIcon,
  IdentificationIcon,
  UserIcon,
  CalendarIcon,
  CheckCircleIcon,
  LocationMarkerIcon,
} from '@heroicons/react/outline';
import { SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { RelatedPatientRow } from './RelatedPatientRow';
import { copyToClipboard } from '../../../common/utils/clipboard';
import { calculateAge, formatReadable } from '../../../common/utils/date';
import { User } from '../../graphql/types';

interface Props {
  patient: User;
  showNotificationHandler: (flag: boolean) => void;
}

export function PatientsTableRow(props: Props) {
  const { patient, showNotificationHandler } = props;
  const { t } = useTranslation();
  const history = useHistory();

  const copyIdentificationNumber = () => {
    if (!patient.patient_settings.identification_number) {
      return;
    }

    const trimmedIdentificationNumber = patient.patient_settings.identification_number.replace(/\D/g, '');
    copyToClipboard(trimmedIdentificationNumber);
    showNotificationHandler(true);
  };

  const rowClickHandler = ({ target }: SyntheticEvent<HTMLElement>): void => {
    if (!(target instanceof HTMLElement)) {
      return;
    }

    if (target.dataset.copyClipboard === 'true') {
      copyIdentificationNumber();
    } else {
      history.push(`/doctor/patients/${patient.id}`);
    }
  };

  return (
    <>
      <tr onClick={rowClickHandler} className="cursor-pointer">
        <td className="px-6 py-4 whitespace-nowrap">
          <div className="flex items-center">
            <div className="flex-shrink-0 h-10 w-10">
              <img className="h-10 w-10 rounded-full" src={patient.avatar} alt={patient.first_name} />
            </div>
            <div className="ml-4">
              <div className="text-sm font-medium text-gray-900">
                {patient.first_name} {patient.last_name}
              </div>
              <div className="flex items-center text-sm text-gray-500">
                <MailIcon className="flex-shrink-0 mr-1 h-4 w-4 text-gray-400" />
                {patient.email ? patient.email : t('doctor:patients:no_email_address')}
              </div>
            </div>
          </div>
        </td>
        <td className="px-6 py-4 whitespace-nowrap">
          <div
            data-copy-clipboard={!!patient.patient_settings.identification_number}
            className="flex items-center text-sm text-gray-500 cursor-pointer"
          >
            <IdentificationIcon className="flex-shrink-0 mr-1 h-4 w-4 text-gray-400 pointer-events-none" />
            {patient.patient_settings.identification_number ? patient.patient_settings.identification_number : '-'}
          </div>

          <div className="flex items-center text-sm text-gray-500">
            <PhoneIcon className="flex-shrink-0 mr-1 h-4 w-4 text-gray-400" />
            {patient.patient_settings.phone_number
              ? patient.patient_settings.phone_number
              : t('doctor:patients:no_phone_number')}
          </div>
        </td>
        <td className="px-6 py-4 whitespace-nowrap">
          {patient.patient_settings.gender && (
            <div className="flex items-center text-sm text-gray-500">
              <UserIcon className="flex-shrink-0 mr-1 h-4 w-4 text-gray-400" />
              {t(`common:${patient.patient_settings.gender}`)}
            </div>
          )}
          {patient.patient_settings.date_of_birth && (
            <div className="flex items-center text-sm text-gray-500">
              <CalendarIcon className="flex-shrink-0 mr-1 h-4 w-4 text-gray-400" />
              {formatReadable(patient.patient_settings.date_of_birth)} (
              {calculateAge(patient.patient_settings.date_of_birth)})
            </div>
          )}
        </td>
        <td className="px-6 py-4 whitespace-nowrap">
          {patient.patient_settings.postal_code && (
            <div className="flex items-center text-sm text-gray-500">
              <LocationMarkerIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" />
              {patient.patient_settings.postal_code}
            </div>
          )}
          {patient.patient_settings.is_pregnant && (
            <div className="flex items-center text-sm text-gray-500">
              <CheckCircleIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" />
              {t('common:is_pregnant')}
            </div>
          )}
          {patient.patient_settings.birth_control && (
            <div className="flex items-center text-sm text-gray-500">
              <CheckCircleIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" />
              {t('common:is_taking_birth_control')}
            </div>
          )}
        </td>
      </tr>
      {patient.created_users &&
        patient.created_users.map((patient: User) => <RelatedPatientRow key={patient.id} patient={patient} />)}
    </>
  );
}
