import { UserIcon, PhoneIcon, IdentificationIcon, MailIcon, KeyIcon } from '@heroicons/react/outline';
import { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import type { SignUpFormProps } from './SignUpForm.types';
import type { ValidationError } from '../../../../common/api/types';
import { signUpDoctor } from '../../../../common/authentication/api';
import { Button, Input } from '../../../../common/form/components';
import { getPrivacyPolicyUrl, getTermsAndConditionsUrl } from '../../../../common/utils/legal';
import { UtmContext } from '../../../../common/utm/UtmContext';

export function SignUpForm(props: SignUpFormProps) {
  const { onSuccessfulSignUp, i18n } = props;
  const { t } = useTranslation();
  const { utm_source, utm_medium, utm_campaign, utm_term } = useContext(UtmContext);
  const [first_name, setFirstName] = useState<string>('');
  const [last_name, setLastName] = useState<string>('');
  const [phone_number, setPhoneNumber] = useState<string>('');
  const [license_number, setLicenseNumber] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [email_confirmation, setEmailConfirmation] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [terms_of_service, setTermsOfService] = useState<boolean>(false);
  const [privacy_policy, setPrivacyPolicy] = useState<boolean>(false);
  const [error, setError] = useState<ValidationError>({} as ValidationError);
  const [loading, setLoading] = useState<boolean>(false);

  async function onSignUpFormSubmit(event: React.FormEvent<HTMLElement>): Promise<void> {
    event.preventDefault();

    if (!loading) {
      setLoading(true);
      setError({} as ValidationError);

      try {
        const user = await signUpDoctor({
          first_name,
          last_name,
          license_number,
          phone_number,
          email,
          email_confirmation,
          password,
          language: i18n.language,
          terms_of_service,
          privacy_policy,
          utm_source,
          utm_medium,
          utm_campaign,
          utm_term,
        });

        setFirstName('');
        setLastName('');
        setLicenseNumber('');
        setPhoneNumber('');
        setEmail('');
        setEmailConfirmation('');
        setPassword('');
        setLoading(false);

        onSuccessfulSignUp(user);
      } catch (error) {
        setPassword('');
        setError(error.error);
        setLoading(false);
      }
    }
  }

  return (
    <form className="space-y-2" onSubmit={onSignUpFormSubmit}>
      <div className="space-y-4">
        <Input
          icon={<UserIcon className="h-5 w-5 text-gray-400" />}
          label={t('common:first_name')}
          placeholder={t('common:first_name')}
          value={first_name}
          onChange={(event) => setFirstName(event.target.value)}
          error={error.first_name}
          id="first_name"
          name="first_name"
          type="text"
          required
        />
        <Input
          icon={<UserIcon className="h-5 w-5 text-gray-400" />}
          label={t('common:last_name')}
          placeholder={t('common:last_name')}
          value={last_name}
          onChange={(event) => setLastName(event.target.value)}
          error={error.last_name}
          id="last_name"
          name="last_name"
          type="text"
          required
        />
        <Input
          icon={<IdentificationIcon className="h-5 w-5 text-gray-400" />}
          label={t('common:license_number')}
          placeholder={t('common:license_number')}
          value={license_number}
          onChange={(event) => setLicenseNumber(event.target.value)}
          error={error.license_number}
          id="license_number"
          name="license_number"
          type="text"
          required
        />
        <Input
          icon={<PhoneIcon className="h-5 w-5 text-gray-400" />}
          label={t('common:phone_number')}
          placeholder={t('common:phone_number')}
          value={phone_number}
          onChange={(event) => setPhoneNumber(event.target.value)}
          error={error.phone_number}
          id="phone_number"
          name="phone_number"
          type="text"
          required
        />
        <Input
          icon={<MailIcon className="h-5 w-5 text-gray-400" />}
          label={t('common:email_address')}
          placeholder={t('common:email_address')}
          value={email}
          onChange={(event) => setEmail(event.target.value)}
          error={error.email}
          id="email"
          name="email"
          type="email"
          autoComplete="email"
          required
        />
        <Input
          icon={<MailIcon className="h-5 w-5 text-gray-400" />}
          label={t('common:confirm_email_address')}
          placeholder={t('common:confirm_email_address')}
          value={email_confirmation}
          onChange={(event) => setEmailConfirmation(event.target.value)}
          error={error.email}
          id="email_confirmation"
          name="email_confirmation"
          type="email"
          required
        />
        <Input
          icon={<KeyIcon className="h-5 w-5 text-gray-400" />}
          label={t('common:password')}
          placeholder={t('common:password')}
          value={password}
          onChange={(event) => setPassword(event.target.value)}
          error={error.password}
          id="password"
          name="password"
          type="password"
          autoComplete="current-password"
          required
        />

        <div className="mt-4">
          <div className="flex items-center">
            <input
              id="terms_of_service"
              type="checkbox"
              required
              className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
              checked={terms_of_service}
              onChange={(event) => setTermsOfService(event.target.checked)}
            />
            <label htmlFor="terms_of_service" className="ml-2 block text-sm leading-5 text-gray-900">
              {t('authentication:agree_to_terms_of_service')}{' '}
              <a
                className="font-medium text-teal-600 hover:text-teal-500 focus:outline-none focus:underline transition ease-in-out duration-150"
                href={getTermsAndConditionsUrl(i18n.language)}
                target="_blank"
                rel="noreferrer"
              >
                {t('authentication:terms_of_service')}
              </a>
            </label>
          </div>
          {error.terms_of_service && <p className="mt-1 text-sm text-red-600">{error.terms_of_service}</p>}
        </div>

        <div className="mt-4">
          <div className="flex items-center">
            <input
              id="privacy_policy"
              type="checkbox"
              required
              className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
              checked={privacy_policy}
              onChange={(event) => setPrivacyPolicy(event.target.checked)}
            />
            <label htmlFor="privacy_policy" className="ml-2 block text-sm leading-5 text-gray-900">
              {t('authentication:agree_to_privacy_policy')}{' '}
              <a
                className="font-medium text-teal-600 hover:text-teal-500 focus:outline-none focus:underline transition ease-in-out duration-150"
                href={getPrivacyPolicyUrl(i18n.language)}
                target="_blank"
                rel="noreferrer"
              >
                {t('authentication:privacy_policy')}
              </a>
            </label>
          </div>
          {error.privacy_policy && <p className="mt-1 text-sm text-red-600">{error.privacy_policy}</p>}
        </div>
      </div>

      <div className="pt-8">
        <Button label={t('authentication:signup')} block type="submit" loading={loading} disabled={loading} />
      </div>
    </form>
  );
}
