import { ApolloProvider } from '@apollo/client';
import { BrowserRouter } from 'react-router-dom';

import { UserProvider } from './common/authentication/UserContext';
import { PermissionsProvider } from './common/context/PermissionContext/PermissionContext';
import Router from './common/router/components/Router';
import { UtmProvider } from './common/utm/UtmContext';
import { apolloClient } from './doctor/graphql/client';

function App() {
  return (
    <ApolloProvider client={apolloClient}>
      <UtmProvider>
        <UserProvider>
          <PermissionsProvider>
            <BrowserRouter>
              <Router />
            </BrowserRouter>
          </PermissionsProvider>
        </UserProvider>
      </UtmProvider>
    </ApolloProvider>
  );
}

export default App;
