import { useContext } from 'react';

import { UserContext } from '../../../common/authentication/UserContext';
import { User } from '../../../common/authentication/types';
import { CreateTemplateForm } from '../components';

export function TemplateCreatePage() {
  const { user } = useContext(UserContext);

  return (
    <div className="template-form-page w-full mx-auto p-4 sm:p-6 md:p-8">
      <CreateTemplateForm user={user as User} />
    </div>
  );
}
