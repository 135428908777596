import { useContext } from 'react';
import ReactGA from 'react-ga';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';

import {
  DoctorsRouter as AdminDoctorsRouter,
  TemplatesRouter as AdminTemplatesRouter,
} from '../../../../admin/router/components';
import { LogInPage, SignUpPage, ForgotPasswordPage, ResetPasswordPage } from '../../../../doctor/authentication/pages';
import { DashboardPage } from '../../../../doctor/dashboard/pages';
import { ConsultationsRouter, PatientsRouter, TemplatesRouter } from '../../../../doctor/router/components';
import { SettingsPage as DoctorSettingsPage } from '../../../../doctor/settings/pages';
import { UserContext } from '../../../authentication/UserContext';
import { getRedirectPathByRole } from '../../helpers';
import AdminRoute from '../AdminRoute';
import DoctorRoute from '../DoctorRoute';
import DoctorVerifiedAndAdminRoute from '../DoctorVerifiedAndAdminRoute';
import DoctorVerifiedRoute from '../DoctorVerifiedRoute';
import UnauthenticatedRoute from '../UnauthenticatedRoute';

export function Router() {
  const { user } = useContext(UserContext);
  const history = useHistory();

  if (process.env.REACT_APP_ENV === 'production') {
    ReactGA.initialize('UA-183863971-1');

    history.listen((location) => {
      ReactGA.pageview(location.pathname + location.search);
    });
  }

  return (
    <Switch>
      {/* Unauthenticated */}
      <UnauthenticatedRoute path="/doctor/login" component={LogInPage} />
      <UnauthenticatedRoute path="/doctor/signup" component={SignUpPage} />
      <UnauthenticatedRoute path="/forgot-password" component={ForgotPasswordPage} />
      <UnauthenticatedRoute path="/reset-password" component={ResetPasswordPage} />

      {/*/ Doctor */}
      <DoctorRoute path="/doctor/settings" component={DoctorSettingsPage} />

      {/* Verified Doctor */}
      <DoctorVerifiedRoute path="/doctor/templates" component={TemplatesRouter} />
      <DoctorVerifiedRoute path="/doctor/dashboard" component={DashboardPage} />
      <DoctorVerifiedRoute path="/doctor/patients" component={PatientsRouter} />

      {/* Verified Doctor & Admin */}
      <DoctorVerifiedAndAdminRoute path="/doctor/consultations" component={ConsultationsRouter} />

      {/* admin */}
      <AdminRoute path="/admin/doctors" component={AdminDoctorsRouter} />
      <AdminRoute path="/admin/templates" component={AdminTemplatesRouter} />
      <Route path="/admin">
        <Redirect to="/admin/doctors" />
      </Route>

      <Route path="*">
        <Redirect to={getRedirectPathByRole(user)} />
      </Route>
    </Switch>
  );
}
