import { MailIcon, KeyIcon } from '@heroicons/react/outline';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { logIn } from '../../../common/authentication/api';
import { User } from '../../../common/authentication/types';
import { Button, Input } from '../../../common/form/components';

interface Props {
  onSuccessfulLogIn: (user: User) => void;
}

export function LogInForm(props: Props) {
  const { onSuccessfulLogIn } = props;
  const { t } = useTranslation();
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  async function onLogInFormSubmit(event: React.FormEvent<HTMLElement>): Promise<void> {
    event.preventDefault();

    if (!loading) {
      setLoading(true);
      setError('');

      try {
        const user = await logIn({
          email,
          password,
        });

        setEmail('');
        setPassword('');
        setLoading(false);

        onSuccessfulLogIn(user);
      } catch (error) {
        setPassword('');
        setError(error.message);
        setLoading(false);
      }
    }
  }

  return (
    <form className="space-y-2" onSubmit={onLogInFormSubmit}>
      <div className="space-y-4">
        <Input
          icon={<MailIcon className="h-5 w-5 text-gray-400" />}
          label={t('common:email_address')}
          placeholder={t('common:email_address')}
          value={email}
          onChange={(event) => setEmail(event.target.value)}
          error={error}
          id="email"
          name="email"
          type="email"
          autoComplete="email"
          required
        />

        <Input
          icon={<KeyIcon className="h-5 w-5 text-gray-400" />}
          label={t('common:password')}
          placeholder={t('common:password')}
          value={password}
          onChange={(event) => setPassword(event.target.value)}
          error={error}
          id="password"
          name="password"
          type="password"
          autoComplete="current-password"
          required
        />
      </div>

      <div className="flex justify-end">
        <Link
          to="/forgot-password"
          className="text-sm font-normal text-teal-600 hover:text-teal-500 focus:outline-none focus:underline transition ease-in-out duration-150"
        >
          {t('authentication:forgot_password')}
        </Link>
      </div>

      <div className="pt-6">
        <Button label={t('authentication:login')} block type="submit" loading={loading} disabled={loading} />
      </div>
    </form>
  );
}
