import { ButtonHTMLAttributes, ReactNode } from 'react';

import { Spinner } from './Spinner';

type Variant = 'primary' | 'secondary' | 'outline' | 'tertiary';

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  label: string;
  loading?: boolean;
  block?: boolean;
  variant?: Variant;
  icon?: ReactNode;
}

export function Button(props: Props) {
  const { label, loading, block, variant = 'primary', className = '', disabled, icon, ...rest } = props;

  const defaultClasses = 'focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500';
  const disabledClasses = 'opacity-50 cursor-not-allowed';
  const inlineClasses = 'inline-flex';
  const blockClasses = 'w-full flex';
  const variantClasses = {
    primary: 'text-white bg-teal-600 hover:bg-teal-500 border-transparent',
    secondary: 'text-teal-800 bg-teal-200 hover:bg-teal-300 border-transparent',
    tertiary: 'text-white bg-pink-600 hover:bg-pink-500 focus:ring-transparent',
    outline: 'text-gray-700 bg-white hover:bg-gray-50 border-gray-300',
  };

  return (
    <button
      className={`${className} ${disabled ? disabledClasses : variant !== 'tertiary' ? defaultClasses : ''} ${
        block ? blockClasses : inlineClasses
      } ${
        variantClasses[variant]
      } justify-center items-center py-2 px-4 border rounded-md shadow-sm text-sm font-medium`}
      disabled={disabled}
      {...rest}
    >
      {loading && <Spinner className="-ml-1 mr-3" />}
      {icon && icon}
      {label}
    </button>
  );
}
