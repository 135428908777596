import { Listbox, Transition } from '@headlessui/react';
import { ClipboardListIcon } from '@heroicons/react/outline';
import debounce from 'lodash.debounce';
import mixpanel from 'mixpanel-browser';
import { useState, useEffect, useCallback, useRef, FormEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { ValidationError } from '../../../common/api/types';
import { User } from '../../../common/authentication/types';
import { updateConsultation } from '../../../common/consultation/api';
import { Input } from '../../../common/form/components';
import { searchICD10Codes } from '../../../common/icd10/api';
import { ICD10Code } from '../../../common/icd10/types';
import { useWindowClick } from '../../../common/utils/window-events';
import { Consultation } from '../../graphql/types';

interface Props {
  user: User;
  consultation: Consultation;
  onUpdateConsultation: (updatedConsultation: Consultation) => void;
  icd10CodeSuggestions: ICD10Code[];
  error: ValidationError;
  setError: (error: ValidationError) => void;
}

export function ActionHeaderICD10CodeSelector(props: Props) {
  const { user, consultation, onUpdateConsultation, icd10CodeSuggestions, error, setError } = props;
  const [icd10_code, setIcd10Code] = useState<string>('');
  const [icd10_codes, setIcd10Codes] = useState<ICD10Code[]>([]);
  const [icd10_code_suggestions, setIcd10CodeSuggestions] = useState<ICD10Code[]>([]);
  const [icd10AutoCompleteOpen, setIcd10AutoCompleteOpen] = useState<boolean>(false);
  const { t } = useTranslation();
  const icd10SelectorRef = useRef<HTMLDivElement>(null);
  const windowClickCallback = useCallback(
    (event: MouseEvent) => {
      if (icd10AutoCompleteOpen) {
        const icd10SelectorElement = icd10SelectorRef?.current;

        if (!icd10SelectorElement || icd10SelectorElement.contains(event.target as Node)) {
          return;
        }

        setTimeout(() => setIcd10AutoCompleteOpen(false), 100);
      }
    },
    [icd10AutoCompleteOpen]
  );
  useWindowClick(windowClickCallback);

  useEffect(() => {
    setError({} as ValidationError);
    setIcd10Code(consultation.icd10_code ?? '');
    setIcd10Codes([]);
  }, [consultation]);

  const debouncedICD10Search = useCallback(
    debounce(async (query) => {
      const icd10Codes = await searchICD10Codes(user, { query });
      mixpanel.track('doctor:consultation:search_icd10_codes', {
        searchValue: query,
      });
      setIcd10Codes(icd10Codes.icd10_codes);
      setIcd10CodeSuggestions(icd10Codes.icd10_code_suggestions);
      setIcd10AutoCompleteOpen(icd10Codes.icd10_codes.length !== 0 || icd10Codes.icd10_code_suggestions.length !== 0);
    }, 750),
    []
  );

  async function onSearchICD10(event: FormEvent<HTMLInputElement>): Promise<void> {
    const query = (event.target as HTMLInputElement).value;
    setIcd10Code(query);
    if (query.length > 2) {
      await debouncedICD10Search(query);
    } else {
      setIcd10Codes([]);
      setIcd10CodeSuggestions([]);
      setIcd10AutoCompleteOpen(false);
    }
  }

  async function onICD10CodeSelected(value: string): Promise<void> {
    setError({} as ValidationError);
    setIcd10Code(value);
    setIcd10AutoCompleteOpen(false);

    try {
      if (value.length) {
        const updatedConsultation = await updateConsultation(user, consultation, {
          icd10_code: value,
        });
        await onUpdateConsultation(updatedConsultation);
      }
    } catch (errors) {
      setError(errors.error);
    }
  }

  return (
    <div ref={icd10SelectorRef} className="text-sm leading-5 text-gray-900">
      <Input
        icon={<ClipboardListIcon className="h-5 w-5 text-gray-400" />}
        placeholder={t('doctor:icd10_code')}
        value={icd10_code}
        onChange={onSearchICD10}
        onFocus={() => {
          setTimeout(() => setIcd10AutoCompleteOpen(true), 100);
        }}
        error={error.icd10_code}
        id="icd10_code"
        name="icd10_code"
        type="text"
        autoComplete="off"
      />
      <Listbox as="div" className="space-y-1" value={icd10_code} onChange={onICD10CodeSelected}>
        {() => (
          <Transition
            show={icd10AutoCompleteOpen}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            className="absolute w-64 sm:w-96 mt-1 sm:-ml-40 rounded-md bg-white shadow-lg z-50"
          >
            <Listbox.Options
              static
              className="max-h-96 rounded-md text-base leading-6 ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm sm:leading-5 z-10"
            >
              {!!icd10CodeSuggestions.length && !icd10_code_suggestions.length && !icd10_codes.length && (
                <>
                  <div className="bg-gray-200 text-gray-900 cursor-default select-none relative py-2 pl-8 pr-4 border-b">
                    <span className="font-semibold block">{t('common:suggestions')}</span>
                  </div>
                  {icd10CodeSuggestions.map((code) => (
                    <Listbox.Option key={code.id} value={code.code}>
                      <div className="hover:text-white hover:bg-teal-500 text-gray-900 cursor-default select-none relative py-2 pl-8 pr-4 border-b">
                        <span className="font-semibold block">
                          {code.code} - {code.description}
                        </span>
                        {code.inclusion && (
                          <span>
                            {t('common:incl')}: {code.inclusion}
                          </span>
                        )}
                      </div>
                    </Listbox.Option>
                  ))}
                </>
              )}
              {!!icd10_code_suggestions.length && (
                <>
                  <div className="bg-gray-200 text-gray-900 cursor-default select-none relative py-2 pl-8 pr-4 border-b">
                    <span className="font-semibold block">{t('common:suggestions')}</span>
                  </div>
                  {icd10_code_suggestions.map((code) => (
                    <Listbox.Option key={code.id} value={code.code}>
                      <div className="hover:text-white hover:bg-teal-500 text-gray-900 cursor-default select-none relative py-2 pl-8 pr-4 border-b">
                        <span className="font-semibold block">
                          {code.code} - {code.description}
                        </span>
                        {code.inclusion && (
                          <span>
                            {t('common:incl')}: {code.inclusion}
                          </span>
                        )}
                      </div>
                    </Listbox.Option>
                  ))}
                </>
              )}
              {!!icd10_codes.length && (
                <>
                  <div className="bg-gray-200 text-gray-900 cursor-default select-none relative py-2 pl-8 pr-4 border-b">
                    <span className="font-semibold block">{t('common:search_results')}</span>
                  </div>
                  {icd10_codes.map((code) => (
                    <Listbox.Option key={code.id} value={code.code}>
                      <div className="hover:text-white hover:bg-teal-500 text-gray-900 cursor-default select-none relative py-2 pl-8 pr-4 border-b">
                        <span className="font-semibold block">
                          {code.code} - {code.description}
                        </span>
                        {code.inclusion && (
                          <span>
                            {t('common:incl')}: {code.inclusion}
                          </span>
                        )}
                      </div>
                    </Listbox.Option>
                  ))}
                </>
              )}
            </Listbox.Options>
          </Transition>
        )}
      </Listbox>
    </div>
  );
}
