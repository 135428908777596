interface Props {
  className: string;
}

export function DotIcon(props: Props) {
  const { className } = props;

  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 8 8">
      <circle cx="4" cy="4" r="3" />
    </svg>
  );
}
