import { Transition } from '@headlessui/react';
import { FilterIcon, SearchIcon } from '@heroicons/react/outline';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Filter, ConsultationStatus } from '../../../common/consultation/types';
import { Button, Select, Input } from '../../../common/form/components';

interface Props {
  filter: Filter;
  onFilterChange: (filter: Filter) => void;
}

export function ConsultationFilter(props: Props) {
  const { filter, onFilterChange } = props;
  const [filterIsOpen, setFilterIsOpen] = useState<boolean>(false);
  const [searchIsOpen, setSearchIsOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [statuses, setStatuses] = useState<ConsultationStatus[]>([]);
  const [sortBy, setSortBy] = useState<string>('activity');
  const [query, setQuery] = useState<string>('');
  const { t } = useTranslation();

  useEffect(() => {
    const keydownHandler = (event: KeyboardEvent) => {
      if (event.code === 'Escape') {
        setSearchIsOpen(false);
        setFilterIsOpen(false);
      }
    };
    document.addEventListener('keydown', keydownHandler);

    return () => document.removeEventListener('keydown', keydownHandler);
  }, []);

  useEffect(() => {
    setStatuses(filter.statuses);
    setSortBy(filter.sortBy);
    setQuery(filter.query);
    setLoading(false);
    setFilterIsOpen(false);
    setSearchIsOpen(false);
  }, [filter]);

  async function onFilterFormSubmit(event: React.FormEvent<HTMLElement>): Promise<void> {
    event.preventDefault();
    setLoading(true);

    onFilterChange({
      ...filter,
      statuses,
      sortBy,
      query,
    });
  }

  async function onClearSearchClick(event: React.MouseEvent<HTMLElement>): Promise<void> {
    event.preventDefault();
    setLoading(true);
    setQuery('');

    onFilterChange({
      ...filter,
      statuses,
      sortBy,
      query: '',
    });
  }

  function onStatusChange(checked: boolean, status: ConsultationStatus) {
    if (checked) {
      setStatuses([...statuses, status]);
    } else {
      const index = statuses.indexOf(status);
      setStatuses([...statuses.slice(0, index), ...statuses.slice(index + 1)]);
    }
  }

  return (
    <div className="relative inline-block text-left">
      <div className="flex">
        <button
          onClick={() => {
            if (filterIsOpen) {
              setFilterIsOpen(false);
            }
            setSearchIsOpen(!searchIsOpen);
          }}
          className="rounded-full flex items-center text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-teal-500"
          id="options-menu"
          aria-haspopup="true"
          aria-expanded={searchIsOpen}
        >
          <SearchIcon className="h-5 w-5" />
        </button>

        <button
          onClick={() => {
            if (searchIsOpen) {
              setSearchIsOpen(false);
            }
            setFilterIsOpen(!filterIsOpen);
          }}
          className="ml-2 rounded-full flex items-center text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-teal-500"
          id="options-menu"
          aria-haspopup="true"
          aria-expanded={filterIsOpen}
        >
          <FilterIcon className="h-5 w-5" />
        </button>
      </div>

      <Transition
        show={filterIsOpen}
        enter="transition ease-out duration-200"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
        className="z-50 origin-top-right absolute right-0 mt-2 w-64 sm:w-72 md:w-80 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5"
      >
        <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
          <div className="divide-y divide-gray-200 p-4 space-y-2">
            <h3 className="text-lg leading-5 font-medium text-gray-900">{t('common:filter')}</h3>
            <form onSubmit={onFilterFormSubmit} className="space-y-2">
              <div className="pt-4">
                <div role="group" aria-labelledby="label-email">
                  <div className="grid grid-cols-5 gap-4">
                    <div className="col-span-2">
                      <div className="text-base font-medium text-gray-900 sm:text-sm sm:text-gray-700" id="label-email">
                        {t('common:status')}
                      </div>
                    </div>
                    <div className="mt-2 sm:mt-0 col-span-3 flex flex-col items-end">
                      <div className="max-w-lg space-y-1">
                        <div className="relative flex items-start">
                          <div className="flex items-center h-5">
                            <input
                              id="new"
                              name="new"
                              type="checkbox"
                              className="focus:ring-teal-500 h-4 w-4 text-teal-600 border-gray-300 rounded"
                              onChange={(event) =>
                                onStatusChange(event.target.checked, event.target.name as ConsultationStatus)
                              }
                              checked={statuses.indexOf('new') !== -1}
                            />
                          </div>
                          <div className="ml-3 text-sm">
                            <label htmlFor="new" className="text-gray-700">
                              {t('common:new')}
                            </label>
                          </div>
                        </div>
                        <div>
                          <div className="relative flex items-start">
                            <div className="flex items-center h-5">
                              <input
                                id="in_progress"
                                name="in_progress"
                                type="checkbox"
                                className="focus:ring-teal-500 h-4 w-4 text-teal-600 border-gray-300 rounded"
                                onChange={(event) =>
                                  onStatusChange(event.target.checked, event.target.name as ConsultationStatus)
                                }
                                checked={statuses.indexOf('in_progress') !== -1}
                              />
                            </div>
                            <div className="ml-3 text-sm">
                              <label htmlFor="in_progress" className="text-gray-700">
                                {t('common:in_progress')}
                              </label>
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className="relative flex items-start">
                            <div className="flex items-center h-5">
                              <input
                                id="reminded"
                                name="reminded"
                                type="checkbox"
                                className="focus:ring-teal-500 h-4 w-4 text-teal-600 border-gray-300 rounded"
                                onChange={(event) =>
                                  onStatusChange(event.target.checked, event.target.name as ConsultationStatus)
                                }
                                checked={statuses.indexOf('reminded') !== -1}
                              />
                            </div>
                            <div className="ml-3 text-sm">
                              <label htmlFor="reminded" className="text-gray-700">
                                {t('common:reminded')}
                              </label>
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className="relative flex items-start">
                            <div className="flex items-center h-5">
                              <input
                                id="closed"
                                name="closed"
                                type="checkbox"
                                className="focus:ring-teal-500 h-4 w-4 text-teal-600 border-gray-300 rounded"
                                onChange={(event) =>
                                  onStatusChange(event.target.checked, event.target.name as ConsultationStatus)
                                }
                                checked={statuses.indexOf('closed') !== -1}
                              />
                            </div>
                            <div className="ml-3 text-sm">
                              <label htmlFor="closed" className="text-gray-700">
                                {t('common:closed')}
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="pt-4">
                <div role="group" aria-labelledby="label-email">
                  <div className="grid-cols-5 gap-4 flex items-center">
                    <div className="col-span-2">
                      <div className="text-base font-medium text-gray-900 sm:text-sm sm:text-gray-700" id="label-email">
                        {t('common:sort_by')}
                      </div>
                    </div>
                    <div className="mt-2 ml-auto sm:mt-0 col-span-3 flex flex-col items-end">
                      <div className="max-w-lg space-y-1">
                        <div className="relative flex items-start">
                          <div>
                            <Select
                              value={sortBy}
                              onChange={(event) => setSortBy(event.target.value)}
                              id="sort_by"
                              name="sort_by"
                            >
                              <option value="urgency">{t('common:urgency')}</option>
                              <option value="activity">{t('common:activity')}</option>
                              <option value="date">{t('common:date')}</option>
                            </Select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="pt-2 flex justify-end">
                <Button label={t('common:apply')} type="submit" loading={loading} disabled={loading} />
              </div>
            </form>
          </div>
        </div>
      </Transition>
      <Transition
        show={searchIsOpen}
        enter="transition ease-out duration-200"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
        className="z-50 origin-top-right absolute right-0 mt-2 w-64 sm:w-72 md:w-80 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5"
      >
        <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
          <div className="divide-y divide-gray-200 p-4 space-y-2">
            <h3 className="text-lg leading-5 font-medium text-gray-900">{t('common:search')}</h3>
            <form onSubmit={onFilterFormSubmit}>
              <div className="pt-4">
                <Input
                  icon={<SearchIcon className="h-5 w-5 text-gray-400" />}
                  placeholder={t('common:search')}
                  value={query}
                  onChange={(event) => setQuery(event.target.value)}
                  id="query"
                  name="query"
                  type="text"
                  autoFocus={searchIsOpen}
                  autoComplete="off"
                />
              </div>
              <div className="pt-4 flex justify-between">
                <Button
                  label={t('common:clear')}
                  variant="outline"
                  type="button"
                  disabled={!query || loading}
                  onClick={onClearSearchClick}
                />
                <Button label={t('common:search')} type="submit" loading={loading} disabled={!query || loading} />
              </div>
            </form>
          </div>
        </div>
      </Transition>
    </div>
  );
}
