import { Switch } from '@headlessui/react';
import { useEffect, useState } from 'react';

import { classNames } from '../../utils/style';

interface ToggleWithLabelProps {
  checked: boolean;
  label: string;
  onChangeCallback: (flag: boolean) => void;
}

export function ToggleWithLabel(props: ToggleWithLabelProps) {
  const { checked, label, onChangeCallback } = props;
  const [enabled, setEnabled] = useState(checked || false);

  useEffect(() => {
    setEnabled(checked || false);
  }, [checked]);

  function onChangeHandler(flag: boolean): void {
    setEnabled(flag);
    onChangeCallback(flag);
  }

  return (
    <Switch.Group as="div" className="flex items-center">
      <Switch
        checked={enabled}
        onChange={onChangeHandler}
        className={classNames(
          enabled ? 'bg-teal-600' : 'bg-gray-200',
          'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500'
        )}
      >
        <span
          aria-hidden="true"
          className={classNames(
            enabled ? 'translate-x-5' : 'translate-x-0',
            'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
          )}
        />
      </Switch>
      <Switch.Label as="span" className="ml-2">
        <span className="text-sm text-gray-500 cursor-pointer">{label}</span>
      </Switch.Label>
    </Switch.Group>
  );
}
