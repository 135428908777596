import { useContext } from 'react';
import { Redirect, Route } from 'react-router-dom';

import { UserContext } from '../../../authentication/UserContext';
import type { CustomRouteProps } from '../../types';

export function DoctorVerifiedRoute(props: CustomRouteProps) {
  const { component: Component, ...rest } = props;
  const { user } = useContext(UserContext);

  return (
    <Route
      {...rest}
      render={() =>
        user && ((user.is_doctor && user.is_verified_doctor) || user.is_medical_care) ? (
          <Component />
        ) : (
          <Redirect to="/doctor/login" />
        )
      }
    />
  );
}
