import { useTranslation } from 'react-i18next';

import { User } from '../../../common/authentication/types';
import { ToggleWithLabel, StatusTag } from '../../../common/consultation/components';
import { getConsultationIsFlagged } from '../../../common/consultation/helpers';
import { formatReadable } from '../../../common/utils/date';
import { Consultation } from '../../graphql/types';

interface Props {
  consultation: Consultation;
  toggleConsultationFlag: (flag: boolean) => void;
  user: User;
}

export function ActionHeaderConsultationInformation(props: Props) {
  const { user, consultation, toggleConsultationFlag } = props;
  const { t } = useTranslation();

  return (
    <div className="flex flex-col space-y-2">
      <div className="flex flex-row justify-between items-center">
        <dl className="flex space-x-4">
          <div>
            <dt className="text-sm leading-5 font-medium text-gray-500">{t('common:date')}</dt>
            <dd className="mt-1 text-sm leading-5 text-gray-500">{formatReadable(consultation.created_at)}</dd>
          </div>
          {consultation.user_has_write_access && (
            <div>
              <ToggleWithLabel
                checked={getConsultationIsFlagged(user, consultation)}
                label={t('doctor:consultation:mark_as_flagged')}
                onChangeCallback={toggleConsultationFlag}
              />
            </div>
          )}
        </dl>
      </div>
      <div className="flex flex-row justify-between items-center">
        <dl className="flex space-x-4">
          <div>
            <dt className="text-sm leading-5 font-medium text-gray-500">{t('common:type')}</dt>
            <dd className="mt-1 text-sm leading-5 text-gray-900">
              <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium leading-4 bg-teal-100 text-teal-800">
                {t(`common:${consultation.type}`)}
              </span>
              <span className="ml-1 inline-flex items-center px-2 py-0.5 rounded text-xs font-medium leading-4 bg-teal-100 text-teal-800 capitalize">
                {consultation.pricing_tier.title}
              </span>
            </dd>
          </div>
          <div>
            <dt className="text-sm leading-5 font-medium text-gray-500">{t('common:status')}</dt>
            <dd className="mt-1 text-sm leading-5">
              <StatusTag status={consultation.status} />
            </dd>
          </div>
        </dl>
      </div>
    </div>
  );
}
