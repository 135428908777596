import { gql } from '@apollo/client';

export const GET_USER_ROLES_PERMISSIONS = gql`
  query getUserPermissions {
    me {
      permissions {
        name
      }
      roles {
        name
        permissions {
          name
        }
      }
    }
  }
`;
