import { useTranslation } from 'react-i18next';

import { Spinner } from './Spinner';

interface Props {
  message: string;
}

export function LoadingState(props: Props) {
  const { message } = props;
  const { t } = useTranslation();

  return (
    <div className="bg-white overflow-hidden shadow rounded-md">
      <div className="px-4 py-5 sm:p-6 text-center">
        <Spinner className="h-12 w-12 mx-auto" />
        <h3 className="mt-4 text-lg leading-6 font-medium text-gray-900">{t('common:please_hold_on')}</h3>
        <div className="mt-2 text-sm leading-5 text-gray-500">
          <p>{message}</p>
        </div>
      </div>
    </div>
  );
}
