import { Consultation, Problem, CosmeticRequest } from '../../doctor/graphql/types';
import { User } from '../authentication/types';

export function getConsultationDescription(consultation: Consultation): string {
  if (consultation.consultable_type === 'App\\Models\\CosmeticRequest') {
    return (consultation.consultable as CosmeticRequest).expectations;
  } else if (
    consultation.type === 'follow_up' &&
    !consultation.show_problem_pictures &&
    consultation.latest_developments
  ) {
    return consultation.latest_developments;
  } else {
    return (consultation.consultable as Problem).description;
  }
}

export const getConsultationIsFlagged = (user: User, consultation: Consultation): boolean => {
  const userSettings = consultation.user_settings;
  if (!userSettings) {
    return false;
  }

  const userSettingForUser = userSettings.find((userSetting) => {
    return user.id.toString() === userSetting.id.toString();
  });

  return userSettingForUser?.pivot?.is_flagged ?? false;
};
