import {
  SignUpPatientInput,
  SignUpDoctorInput,
  LogInInput,
  ForgotPasswordInput,
  ResetPasswordInput,
  User,
  UserSourceInput,
} from './types';
import { ValidationError } from '../api/ValidationError';
import * as api from '../api/api';
import i18n from '../i18n';

export async function signUpPatient(signUpInput: SignUpPatientInput): Promise<User> {
  try {
    const { email, password } = signUpInput;

    await api.signUpPatient(signUpInput);

    return logIn({ email, password });
  } catch (error) {
    throw new ValidationError('Validation failed', error.response.data.errors);
  }
}

export async function signUpDoctor(signUpInput: SignUpDoctorInput): Promise<User> {
  try {
    const { email, password } = signUpInput;

    await api.signUpDoctor(signUpInput);

    return logIn({ email, password });
  } catch (error) {
    throw new ValidationError('Validation failed', error.response.data.errors);
  }
}

export async function logIn(logInInput: LogInInput): Promise<User> {
  try {
    const loginResponse = await api.logIn(logInInput);
    const userResponse = await api.getAuthenticatedUser(loginResponse.data.access_token);

    return {
      ...userResponse.data.data.user,
      access_token: loginResponse.data.access_token,
    };
  } catch (error) {
    if (error.response.data.error !== 'invalid_grant') {
      throw new Error(error.response.data.message ?? i18n.t('authentication:invalid_credentials'));
    }
    throw new Error(i18n.t('authentication:invalid_credentials'));
  }
}

export async function getAuthenticatedUser(user: User): Promise<User> {
  try {
    const userResponse = await api.getAuthenticatedUser(user.access_token);

    return {
      ...userResponse.data.data.user,
      access_token: user.access_token,
    };
  } catch (error) {
    if (error.response.data.error !== 'invalid_grant') {
      throw new Error(error.response.data.message ?? i18n.t('authentication:invalid_credentials'));
    }
    throw new Error(i18n.t('authentication:invalid_credentials'));
  }
}

export async function forgotPassword(forgotPasswordInput: ForgotPasswordInput): Promise<boolean> {
  try {
    await api.forgotPassword(forgotPasswordInput);

    return true;
  } catch (error) {
    throw new ValidationError('Validation failed', error.response.data.errors);
  }
}

export async function resetPassword(resetPasswordInput: ResetPasswordInput): Promise<boolean> {
  try {
    const resetPasswordReponse = await api.resetPassword(resetPasswordInput);

    return resetPasswordReponse.data.success;
  } catch (error) {
    throw new ValidationError('Validation failed', error.response.data.errors);
  }
}

export async function updateUserSource(user: User, userSourceInput: UserSourceInput): Promise<boolean> {
  try {
    const updateUserSourceResponse = await api.updateUserSource(user, userSourceInput);
    return updateUserSourceResponse.data.success;
  } catch (error) {
    throw new ValidationError('Validation failed', error.response.data.errors);
  }
}
