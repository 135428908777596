import { Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import { useState, useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  title: string;
  icon: React.ReactNode;
  isOpen: boolean;
  message?: string;
  onClose?: () => void;
}

export function Notification(props: Props) {
  const { title, message, icon, isOpen, onClose } = props;
  const { t } = useTranslation();

  const [is_open, setIsOpen] = useState<boolean>(false);

  useLayoutEffect(() => {
    setIsOpen(isOpen);

    if (isOpen) {
      const timer = setTimeout(() => {
        setIsOpen(false);
        onClose && onClose();
      }, 3000);

      return function () {
        clearTimeout(timer);
        onClose && onClose();
      };
    }
  }, [isOpen]);

  function onCloseButtonClick(): void {
    setIsOpen(false);
    onClose && onClose();
  }

  return (
    <Transition
      show={is_open}
      enter="transform ease-out duration-300 transition"
      enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
      enterTo="translate-y-0 opacity-100 sm:translate-x-0"
      leave="transition ease-in duration-100"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
      className="z-50 fixed inset-0 flex items-end justify-center px-4 py-6 pointer-events-none sm:p-6 sm:items-start sm:justify-end"
    >
      <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
        <div className="p-4">
          <div className="flex items-start">
            <div className="flex-shrink-0">{icon}</div>
            <div className="ml-3 w-0 flex-1 pt-0.5">
              <p className="text-sm font-medium text-gray-900">{title}</p>
              {message && <p className="mt-1 text-sm text-gray-500">{message}</p>}
            </div>
            <div className="ml-4 flex-shrink-0 flex">
              <button
                className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-light-blue-500"
                type="button"
                onClick={onCloseButtonClick}
              >
                <span className="sr-only">{t('common:close')}</span>
                <XIcon className="h-5 w-5" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  );
}
