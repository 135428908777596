import { ExclamationCircleIcon } from '@heroicons/react/outline';
import { TextareaHTMLAttributes, ReactNode } from 'react';

interface Props extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  label?: string;
  icon?: ReactNode;
  error?: string;
}

export function Textarea(props: Props) {
  const { label, icon, error, id, required, ...rest } = props;

  const defaultClasses = 'border-gray-300 placeholder-gray-400 focus:ring-teal-500 focus:border-teal-500';
  const errorClasses = 'border-red-300 placeholder-red-300 focus:ring-red-500 focus:border-red-500 text-red-900';
  const iconClasses = 'pl-10';

  return (
    <div>
      {label && (
        <label htmlFor={id} className="block text-sm font-medium text-gray-700 text-left">
          {label}
          {required && '*'}
        </label>
      )}
      <div className="mt-1 flex relative rounded-md shadow-sm">
        {icon && <div className="absolute inset-y-0 left-0 pt-3 sm:pt-2 pl-3 flex pointer-events-none">{icon}</div>}
        {error && (
          <div className="absolute inset-y-0 right-0 pt-3 sm:pt-2 pr-3 flex pointer-events-none">
            <ExclamationCircleIcon className="h-5 w-5 text-red-500" />
          </div>
        )}
        <textarea
          id={id}
          required={required}
          {...rest}
          className={`shadow-sm block w-full sm:text-sm rounded-md ${error ? errorClasses : defaultClasses} ${
            icon && iconClasses
          }`}
        />
      </div>
      {error && (
        <p className="mt-2 text-sm text-red-600" id={`${id}-error`}>
          {error}
        </p>
      )}
    </div>
  );
}
