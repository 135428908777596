import mixpanel from 'mixpanel-browser';
import { createContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { User } from './types';
import { disconnectWebSocketConnection, setupWebSocketConnection } from '../../services/websocket-client';

interface IUserContext {
  user: User | null;
  setAuthenticatedUser: (user: User) => void;
  unsetAuthenticatedUser: () => void;
}

interface Props {
  children: React.ReactNode;
}

export const UserContext = createContext<IUserContext>({} as IUserContext);

export function UserProvider(props: Props) {
  const { children } = props;
  const [user, setUser] = useState<User | null>(determineInitialUser());
  const { i18n } = useTranslation();

  function determineInitialUser() {
    const storedUserJson = localStorage.getItem('user');
    if (storedUserJson !== null) {
      const storedUser = JSON.parse(storedUserJson);
      identifyMixpanel(storedUser);
      identifyGoogleAnalytics(storedUser);
      setupWebSocketConnection(storedUser.access_token);
      return storedUser;
    }

    return null;
  }

  function setAuthenticatedUser(user: User) {
    localStorage.setItem('user', JSON.stringify(user));
    localStorage.setItem('i18nextLng', user.language ?? 'nl');
    i18n.changeLanguage(user.language ?? 'nl');
    identifyMixpanel(user);
    identifyGoogleAnalytics(user);
    setUser(user);
  }

  function unsetAuthenticatedUser() {
    disconnectWebSocketConnection();
    localStorage.removeItem('user');
    setUser(null);
  }

  function identifyMixpanel(user: User) {
    // It seems like register and people.set are separate things, so we'll perform both for now.
    mixpanel.identify(user.id.toString());
    mixpanel.register({
      Name: `${user.first_name} ${user.last_name}`,
      Email: `${user.email}`,
      Role: user.is_doctor ? 'doctor' : 'patient',
    });
    mixpanel.people.set({
      Name: `${user.first_name} ${user.last_name}`,
      Email: `${user.email}`,
      Role: user.is_doctor ? 'doctor' : 'patient',
    });

    // Send extra patient properties if available.
    if (user.patient_settings) {
      mixpanel.people.set({
        Country: user.patient_settings?.country ?? '',
        PostalCode: user.patient_settings?.postal_code ?? '',
        Gender: user.patient_settings?.gender ?? '',
      });
    }
  }

  function identifyGoogleAnalytics(user: User) {
    (window as any)?.dataLayer?.push({
      name: `${user.first_name} ${user.last_name}`,
      email: `${user.email}`,
      role: user.is_doctor ? 'doctor' : 'patient',
    });

    if (user.patient_settings) {
      (window as any)?.dataLayer?.push({
        country: user.patient_settings?.country ?? '',
        postal_code: user.patient_settings?.postal_code ?? '',
        gender: user.patient_settings?.gender ?? '',
        phone_number: user.patient_settings?.phone_number ?? '',
      });
    }
  }

  const value = {
    user,
    setAuthenticatedUser,
    unsetAuthenticatedUser,
  };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}
