import { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { UserContext } from '../../../common/authentication/UserContext';
import { User } from '../../../common/authentication/types';
import { getPatient } from '../../../common/consultation/api';
import { Patient } from '../../../common/consultation/types';
import { NotFoundState, LoadingState } from '../../../common/state/components';
import { PatientProfile } from '../components';

interface Params {
  id: string;
}

export function PatientProfilePage() {
  const { t } = useTranslation();
  const { id } = useParams<Params>();
  const { user } = useContext(UserContext);

  const [loading, setLoading] = useState<boolean>(true);
  const [not_found, setNotFound] = useState<boolean>(false);
  const [patient, setPatient] = useState<Patient | null>(null);

  useEffect(() => {
    async function collectPatient() {
      setNotFound(false);
      setLoading(true);

      try {
        const patient = await getPatient(user as User, parseInt(id, 10));

        setPatient(patient);
        setLoading(false);
      } catch (errors) {
        console.error(errors);
        setLoading(false);
        setNotFound(true);
      }
    }

    collectPatient();
  }, [user, id]);

  if (loading) {
    return (
      <div className="w-full mx-auto p-4 sm:p-6 md:p-8">
        <LoadingState message={t('doctor:patients:collecting_patient')} />
      </div>
    );
  } else if ((!loading && !patient) || not_found) {
    return (
      <div className="w-full mx-auto p-4 sm:p-6 md:p-8">
        <NotFoundState message={t('doctor:patients:this_is_not_a_patient_of_yours')} />
      </div>
    );
  }

  return <PatientProfile patient={patient as Patient} />;
}
