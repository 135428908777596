import { MailOpenIcon } from '@heroicons/react/outline';
import { MailIcon } from '@heroicons/react/solid';
import { MouseEvent, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import DeadlineTag from './DeadlineTag';
import PricingTierTag from './PricingTierTag';
import { UserContext } from '../../../common/authentication/UserContext';
import { User } from '../../../common/authentication/types';
import { StatusTag } from '../../../common/consultation/components';
import { getConsultationDescription, getConsultationIsFlagged } from '../../../common/consultation/helpers';
import { ConsultationReadStatus } from '../../../common/consultation/types';
import { formatReadable } from '../../../common/utils/date';
import { Consultation } from '../../graphql/types';

interface Props {
  consultation: Consultation;
  onSelectConsultation: (consultation: Consultation) => void;
  updateConsultationHandler: (consultation: Consultation, status: ConsultationReadStatus) => void;
}

export function ConsultationCard(props: Props) {
  const { user } = useContext(UserContext);
  const { consultation, onSelectConsultation, updateConsultationHandler } = props;
  const { t } = useTranslation();

  function onConsultationCardClick(event: MouseEvent<HTMLElement>): void {
    event.preventDefault();
    onSelectConsultation(consultation);
  }

  async function onMarkConsultationUnreadClick(event: MouseEvent<HTMLButtonElement>) {
    event.stopPropagation();
    const status = consultation.has_new_activity ? 'read' : 'unread';
    updateConsultationHandler(consultation, status);
  }

  if (!consultation.patient) {
    return null;
  }

  return (
    <div
      onClick={onConsultationCardClick}
      className="w-full flex items-center justify-between p-6 space-x-4 hover:bg-gray-50 cursor-pointer"
    >
      <div className="flex">
        <button className="mr-4 text-gray-500" onClick={onMarkConsultationUnreadClick}>
          {consultation.has_new_activity && <MailIcon className="w-6 h-6" />}
          {!consultation.has_new_activity && <MailOpenIcon className="w-6 h-6" />}
        </button>
        <span className="relative">
          <img
            className="w-12 h-12 bg-gray-300 rounded-full flex-shrink-0 object-cover"
            src={consultation.patient.avatar}
            alt={consultation.patient.first_name}
          />
          {consultation.has_new_activity && (
            <span className="absolute top-0 right-0 block h-3 w-3 rounded-full text-white shadow-solid bg-teal-600" />
          )}
        </span>
      </div>
      <div className="flex-1 truncate">
        <div className="flex items-center justify-between space-x-3">
          <h3
            className={`${
              consultation.has_new_activity ? 'font-bold' : 'font-medium'
            } text-gray-900 text-sm leading-5 truncate flex flex-col`}
          >
            <span>
              {consultation.patient.first_name} {consultation.patient.last_name}
            </span>
            {consultation.creator && (
              <span className="font-normal text-xs text-gray-400">
                {t('common:created_by')} {consultation.creator.first_name} {consultation.creator.last_name}
              </span>
            )}
          </h3>
          <span
            className={`${
              consultation.has_new_activity ? 'font-semibold' : 'font-normal'
            } flex-shrink-0 inline-block text-xs leading-5 text-gray-500 group-hover:text-gray-700 group-focus:underline transition ease-in-out duration-150`}
          >
            {formatReadable(consultation.created_at)}
          </span>
        </div>
        <div className="mt-1 flex items-center justify-between space-x-3">
          <p
            className={`${
              consultation.has_new_activity ? 'font-semibold' : 'font-normal'
            } text-gray-500 text-sm leading-5 truncate`}
          >
            {getConsultationDescription(consultation)}
          </p>
          <div className="flex items-center space-x-1">
            <PricingTierTag
              tier={consultation.pricing_tier}
              className={consultation.has_new_activity ? 'font-bold' : ''}
            />
            <StatusTag status={consultation.status} className={consultation.has_new_activity ? 'font-bold' : ''} />
            {getConsultationIsFlagged(user as User, consultation) && (
              <span className="inline-block w-3 h-3 ml-2 bg-red-700 rounded-full" />
            )}
          </div>
        </div>
        <div className="mt-2 flex justify-end space-x-3">
          {consultation.status === 'new' && (
            <DeadlineTag
              deadline={consultation.deadline_at}
              className={consultation.has_new_activity ? 'font-bold' : ''}
            />
          )}
        </div>
      </div>
    </div>
  );
}
