import { SearchInput, ICD10Code, ICD10CodeSearchResult } from './types';
import { ValidationError } from '../api/ValidationError';
import * as api from '../api/api';
import { User } from '../authentication/types';

export async function searchICD10Codes(user: User, searchInput: SearchInput): Promise<ICD10CodeSearchResult> {
  try {
    const icd10CodesReponse = await api.searchICD10Codes(user, searchInput);

    return icd10CodesReponse.data.data;
  } catch (error) {
    throw new ValidationError('Validation failed', error.response.data.errors);
  }
}

export async function getICD10CodeSuggestions(user: User): Promise<ICD10Code[]> {
  try {
    const icd10CodesReponse = await api.getICD10CodeSuggestions(user);

    return icd10CodesReponse.data.data.icd10_codes;
  } catch (error) {
    throw new ValidationError('Validation failed', error.response.data.errors);
  }
}
