import { gql } from '@apollo/client';

export const UPDATE_CONSULTATION_IS_FLAGGED = gql`
  mutation updateDoctorConsultationIsFlagged($consultationId: ID!, $isFlagged: Boolean!) {
    updateDoctorConsultationIsFlagged(consultation_id: $consultationId, isFlagged: $isFlagged) {
      id
      status
      icd10_code
      user_settings {
        id
        pivot {
          is_flagged
          is_read
        }
      }
    }
  }
`;

export const TOGGLE_ASSIGN_CONSULTATION = gql`
  mutation assignConsultation($consultationId: ID!, $userId: ID!) {
    assignConsultation(consultation_id: $consultationId, user_id: $userId) {
      id
      status
      icd10_code
      user_settings {
        id
        pivot {
          assigned
          is_flagged
          is_read
        }
      }
    }
  }
`;

export const TRANSFER_CONSULTATION = gql`
  mutation transferConsultation($consultationId: ID!, $doctorId: ID!) {
    transferConsultation(consultation_id: $consultationId, doctor_id: $doctorId) {
      doctor_id
      id
    }
  }
`;

export const TOGGLE_ROOKOO_FEATURE = gql`
  mutation toggleRookooFeature($consultationId: ID!) {
    toggleRookooFeature(consultation_id: $consultationId) {
      id
      rookoo {
        enabled
      }
    }
  }
`;
