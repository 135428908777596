import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom';

import { DoctorSettingsPage, DoctorsIndexPage } from '../../doctors/pages';
import { Shell } from '../../shell/components';

export function DoctorsRouter() {
  const match = useRouteMatch();

  return (
    <Shell>
      <Switch>
        <Route exact path={match.path} component={DoctorsIndexPage} />
        <Route exact path={`${match.path}/:id/settings`} component={DoctorSettingsPage} />

        <Route path="*">
          <Redirect to={match.url} />
        </Route>
      </Switch>
    </Shell>
  );
}
