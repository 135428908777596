import { MailIcon } from '@heroicons/react/outline';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ValidationError } from '../../../common/api/types';
import { forgotPassword } from '../../../common/authentication/api';
import { Button, Input } from '../../../common/form/components';

export function ForgotPasswordForm() {
  const { t } = useTranslation();
  const [email, setEmail] = useState<string>('');
  const [error, setError] = useState<ValidationError>({} as ValidationError);
  const [loading, setLoading] = useState<boolean>(false);
  const [link_sent, setLinkSent] = useState<boolean>(false);

  async function onForgotPasswordFormSubmit(event: React.FormEvent<HTMLElement>): Promise<void> {
    event.preventDefault();

    if (!loading) {
      setLoading(true);
      setError({} as ValidationError);

      try {
        await forgotPassword({ email });

        setEmail('');
        setLoading(false);
        setLinkSent(true);
      } catch (error) {
        setError(error.error);
        setLoading(false);
      }
    }
  }

  if (link_sent) {
    return (
      <div className="bg-gray-50 sm:rounded-lg">
        <div className="px-4 py-5 sm:p-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            {t('authentication:password_reset_link_sent')}
          </h3>
          <div className="mt-2 max-w-xl text-sm text-gray-500">
            <p>{t('authentication:follow_instructions_in_email')}</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <form className="space-y-2" onSubmit={onForgotPasswordFormSubmit}>
      <div className="space-y-4">
        <Input
          icon={<MailIcon className="h-5 w-5 text-gray-400" />}
          label={t('common:email_address')}
          placeholder={t('common:email_address')}
          value={email}
          onChange={(event) => setEmail(event.target.value)}
          error={error.email}
          id="email"
          name="email"
          type="email"
          autoComplete="email"
          required
        />
      </div>

      <div className="pt-6">
        <Button
          label={t('authentication:send_password_reset_link')}
          block
          type="submit"
          loading={loading}
          disabled={loading}
        />
      </div>
    </form>
  );
}
