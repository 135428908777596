import mixpanel from 'mixpanel-browser';
import React, { ChangeEvent, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { CloseConsultationModalProps } from './CloseConsultationModal.types';
import ModalWrapper from '../../../../common/ModalWrapper';
import { UserContext } from '../../../../common/authentication/UserContext';
import { closeConsultation } from '../../../../common/consultation/api';
import { Button } from '../../../../common/form/components';
import RadioButton from '../../../../common/form/components/RadioButton';
import { RadioButtonGroup } from '../../../../common/form/components/RadioButtonGroup/RadioButtonGroup';

const numberOfWeeksOptions = [1, 2, 4, 6, 8, 10, 12, 16, 24];

export function CloseConsultationModal(props: CloseConsultationModalProps) {
  const { closeCallback, consultation, errorCallback, visible, setVisibility } = props;
  const { user } = useContext(UserContext);
  const { t } = useTranslation();
  const [numberOfWeeks, setNumberOfWeeks] = useState<number>(numberOfWeeksOptions[0]);

  async function onCloseClickHandler() {
    try {
      const updatedConsultation = await closeConsultation(user!, consultation, {
        icd10_code: consultation.icd10_code ? consultation.icd10_code : undefined,
        weeks_until_follow_up: numberOfWeeks !== 0 ? numberOfWeeks : undefined,
      });

      if (numberOfWeeks) {
        mixpanel.track(`doctor:consultation:closed_consultation_with_follow_up_in_${numberOfWeeks}_weeks`);
      } else {
        mixpanel.track('doctor:consultation:closed_consultation');
      }

      closeCallback(updatedConsultation);
      setVisibility(false);
    } catch (errors) {
      errorCallback(errors.error);
      setVisibility(false);
    }
  }

  function onChangeHandler(event: ChangeEvent<HTMLInputElement>) {
    setNumberOfWeeks(parseInt(event.target.value, 10));
  }

  return (
    <ModalWrapper
      title={t('doctor:consultation:close_consultation_modal:title')}
      visible={visible}
      setVisibility={setVisibility}
    >
      <RadioButtonGroup label="">
        {numberOfWeeksOptions.map((weekNumber: number) => {
          const label =
            weekNumber > 12
              ? t('common:close_with_follow_up_in_month', { count: weekNumber / 4 })
              : t('common:close_with_follow_up_in_week', { count: weekNumber });
          const id = `close_with_follow_up_in_week${weekNumber}`;

          return (
            <RadioButton
              key={id}
              changeHandler={onChangeHandler}
              checked={numberOfWeeks === weekNumber}
              id={id}
              label={label}
              name="close_consultation"
              value={weekNumber.toString()}
            />
          );
        })}

        <RadioButton
          changeHandler={onChangeHandler}
          checked={!numberOfWeeks}
          id="close_without_follow_up"
          label={t('common:close_without_follow_up')}
          name="close_consultation"
          value="0"
        />
      </RadioButtonGroup>
      <div className="text-center">
        <Button variant="tertiary" onClick={onCloseClickHandler} label={t('common:close')} className="mt-3" />
      </div>
    </ModalWrapper>
  );
}
