import { useMutation } from '@apollo/client';
import React, { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { SkincareModalTypes } from './SkincareModal.type';
import ModalWrapper from '../../../../../common/ModalWrapper';
import { Button } from '../../../../../common/form/components';
import RadioButton from '../../../../../common/form/components/RadioButton';
import RadioButtonGroup from '../../../../../common/form/components/RadioButtonGroup';
import { CREATE_SKINCARE_PLAN } from '../../../../graphql/mutations/skincare';
import { SkincarePlan } from '../../../../graphql/types';
import { ConsultationDetailParams } from '../../ConsultationDetail/ConsultationDetail.types';

const SKINCARE_PLANS = [
  {
    label: 'Inflammatoire Acne',
    value: SkincarePlan.InflammatoireAcne,
  },
  {
    label: 'Acne General',
    value: SkincarePlan.AcneGeneral,
  },
  {
    label: 'Acne Comedonica',
    value: SkincarePlan.AcneComedonica,
  },
  {
    label: 'Acne Isotretinoine',
    value: SkincarePlan.AcneIsotretinoine,
  },
  {
    label: 'Rosacea',
    value: SkincarePlan.Rosacea,
  },
  {
    label: 'Post Acne',
    value: SkincarePlan.PostAcne,
  },
  {
    label: 'Grove Poriën',
    value: SkincarePlan.GrovePorien,
  },
  {
    label: 'Hyperpigmentatie',
    value: SkincarePlan.Hyperpigmentatie,
  },
  {
    label: 'Eczeem',
    value: SkincarePlan.Eczeem,
  },
  {
    label: 'Seborrhoisch Eczeem',
    value: SkincarePlan.SeborrhoischEczeem,
  },
  {
    label: 'Anti Aging',
    value: SkincarePlan.AntiAging,
  },
  {
    label: 'Peri-Orale Dermatitis (Acne)',
    value: SkincarePlan.PeriOraleDermatitisAcne,
  },
  {
    label: 'Peri-Orale Dermatitis (Eczeem)',
    value: SkincarePlan.PeriOraleDermatitisEczeem,
  },
  {
    label: 'Custom',
    value: SkincarePlan.Custom,
  },
];

export const SkincareModal = (props: SkincareModalTypes) => {
  const { t } = useTranslation();
  const { setVisibility, visible } = props;
  const { id: consultationId } = useParams<ConsultationDetailParams>();
  const [skincarePlanCreation, setSkincarePlanCreation] = useState(false);
  const [selectedSkincarePlan, setSelectedSkincarePlan] = useState<string>(SKINCARE_PLANS[0].value);
  const [createSkinCarePlan] = useMutation(CREATE_SKINCARE_PLAN);

  const createSkinCarePlanHandler = async (): Promise<void> => {
    setSkincarePlanCreation(true);
    await createSkinCarePlan({
      variables: {
        consultationId,
        skincarePlan: selectedSkincarePlan,
      },
    });
    openAirtable();
    setVisibility(false);
    reset();
  };

  const reset = (): void => {
    setSelectedSkincarePlan(SKINCARE_PLANS[0].value);
    setSkincarePlanCreation(false);
  };

  const radioButtonChangeHandler = (event: ChangeEvent<HTMLInputElement>): void => {
    setSelectedSkincarePlan(event.target.value);
  };

  const openAirtable = () => {
    window.open(process.env.REACT_APP_AIRTABLE_URL, '_blank', 'noreferrer');
  };

  return (
    <ModalWrapper
      title={t('doctor:consultation:skincare_plan:modal_title')}
      setVisibility={setVisibility}
      visible={visible}
    >
      <RadioButtonGroup label="">
        {SKINCARE_PLANS.map((skincarePlan, index: number) => {
          const id = `${index}_skincare_plan`;
          return (
            <RadioButton
              key={id}
              changeHandler={radioButtonChangeHandler}
              checked={selectedSkincarePlan === skincarePlan.value}
              id={id}
              label={skincarePlan.label}
              name="skincare_plan"
              value={skincarePlan.value}
            />
          );
        })}
      </RadioButtonGroup>
      <div className="text-center">
        <Button
          className="mt-3"
          loading={skincarePlanCreation}
          label={t('doctor:consultation:skincare_plan:modal_create_button')}
          onClick={async () => {
            await createSkinCarePlanHandler();
          }}
        />
      </div>
    </ModalWrapper>
  );
};
