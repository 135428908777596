import { useTranslation } from 'react-i18next';
import { useRouteMatch, Link } from 'react-router-dom';

import { User } from '../../../common/authentication/types';
import { deleteDefaultCannedResponse } from '../../../common/template/api';
import { Template } from '../../../common/template/types';
import { localeToLanguage } from '../../../common/utils/locale';

interface Props {
  user: User;
  templates: Template[];
  onSuccessfulDelete: (cannedResponse: Template) => void;
  actionsEnabled?: boolean;
}

export function TemplateList(props: Props) {
  const match = useRouteMatch();
  const { t } = useTranslation();
  const { user, templates, onSuccessfulDelete, actionsEnabled = false } = props;

  async function onDeleteLinkClick(cannedResponse: Template, event: React.MouseEvent<HTMLElement>) {
    event.preventDefault();
    await deleteDefaultCannedResponse(user, cannedResponse);
    onSuccessfulDelete(cannedResponse);
  }

  function renderCannedResponses() {
    if (!templates.length) {
      return (
        <tr>
          <td colSpan={2} className="px-6 py-4 whitespace-nowrap text-sm leading-5 text-gray-500 max-w-xl truncate">
            {t('common:you_have_no_templates_yet')}
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-right text-sm leading-5 font-medium">
            <Link to={`${match.path}/create`} className="text-teal-600 hover:text-teal-900">
              {t('common:create_template')}
            </Link>
          </td>
        </tr>
      );
    }

    return templates.map((template: Template) => {
      return (
        <tr key={template.id}>
          <td className="px-6 py-4 whitespace-nowrap text-sm leading-5 font-medium text-gray-900">{template.title}</td>
          <td className="px-6 py-4 whitespace-nowrap text-sm leading-5 text-gray-500 max-w-xl truncate">
            {localeToLanguage(template.language, t)}
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-sm leading-5 text-gray-500 max-w-xl truncate">
            {template.sanitized_template}
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-right text-sm leading-5 font-medium">
            <Link to={`${match.path}/${template.id}`} className="text-teal-600 hover:text-teal-900">
              {t('common:view')}
            </Link>
            {actionsEnabled && (
              <>
                <Link to={`${match.path}/${template.id}/edit`} className="ml-4 text-teal-600 hover:text-teal-900">
                  {t('common:edit')}
                </Link>
                <Link
                  to={`${match.path}/${template.id}/delete`}
                  className="ml-4 text-teal-600 hover:text-teal-900"
                  onClick={(event) => onDeleteLinkClick(template, event)}
                >
                  {t('common:delete')}
                </Link>
              </>
            )}
          </td>
        </tr>
      );
    });
  }

  return (
    <>
      <div className="flex items-center justify-between pb-5 border-b border-gray-200">
        <h3 className="flex text-lg leading-6 font-medium text-gray-900">{t('common:default_templates')}</h3>
        <span className="inline-flex rounded-md shadow-sm">
          {actionsEnabled && (
            <Link
              to={`${match.path}/create`}
              className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-teal-600 hover:bg-teal-500 focus:outline-none focus:border-teal-700 focus:ring-teal active:bg-teal-700 transition ease-in-out duration-150"
            >
              {t('common:create_template')}
            </Link>
          )}
        </span>
      </div>

      <div className="flex flex-col">
        <div className="mt-2 mb-6 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead>
                  <tr>
                    <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                      {t('common:name')}
                    </th>
                    <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                      {t('common:language')}
                    </th>
                    <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                      {t('common:template')}
                    </th>
                    <th className="px-6 py-3 bg-gray-50" />
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">{renderCannedResponses()}</tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
