import { InvitePatientForm } from './InvitePatientForm';
import { User } from '../../../common/authentication/types';
import { Modal } from '../../../common/modal/components';

interface Props {
  user: User;
  open: boolean;
  setOpen: (open: boolean) => void;
}

export function InvitePatientModal(props: Props) {
  const { user, open, setOpen } = props;

  return (
    <Modal open={open} setOpen={setOpen}>
      <InvitePatientForm user={user} setOpen={setOpen} />
    </Modal>
  );
}
