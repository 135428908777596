import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { PatientsTableBody } from './PatientsTableBody';
import { PatientsTableHead } from './PatientsTableHead';
import InfiniteScroll from '../../../common/InfiniteScrollList';
import { SuccessNotification } from '../../../common/notification/components';
import { User } from '../../graphql/types';

interface Props {
  patients: User[];
  loadMoreCallback: () => Promise<boolean>;
}

export function PatientsTable(props: Props) {
  const { patients, loadMoreCallback } = props;
  const { t } = useTranslation();
  const [copyNotificationOpen, setCopyNotificationOpen] = useState<boolean>(false);

  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <SuccessNotification
              title={t('common:copied_to_clipboard')}
              isOpen={copyNotificationOpen}
              onClose={() => setCopyNotificationOpen(false)}
            />
            <InfiniteScroll data={patients} loadMoreCallback={loadMoreCallback}>
              <table className="min-w-full divide-y divide-gray-200">
                <PatientsTableHead />
                <PatientsTableBody patients={patients} showNotificationHandler={setCopyNotificationOpen} />
              </table>
            </InfiniteScroll>
          </div>
        </div>
      </div>
    </div>
  );
}
