import { ExclamationIcon } from '@heroicons/react/outline';
import { useTranslation } from 'react-i18next';

interface Props {
  message: string | React.ReactNode;
  title?: string;
  icon?: React.ReactNode;
}

export function NotFoundState(props: Props) {
  const { message, title, icon } = props;
  const { t } = useTranslation();

  return (
    <div className="bg-white overflow-hidden shadow rounded-md">
      <div className="px-4 py-5 sm:p-6 text-center">
        {icon ? <>{icon}</> : <ExclamationIcon className="h-12 w-12 mx-auto text-orange-400" />}
        <h3 className="mt-4 text-lg leading-6 font-medium text-gray-900">
          {title ? <>{title}</> : <>{t('common:whuups')}</>}
        </h3>
        <div className="mt-2 text-sm leading-5 text-gray-500">
          <p>{message}</p>
        </div>
      </div>
    </div>
  );
}
