import { gql } from '@apollo/client';

export const DRAFT_MESSAGE_FRAGMENT = gql`
  fragment DraftMessage on DraftMessage {
    id
    content
  }
`;

export const UPSERT_DRAFT_MESSAGE = gql`
  ${DRAFT_MESSAGE_FRAGMENT}
  mutation upsertDraftMessage($id: ID, $userId: ID!, $consultationId: ID!, $content: String!) {
    upsertDraftMessage(id: $id, user_id: $userId, consultation_id: $consultationId, content: $content) {
      ...DraftMessage
    }
  }
`;

export const DELETE_DRAFT_MESSAGE = gql`
  ${DRAFT_MESSAGE_FRAGMENT}
  mutation deleteDraftMessage($id: ID!) {
    deleteDraftMessage(id: $id) {
      ...DraftMessage
    }
  }
`;
