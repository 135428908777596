import type { TemplateSearchItemsProps } from './TemplateSearchItems.types';
import type { CannedResponse } from '../../../../../common/template/types';
import TemplateSearchItem from '../TemplateSearchItem';

export function TemplateSearchItems(props: TemplateSearchItemsProps) {
  const { title, items } = props;

  return (
    <>
      <div className="bg-gray-200 text-gray-900 cursor-default select-none relative py-2 pl-8 pr-4 border-b">
        <span className="font-semibold block">{title}</span>
      </div>
      {items.map((cannedResponse: CannedResponse, index: number) => (
        <TemplateSearchItem key={`canned-response-${cannedResponse.id}-${index}`} item={cannedResponse} />
      ))}
    </>
  );
}
