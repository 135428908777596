import { ClipboardListIcon, CheckCircleIcon } from '@heroicons/react/outline';
import { useTranslation } from 'react-i18next';

import { Patient } from '../../../common/consultation/types';

interface Props {
  patient: Patient;
}

export function PatientProfileExtraInformation(props: Props) {
  const { patient } = props;
  const { t } = useTranslation();

  return (
    <section>
      <div className="bg-white shadow sm:rounded-lg">
        <div className="px-4 py-5 sm:px-6">
          <h2 className="text-lg leading-6 font-medium text-gray-900">{t('doctor:patients:extra_information')}</h2>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">{t('doctor:patients:extra_patient_details')}</p>
        </div>
        <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
          <dl className="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-2">
            <div className="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-2 sm:col-span-2">
              <div className="sm:col-span-1">
                <dt className="flex items-center text-sm font-medium text-gray-500">
                  <ClipboardListIcon className="flex-shrink-0 mr-1 h-4 w-4 text-gray-400" />
                  {t('doctor:allergies')}
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {patient.patient_settings.allergies ? patient.patient_settings.allergies : '-'}
                </dd>
              </div>
            </div>
            <div className="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-2 sm:col-span-2">
              <div className="sm:col-span-1">
                <dt className="flex items-center text-sm font-medium text-gray-500">
                  <CheckCircleIcon className="flex-shrink-0 mr-1 h-4 w-4 text-gray-400" />
                  {t('common:is_pregnant')}?
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {patient.patient_settings.is_pregnant ? t('common:yes') : t('common:no')}
                </dd>
              </div>
              <div className="sm:col-span-1">
                <dt className="flex items-center text-sm font-medium text-gray-500">
                  <CheckCircleIcon className="flex-shrink-0 mr-1 h-4 w-4 text-gray-400" />
                  {t('common:is_taking_birth_control')}?
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {patient.patient_settings.birth_control ? t('common:yes') : t('common:no')}
                </dd>
              </div>
            </div>
          </dl>
        </div>
      </div>
    </section>
  );
}
