import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SkincareToggleButtonTypes } from './SkincareToggleButton.types';
import { Button } from '../../../../../common/form/components';
import SkincareModal from '../Modal';

export function SkincareToggleButton(props: SkincareToggleButtonTypes) {
  const { disabled } = props;
  const { t } = useTranslation();
  const [visible, setVisibility] = useState<boolean>(false);

  return (
    <>
      <Button
        type="button"
        disabled={disabled}
        variant="secondary"
        label={t('doctor:consultation:skincare_plan:modal_toggle_button')}
        onClick={() => {
          setVisibility(true);
        }}
      />
      <SkincareModal visible={visible} setVisibility={setVisibility} />
    </>
  );
}
