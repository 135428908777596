import { ClipboardListIcon } from '@heroicons/react/outline';

import type { CheckboxGroupProps } from './CheckboxGroup.types';
import { Input } from '../Input';

export function CheckboxGroup(props: CheckboxGroupProps) {
  const {
    children,
    columns,
    error,
    label,
    showOtherChangeHandler,
    showOtherInput,
    showOtherName,
    showOtherPlaceholder,
    showOtherValue,
  } = props;

  return (
    <fieldset>
      <legend className="text-sm leading-5 font-medium text-gray-700">{label}</legend>
      {/* TODO: check why utility doesn't work, on mobile we don't need 2 cols */}
      <div style={{ columns: columns || 2 }}>{children}</div>
      {showOtherInput && (
        <div className="mt-2">
          <Input
            icon={<ClipboardListIcon className="h-5 w-5 text-gray-400" />}
            placeholder={showOtherPlaceholder}
            value={showOtherValue}
            onChange={showOtherChangeHandler}
            id={`other_${showOtherName}`}
            name={`other_${showOtherName}`}
            type="text"
            required
          />
        </div>
      )}
      {error && <p className="mt-2 text-sm text-red-600">{error}</p>}
    </fieldset>
  );
}
