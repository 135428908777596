import { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { UserContext } from '../../../common/authentication/UserContext';
import { User } from '../../../common/authentication/types';
import { getAllDoctors } from '../../../common/consultation/api';
import { Doctor } from '../../../common/consultation/types';
import { LoadingState } from '../../../common/state/components';
import { DoctorsList } from '../components';

export function DoctorsIndexPage() {
  const { t } = useTranslation();
  const { user } = useContext(UserContext);
  const [loading, setLoading] = useState<boolean>(true);
  const [doctors, setDoctors] = useState<Doctor[]>([]);

  useEffect(() => {
    async function collectDoctors() {
      try {
        const doctors = await getAllDoctors(user as User);

        setDoctors(doctors);
        setLoading(false);
      } catch (errors) {
        console.error(errors);
        setLoading(false);
      }
    }

    collectDoctors();
  }, []);

  return (
    <div className="w-full mx-auto p-4 sm:p-6 md:p-8">
      {loading ? <LoadingState message={t('common:collecting_doctors')} /> : <DoctorsList doctors={doctors} />}
    </div>
  );
}
