import { useState } from 'react';

import { Main } from './Main';
import { Sidebar } from './Sidebar';
import { Topbar } from './Topbar';

interface Props {
  children: React.ReactNode;
}

export function Shell(props: Props) {
  const { children } = props;
  const [mobileSidebarOpen, setMobileSidebarOpen] = useState<boolean>(false);
  const [profileDropdownOpen, setProfileDropdownOpen] = useState<boolean>(false);

  function onOpenSidebarButtonClick(): void {
    setMobileSidebarOpen(true);
  }

  function onProfileDropdownButtonClick(event: React.MouseEvent<HTMLElement>): void {
    event.stopPropagation();
    setProfileDropdownOpen(!profileDropdownOpen);
  }

  return (
    <div onClick={() => setProfileDropdownOpen(false)} className="h-screen flex overflow-hidden bg-gray-100">
      <Sidebar mobileSidebarOpen={mobileSidebarOpen} setMobileSidebarOpen={setMobileSidebarOpen} />

      <div className="flex flex-col w-0 flex-1 overflow-hidden">
        <Topbar
          profileDropdownOpen={profileDropdownOpen}
          onOpenSidebarButtonClick={onOpenSidebarButtonClick}
          onProfileDropdownButtonClick={onProfileDropdownButtonClick}
        />

        <Main>{children}</Main>
      </div>
    </div>
  );
}
