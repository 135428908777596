import { Quill } from 'react-quill';

import { PersonalizedTagObj } from '../Toolbar/Toolbar.types';

const Embed = Quill.import('blots/embed');

class PersonalizedTag extends Embed {
  static create(data: PersonalizedTagObj) {
    const { key, label } = data;
    const node = super.create(label);

    node.setAttribute('data-key', key);
    node.setAttribute('data-label', label);

    node.innerHTML = key === 'skincare_plan' ? `Skincare Plan: ${label}` : label;
    node.addEventListener('click', () => {
      node.remove();
    });

    return node;
  }

  static value(domNode: HTMLSpanElement) {
    const { key, label } = domNode.dataset;

    return {
      key,
      label,
    };
  }
}

PersonalizedTag.blotName = 'personalizedTag';
PersonalizedTag.tagName = 'span';
PersonalizedTag.className = 'personalized-tag';

export default PersonalizedTag;
