import type { ImageMessageProps } from './ImageMessage.types';
import ConversationWrapper from '../ConversationWrapper';

export function ImageMessage(props: ImageMessageProps) {
  const { imageUrl, isUser, message, readonly } = props;

  return (
    <ConversationWrapper isUser={isUser} item={message} readonly={readonly}>
      <img loading="lazy" className="rounded-lg w-1/2 lg:w-1/3" src={imageUrl} alt="" />
    </ConversationWrapper>
  );
}
