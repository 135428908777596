import { useMutation, useQuery } from '@apollo/client';
import React, { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { TransferConsultationModalProps } from './TransferConsultationModal.types';
import ModalWrapper from '../../../../../common/ModalWrapper';
import { Button } from '../../../../../common/form/components';
import RadioButton from '../../../../../common/form/components/RadioButton';
import RadioButtonGroup from '../../../../../common/form/components/RadioButtonGroup';
import { TRANSFER_CONSULTATION } from '../../../../graphql/mutations/consultation';
import { ACTIVE_DOCTORS } from '../../../../graphql/queries/consultations';
import { User } from '../../../../graphql/types';
import { ConsultationDetailParams } from '../../ConsultationDetail/ConsultationDetail.types';

export const TransferConsultationModal = (props: TransferConsultationModalProps) => {
  const { consultationDoctorId, consultationUpdateCallback, setVisibility, visible } = props;
  const { t } = useTranslation();
  const { id: consultationId } = useParams<ConsultationDetailParams>();
  const { loading, data } = useQuery(ACTIVE_DOCTORS, { fetchPolicy: 'cache-and-network' });
  const [transferConsultation] = useMutation(TRANSFER_CONSULTATION);
  const [selectedDoctor, setSelectedDoctor] = useState<string>('');

  const onChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedDoctor(event.target.value);
  };

  const onTransferConsultationClickHandler = async () => {
    const response = await transferConsultation({
      variables: { consultationId, doctorId: selectedDoctor },
    });
    consultationUpdateCallback(response.data.transferConsultation);
  };

  return (
    <ModalWrapper
      title={t('doctor:consultation:transfer_consultation_modal:title')}
      setVisibility={setVisibility}
      visible={visible}
    >
      {loading && <p>loading…</p>}
      {data?.activeDoctors && (
        <RadioButtonGroup label="">
          {data.activeDoctors.map((doctor: User) => {
            if (doctor.id === consultationDoctorId) {
              return null;
            }

            return (
              <RadioButton
                key={doctor.id}
                id={doctor.id}
                name="selected_active_doctor"
                label={`${doctor.first_name} ${doctor.last_name}`}
                value={doctor.id}
                checked={doctor.id === selectedDoctor}
                changeHandler={onChangeHandler}
              />
            );
          })}
        </RadioButtonGroup>
      )}

      <div className="text-center">
        <Button
          disabled={!selectedDoctor}
          variant="tertiary"
          onClick={onTransferConsultationClickHandler}
          label={t('doctor:consultation:transfer_consultation_modal:button')}
          className="mt-3"
        />
      </div>
    </ModalWrapper>
  );
};
