import { XIcon } from '@heroicons/react/outline';

interface Props {
  prescriptions: File[];
  onRemovePrescription: (index: number) => void;
}

export function FileList(props: Props) {
  const { prescriptions, onRemovePrescription } = props;

  function renderPrescriptions() {
    return prescriptions.map((prescription: File, index: number) => {
      return (
        <span
          key={index}
          className="mr-2 inline-flex rounded-md items-center py-0.5 pl-2.5 pr-1 text-sm font-medium bg-teal-100 text-teal-700"
        >
          <span className="w-24 md:w-32 lg:w-full lg:max-w-2xl truncate">{prescription.name}</span>
          <button
            type="button"
            onClick={(event) => onRemovePrescriptionClick(event, index)}
            className="flex-shrink-0 ml-0.5 h-4 w-4 rounded-full inline-flex items-center justify-center text-teal-400 hover:bg-teal-200 hover:text-teal-500 focus:outline-none focus:bg-teal-500 focus:text-white"
          >
            <XIcon className="h-2 w-2" />
          </button>
        </span>
      );
    });
  }

  function onRemovePrescriptionClick(event: React.MouseEvent<HTMLElement>, index: number): void {
    event.preventDefault();
    onRemovePrescription(index);
  }

  if (!prescriptions.length) {
    return null;
  }

  return <div className="mt-2 space-y-2">{renderPrescriptions()}</div>;
}
