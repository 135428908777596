import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom';

import { PatientsPage, PatientProfilePage } from '../../patients/pages';
import DoctorShell from '../../shell/DoctorShell';

export function PatientsRouter() {
  const match = useRouteMatch();

  return (
    <DoctorShell>
      <Switch>
        <Route exact path={match.path} component={PatientsPage} />
        <Route exact path={`${match.path}/:id(\\d+)`} component={PatientProfilePage} />

        <Route path="*">
          <Redirect to={match.url} />
        </Route>
      </Switch>
    </DoctorShell>
  );
}
