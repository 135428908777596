import { CheckCircleIcon, ChevronRightIcon, MailIcon, ExclamationCircleIcon } from '@heroicons/react/solid';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Doctor } from '../../../common/consultation/types';
import { formatDay } from '../../../common/utils/date';

interface Props {
  doctors: Doctor[];
}

export function DoctorsList(props: Props) {
  const { t } = useTranslation();
  const { doctors } = props;

  return (
    <div className="bg-white overflow-hidden shadow sm:rounded-lg">
      <ul className="divide-y divide-gray-200">
        {doctors.map((doctor: Doctor) => (
          <li key={doctor.id}>
            <Link to={`/admin/doctors/${doctor.id}/settings`} className="block hover:bg-gray-50">
              <div className="flex items-center px-4 py-4 sm:px-6">
                <div className="min-w-0 flex-1 flex items-center">
                  <div className="flex-shrink-0">
                    <img className="h-12 w-12 rounded-full" src={doctor.avatar} alt={doctor.first_name} />
                  </div>
                  <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                    <div>
                      <p className="text-sm font-medium text-indigo-600 truncate">
                        {doctor.first_name} {doctor.last_name}
                      </p>
                      <p className="mt-2 flex items-center text-sm text-gray-500">
                        <MailIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                        <span className="truncate">{doctor.email}</span>
                      </p>
                    </div>
                    <div className="hidden md:block">
                      <div>
                        <p className="text-sm text-gray-900">
                          {t('common:created_at')} {formatDay(doctor.created_at)}
                        </p>
                        <p className="mt-2 flex items-center text-sm text-gray-500">
                          {doctor.is_verified_doctor ? (
                            <>
                              <CheckCircleIcon
                                className="flex-shrink-0 mr-1.5 h-5 w-5 text-green-400"
                                aria-hidden="true"
                              />
                              {t('common:verified_at')} {formatDay(doctor.doctor_settings.verified_at)}
                            </>
                          ) : (
                            <>
                              <ExclamationCircleIcon
                                className="flex-shrink-0 mr-1.5 h-5 w-5 text-red-400"
                                aria-hidden="true"
                              />
                              {t('common:unverified')}
                            </>
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <ChevronRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </div>
              </div>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
}
