import React, { useState } from 'react';

import { DoctorShellProps } from './DoctorShell.types';
import { Main } from '../components/Main';
import MobileHeader from '../components/MobileHeader';
import { Sidebar } from '../components/Sidebar';

export const DoctorShell = (props: DoctorShellProps) => {
  const { children } = props;
  const [mobileSidebarOpen, setMobileSidebarOpen] = useState<boolean>(false);
  const [profileDropdownOpen, setProfileDropdownOpen] = useState<boolean>(false);

  const onProfileDropdownButtonClick = (event: React.MouseEvent<HTMLElement>): void => {
    event.stopPropagation();
    setProfileDropdownOpen(!profileDropdownOpen);
  };

  return (
    <div onClick={() => setProfileDropdownOpen(false)} className="h-screen flex overflow-hidden bg-gray-100">
      <Sidebar
        mobileSidebarOpen={mobileSidebarOpen}
        setMobileSidebarOpen={setMobileSidebarOpen}
        profileDropdownOpen={profileDropdownOpen}
        onProfileDropdownButtonClick={onProfileDropdownButtonClick}
      />

      <div className="flex flex-col w-0 flex-1 overflow-hidden">
        <MobileHeader
          callback={() => {
            setMobileSidebarOpen(true);
          }}
        />
        <Main>{children}</Main>
      </div>
    </div>
  );
};
