import { ClockIcon } from '@heroicons/react/outline';
import { useTranslation } from 'react-i18next';

import type { DeadlineTagProps } from './DeadlineTag.types';
import { getTimeRemaining, TimeRemaining } from '../../../../common/utils/date';
import { classNames } from '../../../../common/utils/style';

export function DeadlineTag(props: DeadlineTagProps) {
  const { deadline, className } = props;
  const { t } = useTranslation();
  const timeRemaining: TimeRemaining = getTimeRemaining(deadline);

  function createDeadlineLabel() {
    if (timeRemaining.total <= 0) {
      return t('doctor:consultations:deadline');
    }

    if (timeRemaining.days) {
      return t('doctor:consultations:deadline_day', { count: timeRemaining.days });
    } else if (timeRemaining.hours) {
      return t('doctor:consultations:deadline_hour', { count: timeRemaining.hours });
    } else if (timeRemaining.minutes) {
      return t('doctor:consultations:deadline_minute', { count: timeRemaining.minutes });
    }
  }

  return (
    <span
      className={classNames(
        className,
        'inline-flex items-center px-2 py-0.5 rounded text-xs font-medium leading-4 bg-teal-100 text-teal-800'
      )}
    >
      <ClockIcon className="mr-1 h-4 w-4 text-teal-800" />
      {createDeadlineLabel()}
    </span>
  );
}
