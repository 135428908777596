import { ChevronLeftIcon } from '@heroicons/react/outline';
import { MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

interface Props {
  onClick: (event: MouseEvent<HTMLAnchorElement>) => void;
}

export function ActionHeaderBackLink(props: Props) {
  const { onClick } = props;
  const { t } = useTranslation();

  return (
    <div className="flex flex-row justify-between items-center">
      <Link
        to="/"
        className="text-teal-600 hover:text-teal-500 focus:outline-none transition ease-in-out duration-150 text-sm underline flex items-center"
        onClick={onClick}
      >
        <ChevronLeftIcon className="h-4 w-4 text-gray-400 -ml-1" />
        {t('doctor:back_to_consultations')}
      </Link>
    </div>
  );
}
