import { PersonalizedTagObj } from './Toolbar/Toolbar.types';
import { launchPrompt } from './utils';

export const formats = ['bold', 'italic', 'underline', 'link', 'list', 'bullet', 'personalizedTag'];

export const modules = {
  toolbar: {
    container: '#toolbar',
    handlers: {
      variables(data: string) {
        const range = (this as any).quill.selection.savedRange; // cursor position
        if (!range || range.length !== 0) {
          return;
        }

        const personalizedTag: PersonalizedTagObj = JSON.parse(data);
        const position = range.index;

        (this as any).quill.insertEmbed(position, 'personalizedTag', personalizedTag, 'user');
        (this as any).quill.insertText(position + 1, ' ', 'api');
        (this as any).quill.setSelection(position + 2, 'api');
      },
      skincarePlan(): void {
        const range = (this as any).quill.selection.savedRange; // cursor position
        if (!range || range.length !== 0) {
          return;
        }

        const position = range.index;

        launchPrompt((value: string) => {
          if (!value) {
            return;
          }

          const personalizedTag: PersonalizedTagObj = {
            key: 'skincare_plan',
            label: value,
          };

          (this as any).quill.insertEmbed(position, 'personalizedTag', personalizedTag, 'user');
          (this as any).quill.insertText(position + 1, ' ', 'api');
          (this as any).quill.setSelection(position + 2, 'api');
        });
      },
    },
  },
};
