import type { User } from '../authentication/types';

export function getRedirectPathByRole(user: User | null) {
  if (user === null) {
    return '/doctor/login';
  } else if (user.is_doctor && !user.is_verified_doctor) {
    return '/doctor/settings';
  } else if ((user.is_doctor && user.is_verified_doctor) || user.is_medical_care) {
    return '/doctor/consultations';
  } else {
    return '/admin';
  }
}
