import { useTranslation } from 'react-i18next';

import { ConsultationNoteProps } from './ConsultationNote.types';
import { classNames } from '../../utils/style';
import ConversationWrapper from '../ConversationWrapper';
import { stripEmptyPTags } from '../helpers';

export const ConsultationNote = (props: ConsultationNoteProps) => {
  const { deleteCallbackHandler, note, isUser, readonly } = props;
  const { t } = useTranslation();

  return (
    <ConversationWrapper deleteCallbackHandler={deleteCallbackHandler} isUser={isUser} item={note} readonly={readonly}>
      <div
        className={classNames(
          'px-4 py-2 rounded-lg text-sm leading-5 text-gray-500 bg-primary-light',
          isUser && !readonly && !note.deleted_at && 'pr-8'
        )}
      >
        {!note.deleted_at ? (
          <>
            <h3 className="font-bold">
              {t('doctor:consultation:consultation_notes:title', {
                sender: `${note.sender?.first_name} ${note.sender?.last_name}`,
              })}
            </h3>
            <div className="inline-flex items-start justify-start">
              <div
                className="message whitespace-pre-wrap"
                dangerouslySetInnerHTML={{ __html: stripEmptyPTags(note.content) }}
              />
            </div>
          </>
        ) : (
          <p className="whitespace-pre-wrap italic text-gray-500">
            {t('doctor:consultation:consultation_notes:deleted')}
          </p>
        )}
      </div>
    </ConversationWrapper>
  );
};
