import { CursorClickIcon } from '@heroicons/react/outline';
import { useTranslation } from 'react-i18next';

import { ConsultationCard } from './ConsultationCard';
import { ConsultationListHeader } from './ConsultationListHeader';
import InfiniteScroll from '../../../common/InfiniteScrollList';
import { ConsultationReadStatus, Filter } from '../../../common/consultation/types';
import { EmptyState, LoadingState } from '../../../common/state/components';
import { Consultation } from '../../graphql/types';

interface Props {
  consultations: Consultation[];
  totalConsultationsCount: number;
  onSelectConsultation: (consultation: Consultation | null) => void;
  filter: Filter;
  onFilterChange: (filter: Filter) => void;
  filterLoading: boolean;
  isVisible: boolean;
  setIsVisible: (open: boolean) => void;
  updateConsultationHandler: (consultation: Consultation, status: ConsultationReadStatus) => void;
  loadMoreCallback: () => Promise<boolean>;
}

export function FilterableConsultationList(props: Props) {
  const {
    consultations,
    onSelectConsultation,
    filter,
    onFilterChange,
    filterLoading,
    isVisible,
    setIsVisible,
    updateConsultationHandler,
    loadMoreCallback,
    totalConsultationsCount,
  } = props;
  const { t } = useTranslation();

  return (
    <>
      <div className={`${isVisible ? 'md:grid md:grid-cols-2' : 'hidden'} h-full md:divide-x bg-white`}>
        <div className="flex flex-col md:h-full md:overflow-y-hidden">
          <ConsultationListHeader
            filter={filter}
            onFilterChange={onFilterChange}
            totalConsultationsCount={totalConsultationsCount}
          />
          {filterLoading && <LoadingState message={t('common:collecting_consultations')} />}
          {!filterLoading && (
            <InfiniteScroll
              data={consultations}
              loadMoreCallback={loadMoreCallback}
              wrapperClassName="block h-full overflow-y-auto"
            >
              <ul className="relative z-0 divide-y divide-gray-200 border-b border-gray-200">
                {consultations.map((consultation: Consultation) => {
                  return (
                    <ConsultationCard
                      key={consultation.id}
                      consultation={consultation}
                      onSelectConsultation={(consultation: Consultation) => {
                        setIsVisible(false);
                        onSelectConsultation(consultation);
                      }}
                      updateConsultationHandler={updateConsultationHandler}
                    />
                  );
                })}
              </ul>
            </InfiniteScroll>
          )}
        </div>
        <div className="hidden md:block p-6">
          <EmptyState
            title={t('common:no_consultation_selected')}
            message={t('common:select_consultation_from_your_inbox')}
            icon={<CursorClickIcon className="h-12 w-12 mx-auto text-orange-400" />}
          />
        </div>
      </div>
    </>
  );
}
