import { useMutation } from '@apollo/client';
import React, { useContext } from 'react';

import { RookooToggleProps } from './RookooToggle.types';
import { ToggleWithLabel } from '../../../../common/consultation/components';
import PermissionsContext from '../../../../common/context/PermissionContext';
import { canWriteOthersConsultations } from '../../../../common/utils/permissions';
import { TOGGLE_ROOKOO_FEATURE } from '../../../graphql/mutations/consultation';

export const RookooToggle = (props: RookooToggleProps) => {
  const { changeCallback, consultationId, rookoo } = props;
  const { permissions } = useContext(PermissionsContext);
  const [toggleRookooFeature] = useMutation(TOGGLE_ROOKOO_FEATURE);

  if (!canWriteOthersConsultations(permissions)) {
    return null;
  }

  const onChangeCallbackHandler = async (): Promise<void> => {
    await toggleRookooFeature({
      variables: { consultationId },
    });
    changeCallback();
  };

  return (
    <ToggleWithLabel
      checked={(rookoo && rookoo?.enabled) ?? false}
      label="Rookoo"
      onChangeCallback={onChangeCallbackHandler}
    />
  );
};

export default RookooToggle;
