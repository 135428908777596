import { CheckCircleIcon } from '@heroicons/react/outline';

import { Notification } from './Notification';

interface Props {
  title: string;
  isOpen: boolean;
  message?: string;
  onClose?: () => void;
}

export function SuccessNotification(props: Props) {
  return <Notification icon={<CheckCircleIcon className="h-6 w-6 text-green-400" />} {...props} />;
}
