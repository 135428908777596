export const launchPrompt = (callBack: (value: string) => void) => {
  const hiddenClass = 'hidden';
  const skincarePrompt = document.getElementById('skincare-prompt') as HTMLDivElement | null;
  const skincarePromptInput = document.getElementById('skincare-prompt__input') as HTMLInputElement | null;
  const skincarePromptButton = document.getElementById('skincare-prompt__btn') as HTMLButtonElement | null;

  if (skincarePrompt && skincarePromptInput && skincarePromptButton) {
    skincarePrompt.classList.remove(hiddenClass);
    skincarePromptInput.focus();

    const clickEventHandler = (event: any) => {
      callBack(skincarePromptInput.value);

      skincarePrompt.classList.add(hiddenClass);
      event.target.removeEventListener('click', clickEventHandler);
    };
    skincarePromptButton.addEventListener('click', clickEventHandler);
  }
};
