import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { CountryFlag } from '../../../common/CountryFlag/CountryFlag';
import { Patient } from '../../../common/consultation/types';

interface Props {
  patient: Patient;
}

export function PatientProfileHeading(props: Props) {
  const { patient } = props;
  const { t } = useTranslation();

  return (
    <div className="mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:px-8">
      <div className="flex items-center space-x-5">
        <div className="flex-shrink-0">
          <div className="relative">
            <img className="h-16 w-16 rounded-full" src={patient.avatar} alt={patient.first_name} />
            <span className="absolute inset-0 shadow-inner rounded-full" aria-hidden="true" />
          </div>
        </div>
        <div>
          <div className="flex items-center">
            <CountryFlag country={patient.patient_settings.country} />
            <h1 className="ml-3 text-2xl font-bold text-gray-900">
              {patient.first_name} {patient.last_name}
            </h1>
          </div>
          {patient.creator && (
            <p className="text-sm font-medium text-gray-500">
              {t('doctor:patients:created_by')}{' '}
              <Link
                to={`/doctor/patients/${patient.creator.id}`}
                className="text-teal-600 hover:text-teal-500 focus:outline-none transition ease-in-out duration-150"
              >
                {patient.creator.first_name} {patient.creator.last_name}
              </Link>
            </p>
          )}
        </div>
      </div>
    </div>
  );
}
