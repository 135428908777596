import {
  UserIcon,
  MailIcon,
  IdentificationIcon,
  PhoneIcon,
  ClipboardListIcon,
  OfficeBuildingIcon,
  LocationMarkerIcon,
  ChatAltIcon,
  ClockIcon,
  AdjustmentsIcon,
  CalendarIcon,
  AcademicCapIcon,
} from '@heroicons/react/outline';
import heic2any from 'heic2any';
import { useState, useEffect, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { ValidationError } from '../../../common/api/types';
import { SupportedLanguages, User } from '../../../common/authentication/types';
import { getDoctor, verifyDoctor } from '../../../common/consultation/api';
import { Doctor } from '../../../common/consultation/types';
import { Button, Select, Textarea, Input } from '../../../common/form/components';
import Checkbox from '../../../common/form/components/Checkbox';
import CheckboxGroup from '../../../common/form/components/CheckboxGroup';
import type { CheckboxGroupConfig } from '../../../common/form/components/CheckboxGroup/CheckboxGroup.types';
import { SuccessNotification } from '../../../common/notification/components';
import { updateSettings, updateDoctorSettings } from '../../../common/settings/api';
import type { CountrySetting } from '../../../common/settings/types';
import { LoadingState } from '../../../common/state/components';
import { RemovableTag } from '../../../common/tags/components';
import { formatDay, formatSecondsToHours } from '../../../common/utils/date';

interface Props {
  user: User;
}

interface Params {
  id: string;
}

// TODO: duplicate code DoctorSettingsForm (admin) vs SettingsForm (doctor)
export function DoctorSettingsForm(props: Props) {
  const { user } = props;
  const { id } = useParams<Params>();
  const { t } = useTranslation();

  const [first_name, setFirstName] = useState<string>('');
  const [last_name, setLastName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [language, setLanguage] = useState<string>('');
  const [license_number, setLicenseNumber] = useState<string>('');
  const [phone_number, setPhoneNumber] = useState<string>('');
  const [practice, setPractice] = useState<string>('');
  const [practice_city_nl, setPracticeCityNl] = useState<string>('');
  const [practice_city_fr, setPracticeCityFr] = useState<string>('');
  const [practice_city_en, setPracticeCityEn] = useState<string>('');
  const [about_nl, setAboutNL] = useState<string>('');
  const [about_fr, setAboutFR] = useState<string>('');
  const [about_en, setAboutEN] = useState<string>('');
  const [response_time, setResponseTime] = useState<string>('2_days');
  const [average_response_time, setAverageResponseTime] = useState<number>(0);
  const [avatar_preview, setAvatarPreview] = useState<string>('');
  const [avatar, setAvatar] = useState<Blob | undefined>(undefined);
  const [accepting_new_patients, setAcceptingNewPatients] = useState<boolean>(true);
  const [is_away, setIsAway] = useState<boolean>(false);
  const [mediris_integration, setMedirisIntegration] = useState<boolean>(false);
  const [weekly_volume_limit, setWeeklyVolumeLimit] = useState<string>('');
  const [error, setError] = useState<ValidationError>({} as ValidationError);
  const [doctor, setDoctor] = useState<Doctor>({} as Doctor);
  const [loading, setLoading] = useState<boolean>(true);
  const [verifying, setVerifying] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [allowed_postal_codes, setAllowedPostalCodes] = useState<string[]>([]);
  const [current_allowed_postal_code, setCurrentAllowedPostalCode] = useState<string>('');
  const [allowedCountries, setAllowedCountries] = useState<CountrySetting[]>([]);
  const [supportedLanguages, setSupportedLanguages] = useState<SupportedLanguages[]>([]);
  const [startProfessionalCareer, setStartProfessionalCareer] = useState<string>('');
  const [education, setEducation] = useState<string>('');
  const [consultationTransfer, setConsultationTransfer] = useState<boolean>(false);

  // Checkbox groups
  const allowedCountriesCheckboxGroup: CheckboxGroupConfig<CountrySetting> = {
    legend: t('doctor:settings:allowed_countries'),
    name: 'allowedCountries',
    changeHandler: onCheckboxGroupChangeHandler,
    items: ['belgium', 'netherlands'],
  };
  const supportedLanguagesCheckboxGroup: CheckboxGroupConfig<SupportedLanguages> = {
    legend: t('doctor:settings:supported_languages'),
    name: 'supportedLanguages',
    changeHandler: onCheckboxGroupChangeHandler,
    items: ['dutch', 'french', 'english'],
  };

  useEffect(() => {
    async function collectDoctorInformation() {
      const doctor = await getDoctor(user, parseInt(id, 10));

      setFirstName(doctor.first_name);
      setLastName(doctor.last_name);
      setEmail(doctor.email);
      setLanguage(doctor.language);
      setSupportedLanguages(doctor.doctor_settings.supported_languages ?? ['dutch']);
      setAvatarPreview(doctor.avatar);
      setLicenseNumber(doctor.doctor_settings.license_number);
      setPhoneNumber(doctor.doctor_settings.phone_number);
      setPractice(doctor.doctor_settings.practice ?? '');
      setPracticeCityNl(doctor.doctor_settings.practice_city_nl ?? '');
      setPracticeCityFr(doctor.doctor_settings.practice_city_fr ?? '');
      setPracticeCityEn(doctor.doctor_settings.practice_city_en ?? '');
      setAboutNL(doctor.doctor_settings.about_nl ?? '');
      setAboutFR(doctor.doctor_settings.about_fr ?? '');
      setAboutEN(doctor.doctor_settings.about_en ?? '');
      setResponseTime(doctor.doctor_settings.response_time);
      setAverageResponseTime(doctor.doctor_settings.average_response_time);
      setAcceptingNewPatients(doctor.doctor_settings.accepting_new_patients);
      setIsAway(doctor.doctor_settings.is_away);
      setMedirisIntegration(doctor.doctor_settings.mediris_integration);
      setWeeklyVolumeLimit(doctor.doctor_settings.weekly_volume_limit ?? '');
      setAllowedPostalCodes(doctor.doctor_settings.allowed_postal_codes ?? []);
      setAllowedCountries(doctor.doctor_settings.countries ?? ['belgium']);
      setStartProfessionalCareer(
        doctor.doctor_settings.start_professional_career
          ? formatDay(doctor.doctor_settings.start_professional_career)
          : ''
      );
      setEducation(doctor.doctor_settings.education ?? '');
      setConsultationTransfer(doctor.doctor_settings.consultation_transfer ?? false);
      setDoctor(doctor);
      setLoading(false);
    }

    collectDoctorInformation();
  }, [id]);

  async function onSettingsFormSubmit(event: React.FormEvent<HTMLElement>): Promise<void> {
    event.preventDefault();

    if (!submitting) {
      setSubmitting(true);
      setSuccess(false);
      setError({} as ValidationError);

      try {
        await updateSettings(user, parseInt(id, 10), {
          first_name,
          last_name,
          email,
          avatar,
          language,
        });

        await updateDoctorSettings(user, parseInt(id, 10), {
          license_number,
          phone_number,
          supported_languages: supportedLanguages,
          practice,
          practice_city_nl,
          practice_city_fr,
          practice_city_en,
          about_nl,
          about_fr,
          about_en,
          response_time,
          accepting_new_patients,
          is_away,
          mediris_integration,
          weekly_volume_limit,
          allowed_postal_codes,
          countries: allowedCountries,
          start_professional_career: startProfessionalCareer,
          education,
          consultation_transfer: consultationTransfer,
        });

        setSubmitting(false);
        setSuccess(true);
      } catch (errors) {
        setError(errors.error);
        setSubmitting(false);
      }
    }
  }

  async function onVerifyButtonClick(): Promise<void> {
    if (!verifying) {
      setVerifying(true);

      try {
        const verifiedDoctor = await verifyDoctor(user, doctor, {
          is_verified: !doctor.is_verified_doctor,
        });

        setDoctor(verifiedDoctor);
        setVerifying(false);
      } catch (errors) {
        console.error(errors);
        setVerifying(false);
      }
    }
  }

  async function onChangeAvatarClick(event: React.MouseEvent<HTMLElement>): Promise<void> {
    event.preventDefault();
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*, image/heic';
    document.body.appendChild(input);

    const reader = new FileReader();
    reader.onload = () => {
      setAvatarPreview(reader.result as string);
    };

    input.onchange = async (onChangeEvent: Event) => {
      let selectedAvatar = ((onChangeEvent.target as HTMLInputElement).files as FileList)[0] as Blob;
      if (selectedAvatar.type === 'image/heic') {
        selectedAvatar = (await heic2any({
          blob: selectedAvatar,
          toType: 'image/jpeg',
        })) as Blob;
      }
      reader.readAsDataURL(selectedAvatar);
      setAvatar(selectedAvatar);
      document.body.removeChild(input);
    };

    input.click();
  }

  function onCheckboxGroupChangeHandler(event: ChangeEvent<HTMLInputElement>) {
    const { name, value, checked } = event.target;

    if (name === 'supportedLanguages') {
      const updateStateValue = checked
        ? [...supportedLanguages, value as SupportedLanguages]
        : supportedLanguages.filter((item) => item !== value);

      setSupportedLanguages(updateStateValue);
    } else {
      const updateStateValue = checked
        ? [...allowedCountries, value as CountrySetting]
        : allowedCountries.filter((item) => item !== value);
      setAllowedCountries(updateStateValue);
    }
  }

  function onAllowedPostalCodesChange(postal_code: string) {
    setCurrentAllowedPostalCode(postal_code);
    if (postal_code.length === 4) {
      if (allowed_postal_codes.indexOf(postal_code) === -1) {
        setAllowedPostalCodes([...allowed_postal_codes, postal_code]);
      }
      setCurrentAllowedPostalCode('');
    }
  }

  function onRemoveAllowedPostalCode(postal_code: string) {
    const index = allowed_postal_codes.indexOf(postal_code);
    setAllowedPostalCodes([...allowed_postal_codes.slice(0, index), ...allowed_postal_codes.slice(index + 1)]);
  }

  if (loading) {
    return <LoadingState message={t('common:collecting_settings')} />;
  }

  return (
    <form onSubmit={onSettingsFormSubmit}>
      <SuccessNotification
        title={t('common:settings_saved')}
        message={t('common:your_profile_settings_are_saved')}
        isOpen={success}
      />
      <div className="bg-white shadow px-4 py-5 sm:rounded-t-lg sm:p-6">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <h3 className="text-lg font-medium leading-6 text-gray-900">{t('common:personal_information')}</h3>
            <p className="mt-1 text-sm leading-5 text-gray-500">{t('common:use_a_valid_email_address')}</p>
          </div>
          <div className="mt-5 md:mt-0 md:col-span-2 space-y-4">
            <Input
              icon={<UserIcon className="h-5 w-5 text-gray-400" />}
              label={t('common:first_name')}
              placeholder={t('common:first_name')}
              value={first_name}
              onChange={(event) => setFirstName(event.target.value)}
              error={error.first_name}
              id="first_name"
              name="first_name"
              type="text"
              required
            />
            <Input
              icon={<UserIcon className="h-5 w-5 text-gray-400" />}
              label={t('common:last_name')}
              placeholder={t('common:last_name')}
              value={last_name}
              onChange={(event) => setLastName(event.target.value)}
              error={error.last_name}
              id="last_name"
              name="last_name"
              type="text"
              required
            />
            <Input
              icon={<MailIcon className="h-5 w-5 text-gray-400" />}
              label={t('common:email_address')}
              placeholder={t('common:email_address')}
              value={email}
              onChange={(event) => setEmail(event.target.value)}
              error={error.email}
              id="email"
              name="email"
              type="email"
              autoComplete="email"
              required
            />
            <Input
              icon={<IdentificationIcon className="h-5 w-5 text-gray-400" />}
              label={t('common:license_number')}
              placeholder={t('common:license_number')}
              value={license_number}
              onChange={(event) => setLicenseNumber(event.target.value)}
              error={error.license_number}
              id="license_number"
              name="license_number"
              type="text"
              required
            />
            <Input
              icon={<PhoneIcon className="h-5 w-5 text-gray-400" />}
              label={t('common:phone_number')}
              placeholder={t('common:phone_number')}
              value={phone_number}
              onChange={(event) => setPhoneNumber(event.target.value)}
              error={error.phone_number}
              id="phone_number"
              name="phone_number"
              type="text"
              required
            />
            <Select
              icon={<ChatAltIcon className="h-5 w-5 text-gray-400" />}
              label={t('common:language')}
              value={language}
              onChange={(event) => setLanguage(event.target.value)}
              error={error.language}
              id="language"
              name="language"
              required
            >
              <option value="nl">{t('common:dutch')}</option>
              <option value="fr">{t('common:french')}</option>
              <option value="en">{t('common:english')}</option>
            </Select>

            <CheckboxGroup
              key={`fieldset-${supportedLanguagesCheckboxGroup.name}`}
              columns={1}
              label={`${supportedLanguagesCheckboxGroup.legend}*`}
              error={error.supported_languages}
            >
              {supportedLanguagesCheckboxGroup.items.map((item: SupportedLanguages) => (
                <div key={`${supportedLanguagesCheckboxGroup.name}-${item}`} className="mt-2 inline-block w-full">
                  <Checkbox
                    changeHandler={supportedLanguagesCheckboxGroup.changeHandler}
                    checked={supportedLanguages.includes(item)}
                    id={`${supportedLanguagesCheckboxGroup.name}-${item}`}
                    label={t(`common:${item}`)}
                    name={supportedLanguagesCheckboxGroup.name}
                    value={item}
                  />
                </div>
              ))}
            </CheckboxGroup>
            <Input
              icon={<OfficeBuildingIcon className="h-5 w-5 text-gray-400" />}
              label={t('common:practice')}
              placeholder={t('common:practice')}
              value={practice}
              onChange={(event) => setPractice(event.target.value)}
              error={error.practice}
              id="practice"
              name="practice"
              type="text"
            />
            <Input
              icon={<AcademicCapIcon className="h-5 w-5 text-gray-400" />}
              label={t('common:education')}
              placeholder={t('common:education')}
              value={education}
              onChange={(event) => setEducation(event.target.value)}
              error={error.education}
              id="education"
              name="education"
              type="text"
              required
            />
            <Input
              icon={<CalendarIcon className="h-5 w-5 text-gray-400" />}
              label={t('doctor:settings:start_professional_career')}
              placeholder={t('common:date_of_birth')}
              value={startProfessionalCareer}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                setStartProfessionalCareer(event.target.value);
              }}
              error={error.start_professional_career}
              id="start_professional_career"
              name="start_professional_career"
              type="date"
              required
            />
            <div className="grid md:grid-cols-3 gap-3">
              <p className="md:col-span-3 text-sm font-medium text-gray-700">{t('common:practice_city')}</p>
              <Input
                icon={<LocationMarkerIcon className="h-5 w-5 text-gray-400" />}
                placeholder={`${t('common:practice_city')} (${t('common:dutch')})`}
                value={practice_city_nl}
                onChange={(event) => setPracticeCityNl(event.target.value)}
                error={error.practice_city}
                id="practice_city_nl"
                name="practice_city_nl"
                type="text"
              />
              <Input
                icon={<LocationMarkerIcon className="h-5 w-5 text-gray-400" />}
                placeholder={`${t('common:practice_city')} (${t('common:french')})`}
                value={practice_city_fr}
                onChange={(event) => setPracticeCityFr(event.target.value)}
                error={error.practice_city}
                id="practice_city_fr"
                name="practice_city_fr"
                type="text"
              />
              <Input
                icon={<LocationMarkerIcon className="h-5 w-5 text-gray-400" />}
                placeholder={`${t('common:practice_city')} (${t('common:english')})`}
                value={practice_city_en}
                onChange={(event) => setPracticeCityEn(event.target.value)}
                error={error.practice_city_en}
                id="practice_city_en"
                name="practice_city_en"
                type="text"
              />
            </div>
            <Textarea
              icon={<ClipboardListIcon className="h-5 w-5 text-gray-400" />}
              label={t('common:about_nl')}
              placeholder={t('common:about_nl')}
              value={about_nl}
              onChange={(event) => setAboutNL(event.target.value)}
              error={error.about_nl}
              rows={3}
              id="about_nl"
              name="about_nl"
              required
            />
            <Textarea
              icon={<ClipboardListIcon className="h-5 w-5 text-gray-400" />}
              label={t('common:about_fr')}
              placeholder={t('common:about_fr')}
              value={about_fr}
              onChange={(event) => setAboutFR(event.target.value)}
              error={error.about_fr}
              rows={3}
              id="about_fr"
              name="about_fr"
              required
            />
            <Textarea
              icon={<ClipboardListIcon className="h-5 w-5 text-gray-400" />}
              label={t('common:about_en')}
              placeholder={t('common:about_en')}
              value={about_en}
              onChange={(event) => setAboutEN(event.target.value)}
              error={error.about_en}
              rows={3}
              id="about_en"
              name="about_en"
              required
            />

            <div>
              <label className="block text-sm leading-5 font-medium text-gray-700">{t('common:avatar')}</label>
              <div className="mt-1 flex items-center">
                <span className="flex items-center h-20 w-20 rounded-full overflow-hidden bg-gray-100 object-cover">
                  <img
                    src={avatar_preview}
                    alt={doctor.first_name}
                    className="flex items-center h-20 w-20 rounded-full overflow-hidden bg-gray-100 object-cover"
                  />
                </span>
                <span className="ml-5 rounded-md shadow-sm">
                  <Button label={t('common:change')} type="button" variant="outline" onClick={onChangeAvatarClick} />
                </span>
              </div>
              {error.avatar && <p className="mt-1 text-sm text-red-600">{error.avatar}</p>}
            </div>
          </div>
        </div>

        <div className="mt-8 pt-8 border-t border-gray-200 md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <h3 className="text-lg font-medium leading-6 text-gray-900">{t('common:settings')}</h3>
            <p className="mt-1 text-sm leading-5 text-gray-500">{t('common:global_settings')}</p>
          </div>
          <div className="mt-5 md:mt-0 md:col-span-2 space-y-4">
            <Select
              icon={<ClockIcon className="h-5 w-5 text-gray-400" />}
              label={t('common:response_time')}
              value={response_time}
              onChange={(event) => setResponseTime(event.target.value)}
              error={error.response_time}
              id="response_time"
              name="response_time"
              required
            >
              <option value="1_day">{t('common:1_day')}</option>
              <option value="2_days">{t('common:2_days')}</option>
              <option value="3_days">{t('common:3_days')}</option>
              <option value="4_days">{t('common:4_days')}</option>
              <option value="5_days">{t('common:5_days')}</option>
              <option value="6_days">{t('common:6_days')}</option>
              <option value="7_days">{t('common:7_days')}</option>
              <option value="average">
                {t('common:my_average_response_time', { hours: formatSecondsToHours(average_response_time) })}
              </option>
            </Select>
            <Input
              icon={<AdjustmentsIcon className="h-5 w-5 text-gray-400" />}
              label={t('doctor:settings:maximum_number_of_consultations_per_week')}
              placeholder={t('doctor:settings:maximum_number_of_consultations_per_week')}
              value={weekly_volume_limit}
              onChange={(event) => setWeeklyVolumeLimit(event.target.value)}
              error={error.weekly_volume_limit}
              id="weekly_volume_limit"
              name="weekly_volume_limit"
              type="number"
            />
            <Input
              icon={<LocationMarkerIcon className="h-5 w-5 text-gray-400" />}
              label={t('doctor:settings:allowed_postal_codes')}
              placeholder={t('doctor:settings:allowed_postal_codes_placeholder')}
              value={current_allowed_postal_code}
              onChange={(event) => onAllowedPostalCodesChange(event.target.value)}
              error={error.allowed_postal_codes}
              id="allowed_postal_codes"
              name="allowed_postal_codes"
              type="text"
            />
            {!!allowed_postal_codes.length && (
              <div className="space-y-2">
                {allowed_postal_codes.map((postal_code: string) => {
                  return (
                    <RemovableTag key={postal_code} label={postal_code} onRemoveClick={onRemoveAllowedPostalCode} />
                  );
                })}
              </div>
            )}

            <CheckboxGroup
              key={`fieldset-${allowedCountriesCheckboxGroup.name}`}
              label={allowedCountriesCheckboxGroup.legend}
              error={error.countries}
            >
              {allowedCountriesCheckboxGroup.items.map((item: CountrySetting) => (
                <div key={`${allowedCountriesCheckboxGroup.name}-${item}`} className="mt-2 inline-block w-full">
                  <Checkbox
                    changeHandler={allowedCountriesCheckboxGroup.changeHandler}
                    checked={allowedCountries.includes(item)}
                    id={`${allowedCountriesCheckboxGroup.name}-${item}`}
                    label={t(`common:${item}`)}
                    name={allowedCountriesCheckboxGroup.name}
                    value={item}
                  />
                </div>
              ))}
            </CheckboxGroup>

            <div className="pt-2">
              <div className="flex items-center">
                <input
                  id="accepting_new_patients"
                  type="checkbox"
                  className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                  checked={accepting_new_patients}
                  onChange={(event) => setAcceptingNewPatients(event.target.checked)}
                />
                <label htmlFor="accepting_new_patients" className="ml-2 block text-sm leading-5 text-gray-900">
                  {t('common:i_am_accepting_new_patients')}
                </label>
              </div>
              {error.accepting_new_patients && (
                <p className="mt-1 text-sm text-red-600">{error.accepting_new_patients}</p>
              )}
            </div>
            <div>
              <div className="flex items-center">
                <input
                  id="is_away"
                  type="checkbox"
                  className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                  checked={is_away}
                  onChange={(event) => setIsAway(event.target.checked)}
                />
                <label htmlFor="is_away" className="ml-2 block text-sm leading-5 text-gray-900">
                  {t('common:i_am_unavailable')}
                </label>
              </div>
              {error.is_away && <p className="mt-1 text-sm text-red-600">{error.is_away}</p>}
            </div>
            <div>
              <div className="flex items-center">
                <input
                  id="mediris_integration"
                  type="checkbox"
                  className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                  checked={mediris_integration}
                  onChange={(event) => setMedirisIntegration(event.target.checked)}
                />
                <label htmlFor="mediris_integration" className="ml-2 block text-sm leading-5 text-gray-900">
                  {t('doctor:settings:enable_mediris_integration')}
                </label>
              </div>
              {error.mediris_integration && <p className="mt-1 text-sm text-red-600">{error.mediris_integration}</p>}
            </div>
            <div>
              <div className="flex items-center">
                <input
                  id="consultation_transfer"
                  type="checkbox"
                  className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                  checked={consultationTransfer}
                  onChange={(event) => setConsultationTransfer(event.target.checked)}
                />
                <label htmlFor="consultation_transfer" className="ml-2 block text-sm leading-5 text-gray-900">
                  {t('doctor:settings:enable_consultation_transfer')}
                </label>
              </div>
              {error.consultation_transfer && (
                <p className="mt-1 text-sm text-red-600">{error.consultation_transfer}</p>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="shadow px-4 py-3 bg-gray-50 text-right sm:px-6 sm:rounded-b-lg flex justify-between">
        <Button
          label={doctor.is_verified_doctor ? t('common:unverify') : t('common:verify')}
          type="button"
          loading={verifying}
          disabled={verifying}
          onClick={onVerifyButtonClick}
        />
        <Button label={t('common:save')} type="submit" loading={submitting} disabled={submitting} />
      </div>
    </form>
  );
}
