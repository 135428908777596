import type { CountryFlagProps } from './CountryFlag.types';

const CountryFlagBelgium = () => (
  <svg className="block w-full h-full" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 480">
    <path d="M0 0h213.3v480H0z" />
    <path fill="#ffd90c" d="M213.3 0h213.4v480H213.3z" />
    <path fill="#f31830" d="M426.7 0H640v480H426.7z" />
  </svg>
);

const CountryFlagNetherlands = () => (
  <svg className="block w-full h-full" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 480">
    <path fill="#21468b" d="M0 0h640v480H0z" />
    <path fill="#fff" d="M0 0h640v320H0z" />
    <path fill="#ae1c28" d="M0 0h640v160H0z" />
  </svg>
);

export function CountryFlag(props: CountryFlagProps) {
  const { country } = props;
  return (
    <span className="block w-5 h-auto">
      {country === 'belgium' && <CountryFlagBelgium />}
      {country === 'netherlands' && <CountryFlagNetherlands />}
    </span>
  );
}
