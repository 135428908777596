import type { PricingTierTagProps } from './PricingTierTag.types';
import { classNames } from '../../../../common/utils/style';

export function PricingTierTag(props: PricingTierTagProps) {
  const { tier, className } = props;

  return (
    <span
      className={classNames(
        tier.title === 'legacy' ? 'bg-gray-100 text-gray-800' : '',
        tier.title === 'basic' ? 'bg-indigo-100 text-indigo-800' : '',
        tier.title === 'premium' ? 'bg-yellow-100 text-yellow-800' : '',
        tier.title === 'urgent' ? 'bg-red-100 text-red-800' : '',
        className ? className : '',
        'inline-flex items-center px-2 py-0.5 rounded text-xs leading-4 font-medium capitalize'
      )}
    >
      {tier.title}
    </span>
  );
}
