import { Link } from 'react-router-dom';

import { Patient } from '../../../common/consultation/types';

interface Props {
  patient: Patient;
}

export function PatientProfileCreatedProfileCard(props: Props) {
  const { patient } = props;

  return (
    <div className="relative rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-teal-500">
      <div className="flex-shrink-0">
        <img className="h-10 w-10 rounded-full" src={patient.avatar} alt={patient.first_name} />
      </div>
      <div className="flex-1 min-w-0">
        <Link to={`/doctor/patients/${patient.id}`} className="focus:outline-none">
          <span className="absolute inset-0" aria-hidden="true" />
          <p className="text-sm font-medium text-gray-900">
            {patient.first_name} {patient.last_name}
          </p>
        </Link>
      </div>
    </div>
  );
}
