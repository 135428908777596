import { useTranslation } from 'react-i18next';

import { classNames } from '../../utils/style';
// import { ConsultationStatus } from '../types';

interface Props {
  status: string;
  className?: string;
}

export function StatusTag(props: Props) {
  const { status, className } = props;
  const { t } = useTranslation();

  return (
    <span
      className={classNames(
        status === 'new' ? 'bg-indigo-100 text-indigo-800' : '',
        status === 'in_progress' ? 'bg-yellow-100 text-yellow-800' : '',
        status === 'reminded' ? 'bg-pink-100 text-pink-800' : '',
        status === 'draft' ? 'bg-gray-100 text-gray-800' : '',
        status === 'closed' ? 'bg-green-100 text-green-800' : '',
        className ? className : '',
        'inline-flex items-center px-2 py-0.5 rounded text-xs leading-4 font-medium'
      )}
    >
      {t(`common:${status}`)}
    </span>
  );
}
