import {
  SettingsInput,
  DoctorSettingsInput,
  DoctorSettings,
  PatientSettingsInput,
  PatientSettings,
  OtherPatientSettingsInput,
} from './types';
import { ValidationError } from '../api/ValidationError';
import * as api from '../api/api';
import { User } from '../authentication/types';
import { Patient } from '../consultation/types';

export async function updateSettings(user: User, id: number, settingsInput: SettingsInput): Promise<User> {
  try {
    const settingsResponse = await api.updateSettings(user, id, settingsInput);

    return settingsResponse.data.data.user;
  } catch (error) {
    throw new ValidationError('Validation failed', error.response.data.errors);
  }
}

export async function getDoctorSettings(user: User): Promise<DoctorSettings> {
  try {
    const settingsResponse = await api.getDoctorSettings(user);

    return settingsResponse.data.data.doctor_settings;
  } catch (error) {
    throw new ValidationError('Validation failed', error.response.data.errors);
  }
}

export async function getPatientSettings(user: User): Promise<PatientSettings> {
  try {
    const settingsResponse = await api.getPatientSettings(user);

    return settingsResponse.data.data.patient_settings;
  } catch (error) {
    throw new ValidationError('Validation failed', error.response.data.errors);
  }
}

export async function getOtherPatients(user: User): Promise<Patient[]> {
  try {
    const otherPatientsResponse = await api.getOtherPatients(user);

    return otherPatientsResponse.data.data.users;
  } catch (error) {
    throw new ValidationError('Validation failed', error.response.data.errors);
  }
}

export async function updateDoctorSettings(
  user: User,
  id: number,
  settingsInput: DoctorSettingsInput
): Promise<DoctorSettings> {
  try {
    const settingsResponse = await api.updateDoctorSettings(user, id, settingsInput);

    return settingsResponse.data.data.doctor_settings;
  } catch (error) {
    throw new ValidationError('Validation failed', error.response.data.errors);
  }
}

export async function updatePatientSettings(user: User, settingsInput: PatientSettingsInput): Promise<PatientSettings> {
  try {
    const settingsResponse = await api.updatePatientSettings(user, settingsInput);

    return settingsResponse.data.data.patient_settings;
  } catch (error) {
    throw new ValidationError('Validation failed', error.response.data.errors);
  }
}

export async function updateOtherPatientSettings(
  user: User,
  settingsInput: OtherPatientSettingsInput
): Promise<Patient> {
  try {
    const settingsResponse = await api.updateOtherPatientSettings(user, settingsInput);

    return settingsResponse.data.data.user;
  } catch (error) {
    throw new ValidationError('Validation failed', error.response.data.errors);
  }
}

export async function updatePartialPatientSettings(
  user: User,
  settingsInput: PatientSettingsInput
): Promise<PatientSettings> {
  try {
    const settingsResponse = await api.updatePartialPatientSettings(user, settingsInput);

    return settingsResponse.data.data.patient_settings;
  } catch (error) {
    throw new ValidationError('Validation failed', error.response.data.errors);
  }
}

export async function getDataExport(user: User): Promise<Blob> {
  try {
    const exportResponse = await api.getDataExport(user);

    return new Blob([exportResponse.data]);
  } catch (errors) {
    console.error(errors);
    throw new ValidationError('Export failed', { export: 'Something went wrong while exporting your data' });
  }
}
