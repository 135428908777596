import { gql } from '@apollo/client';

export const CREATE_CONSULTATION_NOTE = gql`
  mutation createConsultationNote($userId: ID!, $consultationId: ID!, $content: String!) {
    createConsultationNote(user_id: $userId, consultation_id: $consultationId, content: $content) {
      id
      created_at
      deleted_at
      sender: user {
        id
        avatar
        last_name
        first_name
      }
      content
    }
  }
`;

export const DELETE_CONSULTATION_NOTE = gql`
  mutation deleteConsultationNote($id: ID!) {
    deleteConsultationNote(id: $id) {
      id
      deleted_at
      content
    }
  }
`;
