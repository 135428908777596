import { createContext, useState } from 'react';

interface IUtmContext {
  utm_source: string | null;
  utm_medium: string | null;
  utm_campaign: string | null;
  utm_term: string | null;
}

interface Props {
  children: React.ReactNode;
}

export const UtmContext = createContext<IUtmContext>({} as IUtmContext);

export function UtmProvider(props: Props) {
  const { children } = props;
  const [utm_source] = useState<string | null>(determineInitialState('utm_source'));
  const [utm_medium] = useState<string | null>(determineInitialState('utm_medium'));
  const [utm_campaign] = useState<string | null>(determineInitialState('utm_campaign'));
  const [utm_term] = useState<string | null>(determineInitialState('utm_term'));

  function determineInitialState(key: string) {
    try {
      const urlParams = new URLSearchParams(window.location.search);

      if (urlParams.has(key)) {
        return urlParams.get(key);
      }
    } catch (error) {
      console.error(error);
      return null;
    }

    return null;
  }

  const value = {
    utm_source,
    utm_medium,
    utm_campaign,
    utm_term,
  };

  return <UtmContext.Provider value={value}>{children}</UtmContext.Provider>;
}
