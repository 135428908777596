import Echo from 'laravel-echo';
import Pusher from 'pusher-js';

import { WebsocketClientEventCallback, WebsocketClientEvents } from './websocket-client.types';

export const setupWebSocketConnection = (accessToken: string) => {
  // check if we can use singleton pattern
  if (window.Pusher !== undefined && window.Echo !== undefined) {
    return;
  }

  Pusher.logToConsole = process.env.NODE_ENV === 'development';
  window.Pusher = Pusher;

  const options: any = {
    broadcaster: 'reverb',
    key: process.env.REACT_APP_REVERB_APP_KEY,
    wsHost: process.env.REACT_APP_REVERB_HOST,
    forceTLS: (process.env.REACT_APP_REVERB_SCHEME ?? 'https') === 'https',
    enabledTransports: ['ws', 'wss'],
    auth: {
      headers: {
        Authorization: 'Bearer ' + accessToken,
        Accept: 'application/json',
      },
    },
    authEndpoint: `${process.env.REACT_APP_REVERB_SCHEME}://${process.env.REACT_APP_REVERB_HOST}/api/broadcasting/auth`,
  };

  if (process.env.REACT_APP_ENV === 'development') {
    options.wsPort = 8080;
  }

  window.Echo = new Echo(options);
};

export const disconnectWebSocketConnection = () => {
  window.Echo.disconnect();
};

export const getSubscribedChannels = (): string[] => {
  return Object.keys(window.Echo.connector.channels).map((channel: string) => channel.replace('private-', ''));
};

export const subscribeToPrivateChannel = (
  channel: string,
  event: WebsocketClientEvents,
  callback: WebsocketClientEventCallback
) => {
  window.Echo.private(channel).listen(event, callback);
};

export const unsubscribeFromPrivateChannel = (channel: string) => {
  window.Echo.leave(`private-${channel}`);
};
