import { useEffect } from 'react';

function WindowEvent<Event extends keyof WindowEventMap>(
  eventName: Event,
  handler: (event: WindowEventMap[Event]) => void
): void {
  useEffect(() => {
    window.addEventListener(eventName, handler);
    return () => window.removeEventListener(eventName, handler);
  }, [eventName, handler]);
}

export function useWindowClick(handler: (event: MouseEvent) => void) {
  return WindowEvent('click', handler);
}

export function windowScroll(topValue: number = 0) {
  try {
    window.scroll({ top: topValue, left: 0, behavior: 'smooth' });
  } catch (error) {
    console.error(error);
    window.scrollTo(0, topValue);
  }
}
