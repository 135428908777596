import type { RadioButtonProps } from './RadioButton.types';
import { classNames } from '../../../utils/style';

export function RadioButton(props: RadioButtonProps) {
  const { changeHandler, checked, id, label, name, value } = props;

  return (
    <div
      className={classNames(
        'relative border p-4 flex',
        checked && 'bg-teal-50 border-teal-200 z-10',
        !checked && 'border-gray-200'
      )}
    >
      <div className="flex items-center h-5">
        <input
          id={id}
          value={value}
          name={name}
          type="radio"
          className="form-radio h-4 w-4 text-teal-600 transition duration-150 ease-in-out cursor-pointer border-gray-300 focus:ring-teal-500"
          checked={checked}
          onChange={changeHandler}
        />
      </div>
      <label htmlFor={id} className="ml-3 flex flex-col cursor-pointer">
        <span
          className={classNames(
            'block text-sm leading-5 font-normal',
            checked && 'text-teal-900',
            !checked && 'text-gray-900'
          )}
        >
          {label}
        </span>
      </label>
    </div>
  );
}
