import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

interface ActionHeaderConsultationScrollerProps {
  nextConsultationId?: string;
  previousConsultationId?: string;
}

export function ActionHeaderConsultationScroller(props: ActionHeaderConsultationScrollerProps) {
  const { nextConsultationId, previousConsultationId } = props;
  const { t } = useTranslation();
  const cssClass =
    'text-teal-600 hover:text-teal-500 focus:outline-none transition ease-in-out duration-150 text-sm underline';

  return (
    <div
      className={`flex ${
        nextConsultationId && previousConsultationId
          ? 'justify-between gap-4'
          : previousConsultationId
          ? 'justify-start'
          : 'justify-end'
      }`}
    >
      {previousConsultationId && (
        <Link to={`/doctor/consultations/${previousConsultationId}`} className={cssClass}>
          {t('common:previous_consultation')}
        </Link>
      )}
      {nextConsultationId && (
        <Link to={`/doctor/consultations/${nextConsultationId}`} className={cssClass}>
          {t('common:next_consultation')}
        </Link>
      )}
    </div>
  );
}
