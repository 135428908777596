import type { MedirisProduct } from './types';
import type { User } from '../../doctor/graphql/types';
import { formatDay } from '../utils/date';

function getUrl(product: MedirisProduct): string {
  return `https://${product}${process.env.REACT_APP_ENV === 'production' ? '' : 'acc'}.mediris.be`;
}

export function createRecipeUrl(patient: User): string {
  const params = new URLSearchParams();

  if (patient.patient_settings) {
    if (patient.patient_settings.country !== 'belgium') {
      params.set('firstname', patient.first_name);
      params.set('lastname', patient.last_name);
      params.set('birthday', formatDay(patient.patient_settings.date_of_birth));
    } else {
      params.set('ssin', patient.patient_settings.identification_number ?? '');
    }
  }

  return `${getUrl('recipe')}/voorschrift?${params}`;
}

export function createEFactUrl(patient: User): string {
  const params = new URLSearchParams();

  if (patient.patient_settings) {
    params.set('ssin', patient.patient_settings.identification_number ?? '');
    params.set('useeattest', 'false');
    params.set('codes', '101732');
  }

  return `${getUrl('efact')}/consultatie/new?${params}`;
}

export function createMultiUrl(patient: User): string {
  const params = new URLSearchParams();

  if (patient.patient_settings) {
    params.set('ssin', patient.patient_settings.identification_number ?? '');
  }

  return `${getUrl('multi')}/consultatie/new?${params}`;
}
