import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom';

import { DefaultTemplateViewPage } from '../../../doctor/template/pages';
import { Shell } from '../../shell/components';
import { TemplateIndexPage, TemplateCreatePage, TemplateEditPage } from '../../templates/pages';

export function TemplatesRouter() {
  const match = useRouteMatch();

  return (
    <Shell>
      <Switch>
        <Route exact path={match.path} component={TemplateIndexPage} />
        <Route exact path={`${match.path}/create`} component={TemplateCreatePage} />
        <Route exact path={`${match.path}/:id/edit`} component={TemplateEditPage} />
        <Route exact path={`${match.path}/:id`} component={DefaultTemplateViewPage} />

        <Route path="*">
          <Redirect to={match.path} />
        </Route>
      </Switch>
    </Shell>
  );
}
