import { PatientsTableRow } from './PatientsTableRow';
import { User } from '../../graphql/types';

interface Props {
  patients: User[];
  showNotificationHandler: (flag: boolean) => void;
}

export function PatientsTableBody(props: Props) {
  const { patients, showNotificationHandler } = props;

  return (
    <tbody className="bg-white divide-y divide-gray-200">
      {patients.map((patient: User) => (
        <PatientsTableRow key={patient.id} patient={patient} showNotificationHandler={showNotificationHandler} />
      ))}
    </tbody>
  );
}
