import * as Sentry from '@sentry/react';
import { Integrations as TracingIntegrations } from '@sentry/tracing';
import mixpanel from 'mixpanel-browser';
import React from 'react';
import ReactDOM from 'react-dom/client';

import './common/i18n';
import './assets/main.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

if (
  process.env.REACT_APP_SENTRY_DSN &&
  process.env.REACT_APP_SENTRY_TRACE_SAMPLE_RATE &&
  process.env.REACT_APP_MIXPANEL_TOKEN
) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new TracingIntegrations.BrowserTracing()],
    tracesSampleRate: parseFloat(process.env.REACT_APP_SENTRY_TRACE_SAMPLE_RATE),
  });

  mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, { ignore_dnt: true });
} else {
  mixpanel.init('FAKE-TOKEN', { debug: true, ignore_dnt: true });
}

const root = ReactDOM.createRoot(document.getElementById('root')!);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
