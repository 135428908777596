import { Dialog, Transition } from '@headlessui/react';
import {
  ClipboardListIcon,
  AnnotationIcon,
  SupportIcon,
  PhoneIcon,
  XIcon,
  ChartSquareBarIcon,
  UserGroupIcon,
  BookOpenIcon,
} from '@heroicons/react/outline';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useRouteMatch, matchPath } from 'react-router-dom';

import { Logo } from './Logo';
import { ProfileDropdown } from './ProfileDropdown';
import { classNames } from '../../../common/utils/style';

interface Props {
  mobileSidebarOpen: boolean;
  setMobileSidebarOpen: (isOpen: boolean) => void;
  profileDropdownOpen: boolean;
  onProfileDropdownButtonClick: (event: React.MouseEvent<HTMLElement>) => void;
}

export function Sidebar(props: Props) {
  const { mobileSidebarOpen, setMobileSidebarOpen, profileDropdownOpen, onProfileDropdownButtonClick } = props;
  const { t } = useTranslation();
  const match = useRouteMatch();

  const sidebarNavigation = [
    {
      name: t('common:consultations'),
      to: { pathname: '/doctor/consultations', key: Date.now().toString(), state: { forceReload: true } },
      icon: ClipboardListIcon,
      current: Boolean(matchPath('/doctor/consultations', { path: match.path })),
    },
    {
      name: t('common:templates'),
      to: '/doctor/templates',
      icon: AnnotationIcon,
      current: Boolean(matchPath('/doctor/templates', { path: match.path })),
    },
    {
      name: t('doctor:patients:patients'),
      to: '/doctor/patients',
      icon: UserGroupIcon,
      current: Boolean(matchPath('/doctor/patients', { path: match.path })),
    },
    {
      name: t('common:dashboard'),
      to: '/doctor/dashboard',
      icon: ChartSquareBarIcon,
      current: Boolean(matchPath('/doctor/dashboard', { path: match.path })),
    },
  ];

  return (
    <>
      <div className="hidden w-28 bg-white flex-col md:block border-r border-gray-200">
        <div className="w-full h-full py-2 flex flex-col justify-between">
          <div className="flex flex-col items-center">
            <Logo />
            <div className="mt-6 w-full px-2 space-y-1">
              {sidebarNavigation.map((item) => (
                <Link
                  key={item.name}
                  to={item.to}
                  className={classNames(
                    item.current ? 'bg-gray-100 text-gray-900' : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                    'group w-full p-3 rounded-md flex flex-col items-center text-xs font-medium'
                  )}
                  aria-current={item.current ? 'page' : undefined}
                >
                  <item.icon
                    className={classNames(
                      item.current ? 'text-gray-500' : 'text-gray-500 group-hover:text-gray-500',
                      'h-6 w-6'
                    )}
                    aria-hidden="true"
                  />
                  <span className="mt-2 text-center">{item.name}</span>
                </Link>
              ))}
              <a
                className="group w-full p-3 rounded-md flex flex-col items-center text-xs font-medium text-gray-600 hover:bg-gray-50 hover:text-gray-900"
                target="_blank"
                href="https://skindr.notion.site/Doorverwijzing-fysieke-praktijk-fe212a123d094e3f9e4603015c585d06"
                rel="noreferrer"
              >
                <BookOpenIcon aria-hidden="true" className="h-6 w-6 text-gray-500 group-hover:text-gray-500" />
                <span className="mt-2">{t('common:faq')}</span>
              </a>
            </div>
          </div>

          <div className="w-full px-2 space-y-1">
            <a
              href="mailto:care@skindr.com"
              className="text-gray-600 hover:bg-gray-50 hover:text-gray-900 group w-full p-3 rounded-md flex flex-col items-center text-xs font-medium"
            >
              <SupportIcon className="text-gray-500 group-hover:text-gray-500 h-6 w-6" />
              <span className="mt-2 text-center">{t('common:i_need_help')}</span>
            </a>
            <a
              href="tel:0032479027535"
              className="text-gray-600 hover:bg-gray-50 hover:text-gray-900 group w-full p-3 rounded-md flex flex-col items-center text-xs font-medium"
            >
              <PhoneIcon className="text-gray-500 group-hover:text-gray-500 h-6 w-6" />
              <span className="mt-2 text-center">+32479027535</span>
            </a>
            <div className="flex flex-col items-center">
              <ProfileDropdown
                profileDropdownOpen={profileDropdownOpen}
                onProfileDropdownButtonClick={onProfileDropdownButtonClick}
              />
            </div>
          </div>
        </div>
      </div>

      <Transition.Root show={mobileSidebarOpen} as={Fragment}>
        <Dialog as="div" static className="md:hidden" open={mobileSidebarOpen} onClose={setMobileSidebarOpen}>
          <div className="fixed inset-0 z-40 flex">
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <div className="relative max-w-xs w-full bg-white pb-4 flex-1 flex flex-col">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <button
                    type="button"
                    className="h-12 w-12 rounded-full flex items-center justify-center self-end"
                    onClick={() => setMobileSidebarOpen(false)}
                  >
                    <XIcon className="h-6 w-6 text-black" aria-hidden="true" />
                  </button>
                </Transition.Child>
                <div className="flex-1 h-0 px-2 overflow-y-auto">
                  <nav className="h-full flex flex-col justify-between">
                    <div className="space-y-1">
                      {sidebarNavigation.map((item) => (
                        <Link
                          key={item.name}
                          to={item.to}
                          className={classNames(
                            item.current
                              ? 'bg-gray-100 text-gray-900'
                              : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                            'group py-2 px-3 rounded-md flex items-center text-sm font-medium'
                          )}
                          aria-current={item.current ? 'page' : undefined}
                        >
                          <item.icon
                            className={classNames(
                              item.current ? 'text-gray-500' : 'text-gray-500 group-hover:text-gray-500',
                              'mr-3 h-6 w-6'
                            )}
                            aria-hidden="true"
                          />
                          <span>{item.name}</span>
                        </Link>
                      ))}
                      <a
                        className="group py-2 px-3 rounded-md flex items-center text-sm font-medium text-gray-600 hover:bg-gray-50 hover:text-gray-900"
                        target="_blank"
                        href="https://skindr.notion.site/Doorverwijzing-fysieke-praktijk-fe212a123d094e3f9e4603015c585d06"
                        rel="noreferrer"
                      >
                        <BookOpenIcon
                          aria-hidden="true"
                          className="mr-3 h-6 w-6 text-gray-500 group-hover:text-gray-500"
                        />
                        <span>{t('common:faq')}</span>
                      </a>
                    </div>
                    <div className="space-y-1">
                      <a
                        href="mailto:care@skindr.com"
                        className="text-gray-600 hover:bg-gray-50 hover:text-gray-900 group py-2 px-3 rounded-md flex items-center text-sm font-medium"
                      >
                        <SupportIcon className="text-gray-500 group-hover:text-gray-500 mr-3 h-6 w-6" />
                        <span>{t('common:i_need_help')}</span>
                      </a>
                      <span className="text-gray-600 hover:bg-gray-50 hover:text-gray-900 group py-2 px-3 rounded-md flex items-center text-sm font-medium">
                        <PhoneIcon className="text-gray-500 group-hover:text-gray-500 mr-3 h-6 w-6" />
                        <span>+32479027535</span>
                      </span>
                      <ProfileDropdown
                        profileDropdownOpen={profileDropdownOpen}
                        onProfileDropdownButtonClick={onProfileDropdownButtonClick}
                      />
                    </div>
                  </nav>
                </div>
              </div>
            </Transition.Child>
            <div className="flex-shrink-0 w-14" aria-hidden="true" />
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
