import type { InitDetail } from 'lightgallery/lg-events';
import lgRotate from 'lightgallery/plugins/rotate';
import lgZoom from 'lightgallery/plugins/zoom';
import LightGallery from 'lightgallery/react';
import { useCallback, useEffect, useRef, useState } from 'react';

import type { LightGalleryWrapperProps } from './LightGalleryWrapper.types';
import type { Problem, ProblemPicture } from '../../doctor/graphql/types';
import { classNames } from '../utils/style';

// CSS
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-rotate.css';

export function LightGalleryWrapper(props: LightGalleryWrapperProps) {
  const { consultation } = props;
  const lightGallery = useRef<any>(null);
  const [pictures, setPictures] = useState<string[]>([]);

  const onInit = useCallback((detail: InitDetail) => {
    if (detail) {
      lightGallery.current = detail.instance;
    }
  }, []);

  useEffect(() => {
    const problem = consultation.consultable as Problem;
    let problemPictures: string[] = [];
    let extraPictures: string[] | ProblemPicture[] = problem.pictures ?? [];
    extraPictures = extraPictures.map((picture: ProblemPicture): string => {
      return picture.picture;
    });

    if (consultation.show_problem_pictures) {
      problemPictures = [problem.detail_picture, problem.overview_picture];
    }

    setPictures([...problemPictures, ...extraPictures]);
  }, [consultation.id]);

  return (
    <>
      {!!pictures.length && (
        <LightGallery
          download={false}
          licenseKey={process.env.REACT_APP_LIGHT_GALLERY_LICENSE_KEY}
          onInit={onInit}
          plugins={[lgRotate, lgZoom]}
          selector=".picture"
          flipHorizontal={false}
          flipVertical={false}
        >
          <div className="grid grid-cols-8 gap-4 p-6">
            {pictures.map((picture: string, index: number) => (
              <a
                className={classNames(
                  'picture relative h-0',
                  index > 0 ? 'pb-full shadow' : 'pb-1/2 col-span-6 md:col-span-8',
                  index
                )}
                key={`${consultation.id}-picture-${index}`}
                href={picture}
              >
                <img loading="lazy" className="absolute top-0 left-0 w-full h-full object-cover" src={picture} alt="" />
              </a>
            ))}
          </div>
        </LightGallery>
      )}
    </>
  );
}
