import { ReactNode } from 'react';

interface Props {
  title: string;
  actions?: ReactNode;
}

export function PageHeading(props: Props) {
  const { title, actions } = props;

  return (
    <div className="flex items-center justify-between pb-5">
      <h3 className="flex text-lg leading-6 font-medium text-gray-900">{title}</h3>
      {!!actions && actions}
    </div>
  );
}
