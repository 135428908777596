import { Listbox, Transition } from '@headlessui/react';
import { ClipboardListIcon } from '@heroicons/react/outline';
import { ChangeEvent, useContext, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useOnClickOutside } from 'usehooks-ts';

import type { TemplateSearchProps } from './TemplateSearch.types';
import TemplateSearchItems from './TemplateSearchItems';
import { ValidationError } from '../../../../common/api/types';
import { UserContext } from '../../../../common/authentication/UserContext';
import { User } from '../../../../common/authentication/types';
import { Input } from '../../../../common/form/components';
import { searchCannedResponses } from '../../../../common/template/api';
import { CannedResponse, DoctorCannedResponse } from '../../../../common/template/types';
import { classNames } from '../../../../common/utils/style';

export function TemplateSearch(props: TemplateSearchProps) {
  const { selectCallbackHandler, popoverPlacement, consultationNoteEnabled } = props;
  const { user } = useContext(UserContext);
  const { t } = useTranslation();
  const listBox = useRef(null);
  const [searchValue, setSearchValue] = useState<string>('');
  const [showTemplateList, setShowTemplateList] = useState<boolean>(false);
  const [defaultTemplates, setDefaultTemplates] = useState<CannedResponse[]>([]);
  const [doctorTemplates, setDoctorTemplates] = useState<DoctorCannedResponse[]>([]);
  const [error, setError] = useState<ValidationError>({} as ValidationError);

  useOnClickOutside(listBox, () => {
    setShowTemplateList(false);
  });

  function resetTemplates() {
    setDefaultTemplates([]);
    setDoctorTemplates([]);
  }

  async function changeHandler(event: ChangeEvent<HTMLInputElement>): Promise<void> {
    const query = event.target.value;
    setSearchValue(query);

    if (query.length > 1) {
      try {
        const cannedResponsesResponse = await searchCannedResponses(user as User, {
          query,
        });

        setDefaultTemplates(cannedResponsesResponse.canned_responses);
        setDoctorTemplates(cannedResponsesResponse.doctor_canned_responses);
        setShowTemplateList(
          cannedResponsesResponse.canned_responses.length !== 0 ||
            cannedResponsesResponse.doctor_canned_responses.length !== 0
        );
      } catch (errors) {
        setError(errors.error);
      }
    } else {
      setShowTemplateList(false);
      resetTemplates();
    }
  }

  return (
    <div className="relative">
      <Input
        disabled={consultationNoteEnabled}
        icon={<ClipboardListIcon className="h-5 w-5 text-gray-400" />}
        placeholder={t('doctor:search_template')}
        value={searchValue}
        onChange={changeHandler}
        onFocus={() => {
          if (defaultTemplates.length !== 0 || doctorTemplates.length !== 0) {
            setShowTemplateList(true);
          }
        }}
        error={error?.template}
        id="template-search"
        name="template-search"
        type="text"
      />

      <Listbox
        ref={listBox}
        as="div"
        value={null}
        onChange={(template: CannedResponse | DoctorCannedResponse) => {
          selectCallbackHandler(template);
          setShowTemplateList(false);
          setSearchValue('');
          resetTemplates();
        }}
      >
        {() => (
          <Transition
            show={showTemplateList}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            className={classNames(
              'absolute z-40 max-h-72 rounded-md w-full bg-white shadow-lg overflow-y-scroll',
              popoverPlacement === 'top' && 'bottom-full mb-4',
              popoverPlacement === 'bottom' && 'top-full mt-2'
            )}
          >
            <Listbox.Options
              className="z-20 rounded-md text-base leading-6 ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm sm:leading-5"
              static
            >
              {!!doctorTemplates.length && (
                <TemplateSearchItems title={t('common:personal_templates')} items={doctorTemplates} />
              )}
              {!!defaultTemplates.length && (
                <TemplateSearchItems title={t('common:default_templates')} items={defaultTemplates} />
              )}
            </Listbox.Options>
          </Transition>
        )}
      </Listbox>
    </div>
  );
}
