import { useContext, useEffect, useRef, useState } from 'react';

import type { ListConversationProps } from './ListConversation.types';
import {
  Message,
  ImageMessage as ImageMessageType,
  ConsultationNote as ConsultationNoteType,
} from '../../../doctor/graphql/types';
import { UserContext } from '../../authentication/UserContext';
import type { User } from '../../authentication/types';
import {
  FILE_TYPE,
  IMAGE_TYPE,
  MESSAGE_RESOURCE_FILE_TYPE,
  MESSAGE_RESOURCE_IMAGE_TYPE,
} from '../../constants/message';
import ConsultationNote from '../ConsultationNote';
import FileMessage from '../FileMessage';
import ImageMessage from '../ImageMessage';
import TextMessage from '../TextMessage';

export function ListConversation(props: ListConversationProps) {
  const { deleteCallbackHandler, messages, readonly, notes } = props;
  const { user } = useContext(UserContext);
  const endOfList = useRef<HTMLDivElement | null>(null);
  const [items, setItems] = useState<(Message | ConsultationNoteType)[]>([]);

  useEffect(() => {
    const mergedMessageAndConsultationNotes = [...notes, ...messages].sort((a, b) => {
      return new Date(a.created_at).getTime() - new Date(b.created_at).getTime();
    });
    setItems(mergedMessageAndConsultationNotes);
  }, [messages, notes]);

  useEffect(() => {
    // timeout to fix scroll in to view when text images
    setTimeout(() => {
      endOfList.current?.scrollIntoView({ behavior: 'smooth' });
    }, 500);
  }, [items.length]);

  return (
    <>
      {items.map((item) => {
        const isUser = item.sender?.id.toString() === (user as User).id.toString();

        if ('body_type' in item) {
          switch (item.body_type) {
            case IMAGE_TYPE:
            case MESSAGE_RESOURCE_IMAGE_TYPE:
              return (
                <ImageMessage
                  key={`image-message-${item.id}`}
                  imageUrl={(item.body as ImageMessageType).url}
                  isUser={isUser}
                  message={item}
                  readonly={readonly}
                />
              );
            case FILE_TYPE:
            case MESSAGE_RESOURCE_FILE_TYPE:
              return (
                <FileMessage
                  key={`file-message-${item.id}`}
                  deleteCallbackHandler={deleteCallbackHandler}
                  message={item}
                  isUser={isUser}
                  readonly={readonly}
                />
              );
            default:
              return (
                <TextMessage
                  key={`text-message-${item.id}`}
                  deleteCallbackHandler={deleteCallbackHandler}
                  message={item}
                  isUser={isUser}
                  readonly={readonly}
                />
              );
          }
        } else {
          return (
            <ConsultationNote
              key={`consultation-note-${item.id}`}
              deleteCallbackHandler={deleteCallbackHandler}
              note={item}
              isUser={isUser}
              readonly={readonly}
            />
          );
        }
      })}
      <div ref={endOfList} />
    </>
  );
}
