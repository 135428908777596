import { useTranslation } from 'react-i18next';

import { ConsultationFilter } from './ConsultationFilter';
import InboxPresetSelector from './InboxPresetSelector';
import { Filter } from '../../../common/consultation/types';
import { InboxPreset } from '../../graphql/types';

interface Props {
  filter: Filter;
  onFilterChange: (filter: Filter) => void;
  totalConsultationsCount: number;
}

export function ConsultationListHeader(props: Props) {
  const { filter, onFilterChange, totalConsultationsCount } = props;
  const { t } = useTranslation();

  return (
    <div>
      <div className="flex-1 pl-4 pr-6 pt-4 pb-4 border-t border-gray-200 sm:pl-6 xl:pt-6 xl:border-t-0">
        <div className="flex items-center">
          <h1 className="flex-1 text-lg leading-7 font-medium">{t('common:consultations')}</h1>
          <div className="flex">
            {filter.inboxPreset === InboxPreset.All && (
              <ConsultationFilter filter={filter} onFilterChange={onFilterChange} />
            )}
          </div>
        </div>
      </div>
      <InboxPresetSelector
        filter={filter}
        onFilterChange={onFilterChange}
        totalConsultationsCount={totalConsultationsCount}
      />
    </div>
  );
}
